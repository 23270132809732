import React, {Component, useState, useEffect} from 'react';
import {Route, Link, Redirect} from 'react-router-dom';

import Devices from '../../components/Devices';
import ezglogo from '../../assets/ezg_logo.png';
import Card from "react-bootstrap/Card";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import jwt_decode from "jwt-decode";

import AddCircleTwoToneIcon from '@material-ui/icons/AddCircleTwoTone';
import Button from '@material-ui/core/Button';
import Project from '../../components/projects/Project';

function Projects() {

    const [userProjects, setUserProjects] = useState([]);

    useEffect(()=>{

        var token = JSON.parse(sessionStorage.getItem('token'));
        var userName2 = sessionStorage.getItem('username');
        console.log(userName2)
        var userName = userName2;

        if (token === null) {
            sessionStorage.removeItem('token');
            localStorage.removeItem('token');   
            <Redirect from="*" to="/" />
            window.location.reload();
        }
        else {
            var decodedJwt = jwt_decode(token.token);
            //userName = decodedJwt.username;
            //console.log(token.token) ;  
        }
        console.log(userName)
        if (userName !== null) {

            const requestOptionsECHistory = {
                method: 'GET',
                headers: {'Content-Type': 'application/x-www-form-urlencoded'}                        
            }

            // FETCH 
            fetch('https://api.zvconnect.de/user/getuserdata/' + userName + '/', requestOptionsECHistory)
            .then(response => response.json())
            .then(responseJson => {parseUserProjects(responseJson);
            })            
        }

        function parseUserProjects(responseJson) { 
            console.log(responseJson)
            setUserProjects(responseJson, responseJson);            
        }

    }, []) // <-- empty dependency array

return (

    <div className="module-wrapper">
        <div className="distancer"></div>
        {/* // SUBNAVIGATION */}
        <div className="subnav">
            <div className="fltr">
                <Button variant="outlined" disabled startIcon={<AddCircleTwoToneIcon/>}>Neues Projekt</Button>  
            </div>
        <div className="spacer"></div>                     
        </div>
        {/* END SUBNAVIGATION*/}
        <div className="outer-title">
            Projektauswahl
        </div>   

        {userProjects.map(dataSet =>  (
            <Project projectIPath={dataSet.InternalPath} projectName={dataSet.project} projectCompany={dataSet.project_descriptor} projectID={dataSet.project_id}/>
        ))}
    </div>
);
}

export default Projects;