import React, {Component, useState, Fragment, useEffect} from 'react';
import Chart from 'react-apexcharts'
import Button from '@material-ui/core/Button';
import Card from 'react-bootstrap/Card';
import Table from 'react-bootstrap/Table';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';
import Icon from "../../Icons";

class WpSensorGraph extends Component {

    constructor(props) {
        super(props);

            this.todaysDate = '';
            this.todaysDateSimplified = '';
            this.currentlyDisplayedDate = '';
            this.currentlyDisplayedDateSimplified = '';
            // Color Values used by Chart
            this.colorValues = ["#000000", "#0D47A1", "#2196F3", "#BBDEFB", "#B71C1C", "#F44336", "#FE949F", "#194D33", "#388E3C", "#81C784", "#FF6F00", "#FFD54F"];

            this.state = {

            authToken: '',
            showConfig: 0,

            currentSensorID: 7,    
            currentSensorName: 'Sensor 7',
            tempNames: ['Temp1', 'Temp2', 'Temp3', 'Temp4'],

            yAxisConfig: [],    
            lastFetchedDate: '',
            lastT1: '',
            lastT2: '',
            lastT3: '',
            lastT4: '',
            lastBat: '',
            lastRssi: '',
            lastTimestamp: '',

            // Default Options
            options: {
                colors: ['#34b7eb', '#eb4034', '#44b034', '#44b034'],
                chart: {
                    id: 'temperaturverlauf',
                    zoom: {
                        type: 'xy',
                    },
                },
                tooltip: {
                    enabled: true, 
                    x: {
                        format: 'HH:mm'
                    }
                },
                stroke: {
                    width: 2
                }
                ,
                xaxis: {
                    type: 'datetime',             
                    categories: ['X1','X2', 'X3', 'X4'],
                    labels: {
                        format: 'HH:mm',
                      }
                },
                yaxis: {
                    min: 19,
                    max: 35
                }
            },

            optionsBat: {
                colors: ['#ffda00'],
                chart: {
                    id: 'batteriestand',
                    zoom: {
                        type: 'xy',
                    },
                },
                xaxis: {
                    type: "datetime",
                },
                yaxis: {
                }
            },


            series: [],

            //Series End

            seriesBat: []
            
            //Series End
        }        
    }

    // STATE EVENT HANDLERS
    onSensorConfigNameChange(value) {this.setState({currentSensorName: value});}
    onSensorConfigNameChange(value) {this.setState({currentSensorName: value});}
    //

    switchSensor(id){    
        // Changing state
        this.setState({currentSensorID: id})
        this.getSensorData(id);


        var BtnSensor6 = document.getElementById("BtnSensor6");
        var BtnSensor7 = document.getElementById("BtnSensor7");

        if (id === 6) {
            BtnSensor6.classList.add('green');
            BtnSensor7.classList.remove('green');
        }
        if (id === 7) {
            BtnSensor6.classList.remove('green');
            BtnSensor7.classList.add('green');
        }
        
    }


    switchDayBack(){
        var newDateToFetch = this.currentlyDisplayedDate;
        newDateToFetch.setDate(newDateToFetch.getDate()-1);

        var dd = String(newDateToFetch.getDate()).padStart(2, '0');
        var mm = String(newDateToFetch.getMonth() + 1).padStart(2, '0'); //January is 0!
        var yy = newDateToFetch.getFullYear().toString().substr(-2); 
        var newDateToFetchSimplified = dd + "/" + mm + "/" + yy;

        this.currentlyDisplayedDate = newDateToFetch;
        this.currentlyDisplayedDateSimplified = newDateToFetchSimplified;    

        this.getSensorData(this.state.currentSensorID)

        if (newDateToFetch < this.state.todaysDate) {
            var BtnSwitchDayForward = document.getElementById("BtnSwitchDayForward");
            var BtnSwitchDayToday = document.getElementById("BtnSwitchDayToday");
            BtnSwitchDayForward.classList.remove('hide');
            BtnSwitchDayToday.classList.remove('hide');
        }
    }

    switchDayForward(){
        var newDateToFetch = this.currentlyDisplayedDate;
        newDateToFetch.setDate(newDateToFetch.getDate()+1);

        var dd = String(newDateToFetch.getDate()).padStart(2, '0');
        var mm = String(newDateToFetch.getMonth() + 1).padStart(2, '0'); //January is 0!
        var yy = newDateToFetch.getFullYear().toString().substr(-2); 
        var newDateToFetchSimplified = dd + "/" + mm + "/" + yy;

        this.currentlyDisplayedDate = newDateToFetch;
        this.currentlyDisplayedDateSimplified = newDateToFetchSimplified;    

        this.getSensorData(this.state.currentSensorID)

        //console.log("newDateToFetch" + newDateToFetch + " " + 'this.state.todaysDate' + this.state.todaysDate)
        if (newDateToFetch.toString() === this.state.todaysDate.toString()) {
            var BtnSwitchDayForward = document.getElementById("BtnSwitchDayForward");
            var BtnSwitchDayToday = document.getElementById("BtnSwitchDayToday");
            BtnSwitchDayForward.classList.add('hide');
            BtnSwitchDayToday.classList.add('hide');
        }
    }

    switchDayToday(){
        this.currentlyDisplayedDateSimplified = '';
        this.getSensorData(this.state.currentSensorID)

        var BtnSwitchDayForward = document.getElementById("BtnSwitchDayForward");
        var BtnSwitchDayToday = document.getElementById("BtnSwitchDayToday");
        BtnSwitchDayForward.classList.add('hide');
        BtnSwitchDayToday.classList.add('hide');
    }

    switchRefresh(){
        this.getSensorData(this.state.currentSensorID)
    }

    switchConfig(){
        if (this.state.showConfig === 1) {
            this.setState({showConfig: 0});            
            document.querySelector('#greyOverlay').style.display= 'none';
            document.querySelector('#configWindow').style.display= 'none';
        }
        else {
            this.setState({showConfig: 1});
            document.querySelector('#greyOverlay').style.display= 'block';  
            document.querySelector('#configWindow').style.display= 'block';        
        }
    }

    saveNewConfig(){

        var newConfigJson = {
            nodeId: this.state.currentSensorID,
            nodeName: document.querySelector('#sensorConfigName').value,
            sensor1Descriptor: document.querySelector('#sensorConfigDesc1').value,
            sensor2Descriptor: document.querySelector('#sensorConfigDesc2').value,
            sensor3Descriptor: document.querySelector('#sensorConfigDesc3').value,
            sensor4Descriptor: document.querySelector('#sensorConfigDesc4').value,
            sensor1Color:  parseInt(document.querySelector('#colorSelectorTemp1').value),
            sensor2Color:  parseInt(document.querySelector('#colorSelectorTemp2').value),
            sensor3Color:  parseInt(document.querySelector('#colorSelectorTemp3').value),
            sensor4Color:  parseInt(document.querySelector('#colorSelectorTemp4').value),
            axisYMin: parseInt(document.querySelector('#sensorConfigYMin').value),
            axisYMax:  parseInt(document.querySelector('#sensorConfigYMax').value),
        }

        console.log("POSTING: " + JSON.stringify(newConfigJson));

        const sendConfigTriTemp = {
            method: 'POST',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify(newConfigJson)
        }

        // POST TO API
        fetch('https://api.zvconnect.de/quadtemphyg/sensors/', sendConfigTriTemp)
        .then(response => response.json())
        .then(responseJson => console.log("HERE" + JSON.stringify(responseJson)))

        // SEND TO LOCAL CONFIG PARSER
        this.parseSensorUIConfig(newConfigJson);        
        // HIDE CONFIG WINDOW
        this.switchConfig()
        // REFRESH CHART
        //this.getSensorData(this.state.currentSensorID);

        setTimeout(function() { //Start the timer
            this.getSensorData(this.state.currentSensorID);           
        }.bind(this), 1000)
    }

    UNSAFE_componentWillMount() {
        this.getSensorData(this.state.currentSensorID);
        this.setState({todaysDate: new Date()})
    }

    componentDidUpdate() {

    }

    getSensorData(id){

        const requestOptionsTriTemp = {
            method: 'GET',
            headers: {'Content-Type': 'application/json'},
        }

        // FETCH SENSOR UI CONFIG
        fetch('https://api.zvconnect.de/quadtemphyg/sensors/' + id + '/', requestOptionsTriTemp)
        .then(response => response.json())
        .then(responseJson => {this.parseSensorUIConfig(responseJson);
        })      
        
        // GET SENSOR HISTORY DATA
        console.log("DateToFetchWith= " + currentDateToGet)
        var currentDateToGet = this.currentlyDisplayedDateSimplified;

        // IF NO DATE -> FETCH FOR TODAY
        if (currentDateToGet === '') {
            var today = new Date();
            var dd = String(today.getDate()).padStart(2, '0');
            var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
            var yy = today.getFullYear().toString().substr(-2);            
            currentDateToGet = dd + "/" + mm + "/" + yy;
            
            // Push to Global Variable
            this.currentlyDisplayedDate = today;
            this.currentlyDisplayedDateSimplified = currentDateToGet;
                       
        }

        // PUSH CURRENTLY USED DATE TO STATE
        this.setState({lastFetchedDate: currentDateToGet})        
        console.log("DateToFetchWith= " + currentDateToGet)

        // FETCH SENSOR HISTORY DATA
        fetch('https://api.zvconnect.de/quadtemphyg/?sensorid=' + id + '&startdate='+ currentDateToGet +'&enddate='+ currentDateToGet +'&resolution=1', requestOptionsTriTemp)
        .then(response => response.json())
        .then(responseJson => {this.splitDataPoints(responseJson);
        })
    }

    parseSensorUIConfig(responseJson){

        var configData = responseJson;

        console.log("Processing Config: " + JSON.stringify(configData));

        // Name of the Sensor. Only for Labels. Goes into State.
        this.setState({currentSensorName: configData.nodeName});
        // Labels for Temp 1-3. Used by 'splitDataPoints' when formatting the Data Series.
        this.setState({tempNames: [configData.sensor1Descriptor, configData.sensor2Descriptor, configData.sensor3Descriptor, configData.sensor4Descriptor]});
        document.querySelector('#sensorConfigDesc1').value = [configData.sensor1Descriptor];
        document.querySelector('#sensorConfigDesc2').value = [configData.sensor2Descriptor];   
        document.querySelector('#sensorConfigDesc3').value = [configData.sensor3Descriptor];
        document.querySelector('#sensorConfigDesc4').value = [configData.sensor4Descriptor];
        // Colors for the chart. Goes into chart config.
        var uiConfigColors = [this.colorValues[configData.sensor1Color], this.colorValues[configData.sensor2Color], this.colorValues[configData.sensor3Color], this.colorValues[configData.sensor4Color]];
        // Set Colors in Config Panel
        document.querySelector('#colorSelectorTemp1').value = [configData.sensor1Color];
        document.querySelector('#colorSelectorTemp2').value = [configData.sensor2Color];
        document.querySelector('#colorSelectorTemp3').value = [configData.sensor3Color];
        document.querySelector('#colorSelectorTemp4').value = [configData.sensor4Color];

        // Y-Axis Limits for the chart. Goes into chart config.
        var uiConfigYAxis = {min: configData.axisYMin, max: configData.axisYMax};
        //this.setState({yAxisConfig: [configData.axisYMin, configData.axisYMax]});
        document.querySelector('#sensorConfigYMin').value = [configData.axisYMin];
        document.querySelector('#sensorConfigYMax').value = [configData.axisYMax];
        

        var newUiConfig = {
            colors: uiConfigColors,
            chart: {
                id: 'Temperaturverlauf',
                zoom: {
                    type: 'xy',
                },
            },
            tooltip: {
                enabled: true, 
                x: {
                    format: 'HH:mm'
                }
            },              
            xaxis: {
                type: 'datetime',                    
                labels: {
                    format: 'HH:mm',
                }
            },
            yaxis: uiConfigYAxis
        }

        this.setState({options: newUiConfig});
    }

    splitDataPoints(responseJson) {
        console.log(responseJson);
        console.log("Entries:" + responseJson.length);

        // Check if Response has Content
        if (responseJson.length > 0) {

        // Set Table with last known Status
            if (responseJson.length > 0) {
                var lastPosition = responseJson.length-1;
                this.setState({lastT1: (responseJson[lastPosition].temp4)/10})
                this.setState({lastT2: (responseJson[lastPosition].temp3)/10})
                this.setState({lastT3: (responseJson[lastPosition].temp2)/10})
                this.setState({lastT4: (responseJson[lastPosition].temp1)/10})             
                this.setState({lastBat: (responseJson[lastPosition].bat)})
                this.setState({lastRssi: (responseJson[lastPosition].rssi)})

                var isoDate = responseJson[lastPosition].time;
                var timestamp = isoDate.match(/\d\d:\d\d/);

                this.setState({lastTimestamp: timestamp})
            }

            else {
                this.setState({lastT1: ''})
                this.setState({lastT2: ''})
                this.setState({lastT3: ''})
                this.setState({lastT4: ''})
                this.setState({lastBat: ''})
                this.setState({lastRssi: ''})
                this.setState({lastTimestamp: ''})
            }

            // Create Split Arrays of Temps for Graph
            var temp1Data = {name: this.state.tempNames[0], data: []};
            var temp2Data = {name: this.state.tempNames[1], data: []};
            var temp3Data = {name: this.state.tempNames[2], data: []};
            var temp4Data = {name: this.state.tempNames[3], data: []};
            var batData = {name: 'Batterie', data: []};        

            responseJson.forEach(element => {
                var timeDataPoint = element.time;

                var temp1DataPoint = element.temp4 / 10;
                if (!(temp1DataPoint > 50) && !(temp1DataPoint < 0)) {
                    temp1Data.data.push({ x: timeDataPoint, y: parseFloat(temp1DataPoint)});                
                }
                var temp2DataPoint = element.temp3 / 10;
                if (!(temp2DataPoint > 50) && !(temp2DataPoint < 0)) {
                    temp2Data.data.push({ x: timeDataPoint, y: parseFloat(temp2DataPoint)});                
                }
                var temp3DataPoint = element.temp2 / 10;
                if (!(temp3DataPoint > 50) && !(temp3DataPoint < 0)) {
                    temp3Data.data.push({ x: timeDataPoint, y: parseFloat(temp3DataPoint)});                
                }
                var temp4DataPoint = element.temp1 / 10;
                if (!(temp4DataPoint > 50) && !(temp4DataPoint < 0)) {
                    temp4Data.data.push({ x: timeDataPoint, y: parseFloat(temp4DataPoint)});                
                }
                var batDataDataPoint = element.bat;
                if (batDataDataPoint > 0) {
                    batData.data.push({ x: timeDataPoint, y: parseFloat(batDataDataPoint)});                
                }                
            });

            this.setState({series: [temp1Data, temp2Data, temp3Data, temp4Data]})
            this.setState({seriesBat: [batData]})
            }
        }



    render() {
    return (            
            <div className="tempChart-Wrapper">
                <div id="greyOverlay" style={{display: "none"}}> </div>
                <div id="configWindow" style={{display: "none", height: 715}}>
                    <div className="configWindowTitle">
                        Konfiguration: {this.state.currentSensorName}
                    </div>
                    <hr/>
                    <div>
                        <Form>
                            <Form.Group>
                                <Form.Row>
                                    <Form.Label column="sm" lg={5}>Name Sensor</Form.Label>
                                    <Col><Form.Control id="sensorConfigName" size="sm" type="text" value={this.state.currentSensorName} onChange={e => this.onSensorConfigNameChange(e.target.value)} /></Col>     
                                </Form.Row>
                                <hr/>
                                <Form.Row>
                                    <Form.Label column="sm" lg={5}>Name Temp. 1</Form.Label>
                                    <Col><Form.Control id="sensorConfigDesc1" size="sm" type="text" /></Col> 
                                </Form.Row>
                                <br/>
                                <Form.Row>
                                    <Form.Label column="sm" lg={5}>Farbe Temp. 1</Form.Label>
                                    <Col><Form.Control size="sm" as="select" id="colorSelectorTemp1">
                                        <option value="0" style={{color: '#000000'}}>Schwarz</option> 
                                        <option value="1" style={{color: '#0D47A1'}}>Dunkelblau</option>                                    
                                        <option value="2" style={{color: '#2196F3'}}>Blau</option>
                                        <option value="3" style={{color: '#BBDEFB'}}>Hellbau</option>
                                        <option value="4" style={{color: '#B71C1C'}}>Dunkelrot</option>
                                        <option value="5" style={{color: '#F44336'}}>Rot</option>
                                        <option value="6" style={{color: '#FE949F'}}>Hellrot</option>
                                        <option value="7" style={{color: '#194D33'}}>Dunkelgrün</option>
                                        <option value="8" style={{color: '#388E3C'}}>Grün</option>
                                        <option value="9" style={{color: '#81C784'}}>Hellgrün</option>
                                        <option value="10" style={{color: '#FF6F00'}}>Orange</option>
                                        <option value="11" style={{color: '#FFD54F'}}>Gelb</option>                                                                                                         
                                    </Form.Control></Col> 
                                </Form.Row>
                                <hr/>
                                <Form.Row>
                                    <Form.Label column="sm" lg={5}>Name Temp. 2</Form.Label>
                                    <Col><Form.Control id="sensorConfigDesc2" size="sm" type="text"/></Col> 
                                </Form.Row>
                                <br/>
                                <Form.Row>
                                    <Form.Label column="sm" lg={5}>Farbe Temp. 2</Form.Label>
                                    <Col><Form.Control size="sm" as="select" id="colorSelectorTemp2">
                                        <option value="0" style={{color: '#000000'}}>Schwarz</option> 
                                        <option value="1" style={{color: '#0D47A1'}}>Dunkelblau</option>                                    
                                        <option value="2" style={{color: '#2196F3'}}>Blau</option>
                                        <option value="3" style={{color: '#BBDEFB'}}>Hellbau</option>
                                        <option value="4" style={{color: '#B71C1C'}}>Dunkelrot</option>
                                        <option value="5" style={{color: '#F44336'}}>Rot</option>
                                        <option value="6" style={{color: '#FE949F'}}>Hellrot</option>
                                        <option value="7" style={{color: '#194D33'}}>Dunkelgrün</option>
                                        <option value="8" style={{color: '#388E3C'}}>Grün</option>
                                        <option value="9" style={{color: '#81C784'}}>Hellgrün</option>
                                        <option value="10" style={{color: '#FF6F00'}}>Orange</option>
                                        <option value="11" style={{color: '#FFD54F'}}>Gelb</option>  
                                    </Form.Control></Col> 
                                </Form.Row>
                                <hr/>
                                <Form.Row>
                                    <Form.Label column="sm" lg={5}>Name Temp. 3</Form.Label>
                                    <Col><Form.Control id="sensorConfigDesc3" size="sm" type="text"/></Col> 
                                </Form.Row>
                                <br/>
                                <Form.Row>
                                    <Form.Label column="sm" lg={5}>Farbe Temp. 3</Form.Label>
                                    <Col><Form.Control size="sm" as="select" id="colorSelectorTemp3">
                                        <option value="0" style={{color: '#000000'}}>Schwarz</option> 
                                        <option value="1" style={{color: '#0D47A1'}}>Dunkelblau</option>                                    
                                        <option value="2" style={{color: '#2196F3'}}>Blau</option>
                                        <option value="3" style={{color: '#BBDEFB'}}>Hellbau</option>
                                        <option value="4" style={{color: '#B71C1C'}}>Dunkelrot</option>
                                        <option value="5" style={{color: '#F44336'}}>Rot</option>
                                        <option value="6" style={{color: '#FE949F'}}>Hellrot</option>
                                        <option value="7" style={{color: '#194D33'}}>Dunkelgrün</option>
                                        <option value="8" style={{color: '#388E3C'}}>Grün</option>
                                        <option value="9" style={{color: '#81C784'}}>Hellgrün</option>
                                        <option value="10" style={{color: '#FF6F00'}}>Orange</option>
                                        <option value="11" style={{color: '#FFD54F'}}>Gelb</option>  
                                    </Form.Control></Col> 
                                </Form.Row>
                                <hr/>
                                <Form.Row>
                                    <Form.Label column="sm" lg={5}>Name Temp. 4</Form.Label>
                                    <Col><Form.Control id="sensorConfigDesc4" size="sm" type="text"/></Col> 
                                </Form.Row>
                                <br/>
                                <Form.Row>
                                    <Form.Label column="sm" lg={5}>Farbe Temp. 4</Form.Label>
                                    <Col><Form.Control size="sm" as="select" id="colorSelectorTemp4">
                                        <option value="0" style={{color: '#000000'}}>Schwarz</option> 
                                        <option value="1" style={{color: '#0D47A1'}}>Dunkelblau</option>                                    
                                        <option value="2" style={{color: '#2196F3'}}>Blau</option>
                                        <option value="3" style={{color: '#BBDEFB'}}>Hellbau</option>
                                        <option value="4" style={{color: '#B71C1C'}}>Dunkelrot</option>
                                        <option value="5" style={{color: '#F44336'}}>Rot</option>
                                        <option value="6" style={{color: '#FE949F'}}>Hellrot</option>
                                        <option value="7" style={{color: '#194D33'}}>Dunkelgrün</option>
                                        <option value="8" style={{color: '#388E3C'}}>Grün</option>
                                        <option value="9" style={{color: '#81C784'}}>Hellgrün</option>
                                        <option value="10" style={{color: '#FF6F00'}}>Orange</option>
                                        <option value="11" style={{color: '#FFD54F'}}>Gelb</option>  
                                    </Form.Control></Col> 
                                </Form.Row>                                
                                <hr/>
                                <Form.Row>
                                    <Form.Label column="sm" lg={5}>Y-Achse Minimum</Form.Label>
                                    <Col><Form.Control id="sensorConfigYMin" size="sm" type="number"/></Col> 
                                </Form.Row>
                                <br/>
                                <Form.Row>
                                    <Form.Label column="sm" lg={5}>Y-Achse Maximum</Form.Label>
                                    <Col><Form.Control id="sensorConfigYMax" size="sm" type="number"/></Col> 
                                </Form.Row>                                                                                                                              
                            </Form.Group>                                                                  
                        </Form>
                    </div>
                    <hr/>
                    <div className="configWindowButtons">
                        <Button id="BtnConfig" variant="contained" color="" className="btnSuccess" onClick={() => { this.saveNewConfig() }}>Speichern</Button>
                        <Button id="BtnConfig" variant="contained" color="" className="btnCancel" onClick={() => { this.switchConfig() }}>Schliessen</Button>
                    </div>
                </div>
                <Card className="tempChart-SensorCard">
                    <Card.Header>
                        <div className="card-title">{this.state.currentSensorName}: Letzte Ablesung</div>
                    </Card.Header>
                    <Card.Body>
                        <Table striped bordered hover size="sm">
                            <thead>
                                <tr>
                                    <th>T1</th>
                                    <th>T2</th>
                                    <th>T3</th>
                                    <th>T4</th>
                                    <th>Bat</th>
                                    <th>Signal</th>
                                    <th>Uhrzeit</th>                                                            
                                </tr>                                
                            </thead>
                            <tbody id="smokeTableBody">
                                <TableRow>
                                    <TableCell>{this.state.lastT1}</TableCell>
                                    <TableCell>{this.state.lastT2}</TableCell>
                                    <TableCell>{this.state.lastT3}</TableCell>
                                    <TableCell>{this.state.lastT4}</TableCell>
                                    <TableCell>{this.state.lastBat}</TableCell>
                                    <TableCell>{this.state.lastRssi}</TableCell>
                                    <TableCell>{this.state.lastTimestamp}</TableCell>
                                </TableRow>
                            </tbody>
                        </Table>
                    </Card.Body>
                </Card>
                <div>
                    <Button id="BtnSwitchDayBack" variant="contained" color="primary" className="fltr" onClick={() => { this.switchDayBack() }}>Tag Zurück</Button>
                    <Button id="BtnSwitchDayForward" variant="contained" color="primary" className="fltr hide" onClick={() => { this.switchDayForward() }}>Tag Vor</Button>
                    <Button id="BtnSwitchDayToday" variant="contained" color="primary" className="fltr hide" onClick={() => { this.switchDayToday() }}>Heute</Button>
                    <Button id="BtnConfig" variant="contained" color="primary" className="fltr configButton" onClick={() => { this.switchConfig() }}><Icon name="settings"/></Button>
                    <Button id="BtnSwitchRefresh" variant="contained" color="primary" className="fltr configButton" onClick={() => { this.switchRefresh() }}><Icon name="refresh"/></Button>                    
                </div>
                <Card className="tempChart-card">
                    <Card.Header>
                        <div className="card-title">Temperaturverlauf: {this.state.lastFetchedDate}</div>
                    </Card.Header> 
                    <Card.Body>                    
                        <Chart options={this.state.options} series={this.state.series} type="line" style={{height: '100%'}} height={400} />
                    </Card.Body>
                </Card>
                <Card className="tempChart-card"  style={{display: 'none'}}>
                    <Card.Header>
                        <div className="card-title">Batteriestand: {this.state.lastFetchedDate}</div>
                    </Card.Header>
                    <Card.Body>                        
                        <Chart options={this.state.optionsBat} series={this.state.seriesBat} type="line" style={{height: '100%'}} height={400} />
                    </Card.Body>
                </Card> 
            </div>        
        );
    }
}
export default WpSensorGraph;