import React, {Component, useState, Fragment, useEffect} from 'react';
import {Link} from 'react-router-dom';
import Card from 'react-bootstrap/Card';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import ArrowBackIosTwoToneIcon from '@material-ui/icons/ArrowBackIosTwoTone';
import Button from '@material-ui/core/Button';
import LinkIcon from '@material-ui/icons/Link';
import LinkOffIcon from '@material-ui/icons/LinkOff';
// CHART
import TrinkwasserHygGraph from './TrinkwasserHygGraph';


function Trinkwasserhygiene() {

    const [kaltwasserTemp, setKaltwasserTemp] = useState("-");
    const [warmwasserTemp, setWarmwasserTemp] = useState("-");
  
    const [valve1State, setValve1State] = useState("geschlossen");
    const [valve2State, setValve2State] = useState("geschlossen");

    const [opModeState, setOpMode] = useState();

    // BUTTON PROGRESS ANIMATION

    // function toggleWCFlushAnimation(value){
    //     var aniBtnSpKlein= document.getElementById("wcSpSmallButton");
    //     var aniBtnSpGross= document.getElementById("wcSpBigButton");

    //     if (value === 1) {
    //         aniBtnSpKlein.classList.add("green");
    //     }
    //     else if (value === 2) {
    //         aniBtnSpGross.classList.add("green");
    //     }

    //     setTimeout(()=>{
    //         aniBtnSpKlein.classList.remove("green");
    //         aniBtnSpGross.classList.remove("green");
    //     },5000);
    // }

    function toggleWCFlushAnimation(value){
        var aniBtnSpKlein = document.getElementById("wcSpSmallButton");
        var aniBtnSpGross = document.getElementById("wcSpBigButton");
        var btnWCFWrapper = document.getElementById("btnWCFWrapper");
        var wcFlushButton = document.getElementById("wcFlushButton");

        btnWCFWrapper.classList.remove("hide");
        wcFlushButton.classList.add("active");
        aniBtnSpKlein.classList.add("hide");
        aniBtnSpGross.classList.add("hide");    

        setTimeout(()=>{            
            aniBtnSpKlein.classList.remove("hide");
            aniBtnSpGross.classList.remove("hide");
            wcFlushButton.classList.remove("active");
            btnWCFWrapper.classList.add("hide");
        },5000);
    }

    function toggleGrenzwerteAnimation(){
        var grenzwerteButton = document.getElementById("grenzwerteButton");
        grenzwerteButton.classList.add("active");
        setTimeout(()=>{
            grenzwerteButton.classList.remove("active");
        },2000);
    }

    useEffect(()=>{
        // do stuff here...
        var mqtt = require('mqtt')
        var Broker = "mqtts://testmqtt.syrconnect.de:8001";
        var options = {
            protocol: 'mqtts',
            clientId: 'TWTC' + Math.floor(Math.random() * 1000)
        };

        document.getElementById("maxtw").value = 9.5;
        document.getElementById("mintww").value = 33.0;

        var MasterTopic = "Dehoust/TWTempControl/ZVC/";

        var client  = mqtt.connect(Broker, options);

        client.on('connect', function () {      

            console.log('Trinkwasserhygiene CONNECTED as ' + options.clientId);            
            client.subscribe(MasterTopic + "IN/#", function (err) {;
                if (!err) {
                console.log('SUBBED TOPIC 1');                             
                }
            })
            client.subscribe(MasterTopic + "OUT/minTWW", function (err) {;
                if (!err) {
                console.log('SUBBED TOPIC minTWW');                             
                }
            })  
            client.subscribe(MasterTopic + "OUT/maxTW", function (err) {;
                if (!err) {
                console.log('SUBBED TOPIC maxTW');                             
                }
            })  
            client.subscribe(MasterTopic + "OUT/maxSpz", function (err) {;
                if (!err) {
                console.log('SUBBED TOPIC maxSpz');                             
                }
            })
            client.subscribe(MasterTopic + "OUT/state", function (err) {;
                if (!err) {
                console.log('SUBBED TOPIC STATE');
                getState();
                }
            })            
        }) 

        client.on('message', function (topic, message) {

        console.log("onMessageArrived Message:" + message);
        console.log("onMessageArrived Topic:" + topic);

            // For testing.
            // valveMessage("0:0");
            // tempMessage("255:221");

            if (topic.endsWith('maxTW')) 
            {               
                document.getElementById("maxtw").value = parseInt(message) / 10.0;
            }          
            
            if (topic.endsWith('minTWW')) 
            {
                document.getElementById("mintww").value = parseInt(message) / 10.0;  
            }
            
            if (topic.endsWith('maxSpz')) 
            {
                document.getElementById("maxSpz").value = parseInt(message);  
            }               
        
            if (topic.endsWith('temps')) {
                tempMessage(message);
                console.log("TEMPS" + message);

                var twOfflineIndicator = document.getElementById("trinkwasserOffline");
                var twOnlineIndicator = document.getElementById("trinkwasserOnline");            
    
                if (twOnlineIndicator !=  null) {
                    twOfflineIndicator.classList.add('hide');   
                    twOnlineIndicator.classList.remove('hide'); 
                }                   
            }
        
            if (topic.endsWith('valves')) {
                valveMessage(message);
                console.log("VALVES:" + message)
            }

            if (topic.endsWith('state')) {
                setModeState(message);
                console.log("STATE:" + message)
            }
        })

        client.on('close', function () {            
            console.log("Trinkwasserhygiene DISCONNECTED")        
        })

        function sendThreshold() {
            toggleGrenzwerteAnimation();
            console.log("Sending Grenzwerte");
            var t1 = document.getElementById("maxtw").value * 10.0;
            client.publish("Dehoust/TWTempControl/ZVC/OUT/maxTW", t1.toString(), {retain: true});
            var t2 = document.getElementById("mintww").value * 10.0;
            client.publish("Dehoust/TWTempControl/ZVC/OUT/minTWW", t2.toString(), {retain: true});
            var maxSpz = document.getElementById("maxSpz").value;
            client.publish("Dehoust/TWTempControl/ZVC/OUT/maxSpz", maxSpz.toString(), {retain: true});
        }

        function getState() {
            client.publish("Dehoust/TWTempControl/ZVC/OUT/getstate");
            console.log("Sending: GetState")
        }

        function setModeState(msg) {

            var modeValue = parseInt(msg);

            var btnAutoOn = document.getElementById("btnAutoOn");
            var btnAutoOff = document.getElementById("btnAutoOff");

            if (modeValue === 1) {
                btnAutoOn.classList.add('green');
                btnAutoOff.classList.remove('green');
            }
            else if (modeValue === 0) {
                btnAutoOn.classList.remove('green');
                btnAutoOff.classList.add('green');
            }
            setOpMode((modeValue)) 

            // SET VALVE CONTROLS
            var btnRunKalt = document.getElementById("btnRunKalt");
            var btnStopKalt = document.getElementById("btnStopKalt");
            var btnRunWarm = document.getElementById("btnRunWarm");
            var btnStopWarm = document.getElementById("btnStopWarm");

            // AUTOMATIK IS ON
            if (modeValue === 1) {
                btnRunKalt.classList.add('faded');
                btnStopKalt.classList.add('faded');
                btnRunWarm.classList.add('faded');
                btnStopWarm.classList.add('faded');
            }
            // AUTOMATIK IS OFF
            if (modeValue === 0) {
                btnRunKalt.classList.remove('faded');
                btnStopKalt.classList.remove('faded');
                btnRunWarm.classList.remove('faded');
                btnStopWarm.classList.remove('faded');
            }
        }

        function tempMessage(msg) {
            var msgStr = msg.toString();
            var res = msgStr.split(":");

            console.log('TEMPMSG' + msg);

            //Kaltwasser
            var ts1 = res[0] / 10.0 + " °C";            
            var TempTWControl = document.getElementById("TempTW");
            if (TempTWControl !== null) {
                setKaltwasserTemp((ts1))                
            }
            //Warmwasser
            var ts2 = res[1] / 10.0 + " °C";
            var TempTWWControl = document.getElementById("TempTWW");
            if (TempTWWControl !== null) {
                setWarmwasserTemp((ts2))  
            }
        }
        
        function valveMessage(msg) {

            var msgStr = msg.toString();
            var res = msgStr.split(":");     
            
            // SET VALVE CONTROLS
            var btnRunKalt = document.getElementById("btnRunKalt");
            var btnStopKalt = document.getElementById("btnStopKalt");
            var btnRunWarm = document.getElementById("btnRunWarm");
            var btnStopWarm = document.getElementById("btnStopWarm");

            if (res[0] === "1") {     
                setValve1State("geöffnet")                 
                document.getElementById("v1TextSpan").classList.add("textGreen");     
                document.getElementById("v1TextSpan").classList.remove("textRed");
                btnRunKalt.classList.add('green');
                btnStopKalt.classList.remove('green');
            } else {
                setValve1State("geschlossen")
                document.getElementById("v1TextSpan").classList.add("textRed");     
                document.getElementById("v1TextSpan").classList.remove("textGreen");  
                btnRunKalt.classList.remove('green');
                btnStopKalt.classList.add('green');
            }
        
            if (res[1] === "1") {
                setValve2State("geöffnet")  
                document.getElementById("v2TextSpan").classList.add("textGreen");     
                document.getElementById("v2TextSpan").classList.remove("textRed");
                btnRunWarm.classList.add('green');
                btnStopWarm.classList.remove('green');
            } else {
                setValve2State("geschlossen")  
                document.getElementById("v2TextSpan").classList.add("textRed");     
                document.getElementById("v2TextSpan").classList.remove("textGreen");  
                btnRunWarm.classList.remove('green');
                btnStopWarm.classList.add('green');
            }
        }

        const btnAutomodeOn = document.getElementById('btnAutoOn');
        btnAutomodeOn.addEventListener('click', (event) => {
            if (event.target.click) {                
                //client.publish("Dehoust/TWTempControl/ZVC/OUT/setstate", "1", {retain: true});
                client.publish("Dehoust/TWTempControl/ZVC/OUT/setstate", "1");
               }
        });

        const btnAutomodeOff = document.getElementById('btnAutoOff');
        btnAutomodeOff.addEventListener('click', (event) => {
            if (event.target.click) {                
                client.publish("Dehoust/TWTempControl/ZVC/OUT/setstate", "0");
               }
        });

        const btnStopKaltButton = document.getElementById('btnStopKalt');
        btnStopKaltButton.addEventListener('click', (event) => {
            if (event.target.click) {                
                client.publish("Dehoust/TWTempControl/ZVC/OUT/setV1", "0");
               }
        });

        const btnRunKaltButton = document.getElementById('btnRunKalt');
        btnRunKaltButton.addEventListener('click', (event) => {
            if (event.target.click) {                
                client.publish("Dehoust/TWTempControl/ZVC/OUT/setV1", "1");
               }
        });

        const btnStopWarmButton = document.getElementById('btnStopWarm');
        btnStopWarmButton.addEventListener('click', (event) => {
            if (event.target.click) {                
                client.publish("Dehoust/TWTempControl/ZVC/OUT/setV2", "0");
               }
        });

        const btnRunWarmButton = document.getElementById('btnRunWarm');
        btnRunWarmButton.addEventListener('click', (event) => {
            if (event.target.click) {                
                client.publish("Dehoust/TWTempControl/ZVC/OUT/setV2", "1");
               }
        });

        const grenzwerteButton = document.getElementById('grenzwerteButton');
        grenzwerteButton.addEventListener('click', (event) => {
            if (event.target.click) {                
                sendThreshold();
               }
        });


        // WC FLUSH
        const wcSpSmallBtn = document.getElementById('wcSpSmallButton');
        wcSpSmallBtn.addEventListener('click', (event) => {
            if (event.target.click) {                
                console.log("Kleine Spülung");
                client.publish("Dehoust/TWTempControl/ZVC/OUT/tecespuel", "1");
                toggleWCFlushAnimation(1);
               }
        });

        const grenzwerteBtn = document.getElementById('wcSpBigButton');
        grenzwerteBtn.addEventListener('click', (event) => {
            if (event.target.click) {                
                console.log("Grosse Spülung");
                client.publish("Dehoust/TWTempControl/ZVC/OUT/tecespuel", "2");
                toggleWCFlushAnimation(2);
               }
        });     
    
        return () => client.end(); // <-- kill on unmount
    }, []) // <-- empty dependency array

    return (
    <div className="module-wrapper">
        <div className="distancer">
            {/* // SUBNAVIGATION */}
            <div className="subnav">
                <div className="fltl">
                    <Link to="/Devices">
                        <Button variant="contained" color="primary" className="back-button" startIcon={<ArrowBackIosTwoToneIcon/>}>Geräte</Button>
                    </Link>
                </div>
                <div className="fltr">
                    <Button id="trinkwasserOnline" variant="outlined" color="primary" className="online fltr hide" disableElevation disabled startIcon={<LinkIcon/>}>Verbunden</Button>
                    <Button id="trinkwasserOffline" variant="outlined" color="primary" className="offline fltr" disableElevation disabled startIcon={<LinkOffIcon/>}>Keine Verbindung</Button>
                </div>
                <div className="spacer"></div>                     
            </div>
            {/* END SUBNAVIGATION*/}  
            <div className="outer-title distancer-bottom">
                TECE Hygienespülung
            </div>             
            <Row>
                <Col md="12" lg="6">
                    <Card className="deviceCard">
                        <Card.Header>
                            <div className="card-title">Temperaturen</div>                              
                        </Card.Header>
                        <Card.Body>
                            <div className='fltl'>
                                <div id="TempTW" name="TempTW"><span style={{width: 120, display: "inline-block", marginBottom: 25}}>Kaltwasser:</span> {kaltwasserTemp}</div>
                                <div id="TempTWW" name="TempTWW"><span style={{width: 120, display: "inline-block"}}>Warmwasser:</span> {warmwasserTemp}</div>
                            </div>
                            <div className='fltr'>
                                <Button id="btnAutoOn" variant="contained" color="primary" style={{width: 150, marginBottom: 10, marginLeft: 5, display: "inline-block"}}>Automatik An</Button><br></br>
                                <Button id="btnAutoOff" variant="contained" color="primary" style={{width: 150, marginBottom: 10 , marginLeft: 5, display: "inline-block"}}>Automatik Aus</Button>      
                            </div>                      
                        </Card.Body>
                    </Card>
                    <Card className="deviceCard">
                        <Card.Header>
                            <div className="card-title">Spülventile</div>                            
                        </Card.Header>
                        <Card.Body>
                            <div className='fltl'>
                                <div id="V1" name="V1"><span style={{width: 120, display: "inline-block", marginBottom: 25}}>Kaltwasser:</span><span id="v1TextSpan" className='textRed'>{valve1State}</span></div>
                                <div id="V2" name="V2"><span style={{width: 120, display: "inline-block"}}>Warmwasser:</span><span id="v2TextSpan" className='textRed'>{valve2State}</span></div>                       
                            </div>  
                            <div className='fltr'>
                                <Button id="btnRunKalt" variant="contained" color="primary" style={{width: 75, marginBottom: 10, marginLeft: 5}}>Auf</Button>
                                <Button id="btnStopKalt" variant="contained" color="primary" style={{width: 75, marginBottom: 10, marginLeft: 5}}>Zu</Button>
                                <br></br>
                                <Button id="btnRunWarm" variant="contained" color="primary" style={{width: 75, marginBottom: 10, marginLeft: 5}}>Auf</Button>     
                                <Button id="btnStopWarm" variant="contained" color="primary" style={{width: 75, marginBottom: 10, marginLeft: 5}} >Zu</Button>    
                            </div> 
                        </Card.Body>                       
                    </Card>
                </Col>
                <Col md="12" lg="6">
                    <Card className="deviceCard">
                        <Card.Header>
                            <div className="card-title">Grenzwerte</div>                             
                        </Card.Header>
                        <Card.Body className="grenzewerte-card-body" style={{paddingBottom: 65}}>
                            <div className="twCardRow">
                                <div className="twCardLabel">max. Kaltwassertemp.</div>
                                <div className='fltr'><input type="number" className="dashboardInput" id="maxtw" name="maxtw" min="1.0" max="65.0" step="0.5"/><span className="grenzwerteUnit">°C</span></div>
                            </div>
                            <div className="twCardRow">
                                <div className="twCardLabel">min. Warmwassertemp.</div>  
                                <div className='fltr'><input type="number" className="dashboardInput" id="mintww" name="mintww" min="1.0" max="65.0" step="0.5"/><span className="grenzwerteUnit">°C</span></div>
                            </div>                          
                            <div className="twCardRow">
                                <div className="twCardLabel">max. Spülzeit</div>
                                <div className='fltr'><input type="number" className="dashboardInput" id="maxSpz" name="maxSpz" min="1.0" max="30.0" step="1"/><span className="grenzwerteUnit">Min</span></div>
                            </div>                                                    
                            {/* min Zirkulationstempertur */}
                            {/* <div className="btn-trinkwasser">
                                <Button id="grenzwerteButton" variant="contained" color="primary" style={{width: 220}}>Grenzwerte setzen</Button>
                            </div> */}
                            <div className="btnGWWrapper">
                                <div id="grenzwerteButton" className="grenzwerteButton" style={{width: 220, color: "#fff", fontWeight: "500", fontSize: 14}}><div className="soft"><a><span className="btn-text">GRENZWERTE SETZEN</span></a></div></div>  
                            </div>
                        </Card.Body>
                    </Card>
                    <Card className="deviceCard">
                        <Card.Header>
                            <div className="card-title">WC-Spülung</div>                             
                        </Card.Header>
                        <Card.Body style={{height: 85}}>
                            <div className="btn-wcspulung">
                                <Button id="wcSpSmallButton" variant="contained" color="primary" className="aniBtnSpKlein">Kleine Spülung</Button>
                                <Button id="wcSpBigButton" variant="contained" color="primary" className="aniBtnSpGross">Große Spülung</Button>
                            </div> 
                            <div id="btnWCFWrapper" className="btnWCFWrapper hide">
                                <div id="wcFlushButton" className="wcFlushButton" style={{width: 300, color: "#fff", fontWeight: "500", fontSize: 14}}><div className="soft"><a><span className="btn-text">WC-SPÜLUNG</span></a></div></div>  
                            </div>                           
                        </Card.Body>
                    </Card>
                </Col>               
            </Row>
            <br></br><br></br>
            <Row>
                <Col>
                    <TrinkwasserHygGraph/>
                </Col>               
            </Row>            
        </div>        
    </div>
    )
}

export default Trinkwasserhygiene;