import React, {Component, useState, Fragment, useEffect} from 'react';
import {Link} from 'react-router-dom';
import Card from 'react-bootstrap/Card';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import ArrowBackIosTwoToneIcon from '@material-ui/icons/ArrowBackIosTwoTone';
import Button from '@material-ui/core/Button';
import Slider from '@material-ui/core/Slider';
import LinkIcon from '@material-ui/icons/Link';
import LinkOffIcon from '@material-ui/icons/LinkOff';
import DigitalventilGraph from './DigitalventilGraph';

function Digitalventil() {
    
    const [valueDigitalVentil1, setValueDigitalVentil1] = useState([0]);
    const updateDVRange1=(e,data)=>{
        setValueDigitalVentil1(data)       
    }

    const ventilMarks = [
        {
            value: 0, label: '0%'
        },
        {
            value: 10, label: '10%'
        },
        {
            value: 20, label: '20%'
        },
        {
            value: 30, label: '30%'
        },
        {
            value: 40, label: '40%'
        },    
        {
            value: 50, label: '50%'
        },
        {
            value: 60, label: '60%'
        },
        {
            value: 70, label: '70%'
        },
        {
            value: 80, label: '80%'
        },
        {
            value: 90, label: '90%'
        },
        {
            value: 100, label: '100%'
        },
    ]
    const [dv1State, setDv1State] = useState([0]);
    const [dv1Temp, setDv1Temp] = useState([0]);


    useEffect(()=>{
        // do stuff here...
        var mqtt = require('mqtt')
        var Broker = "mqtts://testmqtt.syrconnect.de:8001";
        var options = {
            protocol: 'mqtts',
            clientId: 'EZGWebsiban' + Math.floor(Math.random() * 1000)
        };

        var Topic1 = "Dehoust/Fluehs/ZVC/";        

        var client  = mqtt.connect(Broker, options);

        client.on('connect', function () {
            var dvOfflineIndicator = document.getElementById("dvOffline");
            var dvOnlineIndicator = document.getElementById("dvOnline");            

            if (dvOnlineIndicator !=  null) {
                dvOfflineIndicator.classList.add('hide');   
                dvOnlineIndicator.classList.remove('hide'); 
            }         

            console.log('Digitalventil CONNECTED as ' + options.clientId);            
            client.subscribe(Topic1 + "IN/temp", function (err) {;
                if (!err) {
                console.log('SUBBED TOPIC: temp');                             
                }
            })
            client.subscribe(Topic1 + "OUT/abs1state", function (err) {;
                if (!err) {
                console.log('SUBBED TOPIC: abs1state');                             
                }
            })

        })

        client.on('message', function (topic, message) {
        // message is Buffer  
        console.log("onMessageArrived Message:" + message);
        console.log("onMessageArrived Topic:" + topic);

            // Set Slider
            if (topic.endsWith("abs1state")) {      
                setDv1State(parseInt(message));
                setValueDigitalVentil1(parseInt(message))
            }

            // Set Temp
            if (topic.endsWith('temp')) 
            {                
                setDv1Temp((parseInt(message) / 10.0))                
            }
        })

        client.on('close', function () {            
            console.log("Digitalventil DISCONNECTED")        
        })

        // const dvSubmit1 = document.getElementById('dvSubmit1');
        // dvSubmit1.addEventListener('click', (event) => {
        //     if (event.target.click) {   

        //         var sli2 = document.getElementById('sliderDV1');
        //         var daVal =  parseFloat(sli2.innerText);             
                
        //         console.log('SENDING:' + daVal)

        //         client.publish("Dehoust/Fluehs/ZVC/IN/abs1state", daVal.toString());
        //        }
        // });   

        // BUTTON PROGRESS ANIMATION
        const btnwp = document.querySelector(".btnWithProgress");
        btnwp.addEventListener("click", () =>{

            //var sli2 = document.getElementById('sliderDV1');
            var sli2 = document.getElementsByName("sliderDV1");
            var daVal =  parseFloat(sli2[0].value);                
            console.log('SENDING:' + daVal)

            // DISABLE FOR LOCAL BUTTON TESTING
            client.publish("Dehoust/Fluehs/ZVC/IN/abs1state", daVal.toString());
            toggleSendingAnimation();
        });

        function toggleSendingAnimation(){
            btnwp.classList.add("active");
            setTimeout(()=>{
                btnwp.classList.remove("active");
              //btn.querySelector("span").innerText = "OK";
              //btn.classList.add("btnWithProgressGreen");
  
              // setTimeout(()=>{
              //     btn.classList.remove("btnWithProgressGreen");
              //     btn.querySelector("span").innerText = "SET VENTIL 1";
              // },2000)
            },2000);
        }

        //Sollwert 0
        const sollwert0Button = document.getElementById('dvsollwert0');
        sollwert0Button.addEventListener('click', (event) => {                      
            client.publish("Dehoust/Fluehs/ZVC/IN/abs1state", "0");         
            console.log('Sending: Sollwert 0')
            toggleSendingAnimation();
        });

        //Sollwert 10
        const sollwert10Button = document.getElementById('dvsollwert10');
        sollwert10Button.addEventListener('click', (event) => {     
            client.publish("Dehoust/Fluehs/ZVC/IN/abs1state", "10");          
            console.log('Sending: Sollwert 10')
            toggleSendingAnimation();
        });

        //Sollwert 20
        const sollwert20Button = document.getElementById('dvsollwert20');
        sollwert20Button.addEventListener('click', (event) => {    
            client.publish("Dehoust/Fluehs/ZVC/IN/abs1state", "20");         
            console.log('Sending: Sollwert 20')
            toggleSendingAnimation();
        });

        //Sollwert 30
        const sollwert30Button = document.getElementById('dvsollwert30');
        sollwert30Button.addEventListener('click', (event) => {     
            client.publish("Dehoust/Fluehs/ZVC/IN/abs1state", "30");          
            console.log('Sending: Sollwert 30')
            toggleSendingAnimation();
        });        

        //Sollwert 40
        const sollwert40Button = document.getElementById('dvsollwert40');
        sollwert40Button.addEventListener('click', (event) => {      
            client.publish("Dehoust/Fluehs/ZVC/IN/abs1state", "40");         
            console.log('Sending: Sollwert 40')
            toggleSendingAnimation();
        });

        //Sollwert 50
        const sollwert50Button = document.getElementById('dvsollwert50');
        sollwert50Button.addEventListener('click', (event) => {     
            client.publish("Dehoust/Fluehs/ZVC/IN/abs1state", "50");         
            console.log('Sending: Sollwert 50')
            toggleSendingAnimation();
        });         

        //Sollwert 60
        const sollwert60Button = document.getElementById('dvsollwert60');
        sollwert60Button.addEventListener('click', (event) => {        
            client.publish("Dehoust/Fluehs/ZVC/IN/abs1state", "60");       
            console.log('Sending: Sollwert 60')
            toggleSendingAnimation();
        });

        //Sollwert 70
        const sollwert70Button = document.getElementById('dvsollwert70');
        sollwert70Button.addEventListener('click', (event) => {     
            client.publish("Dehoust/Fluehs/ZVC/IN/abs1state", "70");         
            console.log('Sending: Sollwert 70')
            toggleSendingAnimation();
        });  

        //Sollwert 80
        const sollwert80Button = document.getElementById('dvsollwert80');
        sollwert80Button.addEventListener('click', (event) => { 
            client.publish("Dehoust/Fluehs/ZVC/IN/abs1state", "80");          
            console.log('Sending: Sollwert 80')
            toggleSendingAnimation();
        });

        //Sollwert 90
        const sollwert90Button = document.getElementById('dvsollwert90');
        sollwert90Button.addEventListener('click', (event) => {     
            client.publish("Dehoust/Fluehs/ZVC/IN/abs1state", "90");      
            console.log('Sending: Sollwert 90')
            toggleSendingAnimation();
        });        

        //Sollwert 100
        const sollwert100Button = document.getElementById('dvsollwert100');
        sollwert100Button.addEventListener('click', (event) => {
            client.publish("Dehoust/Fluehs/ZVC/IN/abs1state", "100");  
            console.log('Sending: Sollwert 100')    
            toggleSendingAnimation();    
        });  
    
        return () => client.end(); // <-- kill on unmount
    }, []) // <-- empty dependency array

    return (
        <div className="module-wrapper">
            <div className="distancer">
                {/* // SUBNAVIGATION */}
                <div className="subnav">
                    <div className="fltl">
                        <Link to="/Devices">
                            <Button variant="contained" color="primary" className="back-button" startIcon={<ArrowBackIosTwoToneIcon/>}>Geräte</Button>
                        </Link>
                    </div>
                    <div className="fltr">
                        <Button id="dvOnline" variant="outlined" color="primary" className="online fltr hide" disableElevation disabled startIcon={<LinkIcon/>}>Verbunden</Button>
                        <Button id="dvOffline" variant="outlined" color="primary" className="offline fltr" disableElevation disabled startIcon={<LinkOffIcon/>}>Verbindung</Button>
                    </div>
                    <div className="spacer"></div>                     
                </div>
                {/* END SUBNAVIGATION*/}  
                <div className="outer-title">
                    Digitalventil
                </div>
                <Row>
                    <Col md="12" lg="12" xl="6">
                        <Card className="deviceCard">
                            <Card.Header>
                                <div className="card-title">Digitalventil 1</div> 
                                <div className="card-title-right"><span id="dvCard1temp">{dv1State} % / {dv1Temp}</span> °C</div>                         
                            </Card.Header>
                            <Card.Body>
                                <div className="card-ventil-buttons">
                                    <Button id="dvsollwert0" variant="contained" color="" className="generic-btn ecbutton">0%</Button>
                                    <Button id="dvsollwert10" variant="contained" color="" className="generic-btn  ecbutton">10%</Button>
                                    <Button id="dvsollwert20" variant="contained" color="" className="generic-btn  ecbutton">20%</Button>
                                    <Button id="dvsollwert30" variant="contained" color="" className="generic-btn  ecbutton">30%</Button>
                                    <Button id="dvsollwert40" variant="contained" color="" className="generic-btn  ecbutton">40%</Button>
                                    <Button id="dvsollwert50" variant="contained" color="" className="generic-btn  ecbutton">50%</Button>  
                                    <Button variant="contained" color="" className="magic-btn"></Button>                               
                                    <Button id="dvsollwert60" variant="contained" color="" className="generic-btn  ecbutton">60%</Button>
                                    <Button id="dvsollwert70" variant="contained" color="" className="generic-btn  ecbutton">70%</Button>
                                    <Button id="dvsollwert80" variant="contained" color="" className="generic-btn  ecbutton">80%</Button>
                                    <Button id="dvsollwert90" variant="contained" color="" className="generic-btn  ecbutton">90%</Button>
                                    <Button id="dvsollwert100" variant="contained" color="" className="generic-btn  ecbutton">100%</Button> 
                                </div>
                                <hr></hr>                               
                                <div className='sliderWrapper'>
                                    <Slider name="sliderDV1" id="sliderDV1" getarialabel="custom thumb label" min={0} max={100} size="small" step={1} defaultValue={0} valueLabelDisplay="on" value={valueDigitalVentil1} marks={ventilMarks} onChange={updateDVRange1}/>                                    
                                </div>    
                                <br/><br/>                    
                                {/* <Button id="dvSubmit1" variant="contained" color="primary">Setze Ventil 1</Button>       */}
                                <div className="btnWPWrapper">
                                    <div className="btnWithProgress"><div className="soft"><a> <span className="btn-text">SET VENTIL 1</span></a></div></div>  
                                </div>                                
                            </Card.Body>
                        </Card>
                    </Col>            
                </Row> 
                <div>
                    <DigitalventilGraph/>
                </div>
            </div>
        </div>
    )
}

export default Digitalventil;