import React, {Component, useState, Fragment, useEffect} from 'react';
import {Link} from 'react-router-dom';
import Card from 'react-bootstrap/Card';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import ArrowBackIosTwoToneIcon from '@material-ui/icons/ArrowBackIosTwoTone';
import Button from '@material-ui/core/Button';
import Slider from '@material-ui/core/Slider';
import LinkIcon from '@material-ui/icons/Link';
import LinkOffIcon from '@material-ui/icons/LinkOff';
import SensorGraph from '../temperaturfuhler4/SensorGraph4';


export default class Temperaturfuehler extends Component{

    constructor(props) {
        super(props);

        this.state = {
            
        };
    }
    
    render() {   
        return (
        <div className="module-wrapper">
            <div className="distancer">
                {/* // SUBNAVIGATION */}
                <div className="subnav">
                    <div className="fltl">
                        <Link to="/Devices">
                            <Button variant="contained" color="primary" className="back-button" startIcon={<ArrowBackIosTwoToneIcon/>}>Geräte</Button>
                        </Link>
                    </div>
                    {/* <div className="fltr">
                        <Button id="tempfeelersOnline" variant="outlined" color="primary" className="online fltr hide" disableElevation disabled startIcon={<LinkIcon/>}>Verbunden</Button>
                        <Button id="tempfeelersOffline" variant="outlined" color="primary" className="offline fltr" disableElevation disabled startIcon={<LinkOffIcon/>}>Verbindung</Button>
                    </div> */}
                    <div className="spacer"></div>                     
                </div>
                {/* END SUBNAVIGATION*/}  
                <div className="outer-title">
                    Trinkwasser
                </div>                
            </div>
            <div>
                <SensorGraph/>
            </div>
        </div>
        );
    }
}
