import React, {Component} from 'react';
import {Link, Router} from 'react-router-dom';
import Header from './Header';
import DeviceMenu from './DeviceMenu';
import Icon from "./Icons.js";
import Card from "react-bootstrap/Card";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import ArrowBackIosTwoToneIcon from '@material-ui/icons/ArrowBackIosTwoTone';
import AddCircleTwoToneIcon from '@material-ui/icons/AddCircleTwoTone';

// DEVICES
import EmojiObjectsTwoToneIcon from '@material-ui/icons/EmojiObjectsTwoTone';
import OfflineBoltTwoToneIcon from '@material-ui/icons/OfflineBoltTwoTone';
import LocalDrinkTwoToneIcon from '@material-ui/icons/LocalDrinkTwoTone';
import WhatshotTwoToneIcon from '@material-ui/icons/WhatshotTwoTone';
import OpacityIcon from '@material-ui/icons/Opacity';
import SettingsIcon from '@material-ui/icons/Settings';
import WindowIcon from '@material-ui/icons/ViewColumn';
import Whatshot from '@material-ui/icons/Whatshot';


import DehoustD from '../assets/dehoust_2.png';
import SyrIcon from '../assets/syr_icon.png';

import Button from '@material-ui/core/Button';

class Devices extends Component {

    constructor(props) {
        super(props);
        this.state = {
          currentProjectId: ""
        };
      }

    underMaintenance() {
        alert("Wartungsarbeiten - bitte versuchen Sie es später nochmal.");
    }

    render() {
        return (
            <div className="distancer">
                <Header/>
                <div className="">
                    {/* // SUBNAVIGATION */}
                     <div className="subnav">
                        <div className="fltl">
                            <Link to="/">
                                <Button variant="contained" color="primary" className="back-button" startIcon={<ArrowBackIosTwoToneIcon/>}>Projekte</Button>
                            </Link>
                         </div>
                         <div className="fltr">                            
                            <Button variant="outlined" disabled startIcon={<AddCircleTwoToneIcon/>}>Neues Modul</Button>                                                    
                         </div>
                        <div className="spacer"></div>                     
                    </div>
                    {/* END SUBNAVIGATION*/}
                    <div className="outer-title distancer-bottom">
                        Experten Zentrum Gebäudetechnik 
                    </div>
                    {/* <div className="outer-title">
                        ZVconnect
                    </div>
                    <div className="spacer"><br></br></div> */}
                    <div className="multi-card-wrapper device-cards">
                        <Row>
                            <Col>
                                <Link to="/Volt">
                                    <Card.Header>
                                        <div className="card-avatar yellow"><OfflineBoltTwoToneIcon/></div>
                                        <div className="card-title-devices">0-10 Volt Steuerung</div>
                                    </Card.Header>
                                </Link>
                            </Col>
                            <Col>
                                <Link to="/Lights">
                                    <Card.Header>
                                        <div className="card-avatar orange"><EmojiObjectsTwoToneIcon/></div>
                                        <div className="card-title-devices">Außenbeleuchtung</div>
                                    </Card.Header>
                                </Link>                        
                            </Col>
                            <Col>
                                <Link to="/Digitalventil">
                                    <Card.Header>
                                        <div className="card-avatar orange"><SettingsIcon/></div>
                                        <div className="card-title-devices">Digitalventil</div>
                                    </Card.Header>
                                </Link>                        
                            </Col>
                            <Col>
                                <Link to="/Fenstersteuerung">
                                    <Card.Header>
                                        <div className="card-avatar orange"><WindowIcon/></div>
                                        <div className="card-title-devices">E-Control Fenster</div>
                                    </Card.Header>
                                </Link>                        
                            </Col>
                            <Col>
                                <Link to="/Fuellstandswaechter">
                                    <Card.Header>
                                        <div className="card-avatar orange"><OpacityIcon/></div>
                                        <div className="card-title-devices">Füllstandswächter</div>
                                    </Card.Header>
                                </Link>                        
                            </Col>                
                            <Col>
                                <Link to="/Hygboxlight">
                                    <Card.Header>
                                        <div className="card-avatar orange"><OpacityIcon/></div>
                                        <div className="card-title-devices">Hygbox Light</div>
                                    </Card.Header>
                                </Link>                        
                            </Col>                     
                            
                            <Col>
                                <Link to="/Rauchmeldersystem">
                                    <Card.Header>
                                        <div className="card-avatar red"><Whatshot/></div>
                                        <div className="card-title-devices">Rauchmeldersystem</div>
                                    </Card.Header>
                                </Link>                        
                            </Col>
                            <Col>
                                <Link to="/Raumtemperatur">
                                    <Card.Header>
                                        <div className="card-avatar red"><Icon name="thermometer"/></div>
                                        <div className="card-title-devices">Raumtemperatur</div>
                                    </Card.Header>
                                </Link>
                            </Col>
                            <Col>
                                <Link to="/Hygienespuelkasten">
                                    <Card.Header>
                                        <div className="card-avatar light-blue"><LocalDrinkTwoToneIcon/></div>
                                        <div className="card-title-devices">Spülkasten Einfach</div>
                                    </Card.Header>
                                </Link>                        
                            </Col>                             
                            {/* <Col>
                                <Link to="/Temperaturfuhler">
                                    <Card.Header>
                                        <div className="card-avatar green"><Icon name="thermometer"/></div>
                                        <div className="card-title-devices">Temperaturfühler (3er)</div>
                                    </Card.Header>
                                </Link>                        
                            </Col> */}
                            <Col>
                                <Link to="/Temperaturfuhler4">
                                    <Card.Header>
                                        <div className="card-avatar red"><Icon name="thermometer"/></div>
                                        <div className="card-title-devices">Trinkwasser</div>
                                    </Card.Header>
                                </Link>                        
                            </Col>
                            <Col md="6">
                                <Link to="/Trinkwasserhygiene">
                                    <Card.Header>
                                        <div className="card-avatar light-blue"><LocalDrinkTwoToneIcon/></div>
                                        <div className="card-title-devices">TECE Hygienespülung</div>
                                    </Card.Header>
                                </Link>                        
                            </Col>
                            <Col md="6">
                                <Link to="/Waermepumpe">
                                    <Card.Header>
                                        <div className="card-avatar light-blue"><SettingsIcon/></div>
                                        <div className="card-title-devices">Wärmepumpe</div>
                                    </Card.Header>
                                </Link>                        
                            </Col>                   
                        </Row>
                        {/* <div className="spacer"><br></br></div>
                        <div className="outer-subtitle">
                            Dehoust Connect
                        </div>
                        <div className="spacer"><br></br></div> */}
                        <div className="spacer"><br></br></div>
                        <Row>
                            <Col md="6">
                                <Link to="/Trennstation">
                                    <Card.Header>
                                        <div className="card-avatar dehoust-blue"><img style={{height: 44}} src={DehoustD} alt="ZVconnect"/>  </div>
                                        <div className="card-title-devices">Trennstation mit Doppelpumpe</div>
                                    </Card.Header>
                                </Link>                            
                            </Col>  
                            <Col md="6">
                                <Link to="/Lexplussl">                           
                                    <Card.Header>
                                        <div className="card-avatar syr-blue"><img style={{height: 44}} src={SyrIcon} alt="ZVconnect"/>  </div>
                                        <div className="card-title-devices">Showroom LEX Plus SL</div>
                                    </Card.Header>
                                </Link>                                                          
                            </Col>  
                        </Row>
                        <Row>
                            <Col md="6">                                
                                <Card.Header className="underMaintenance" onClick={() => { this.underMaintenance() }}>
                                    <div className="card-avatar syr-blue"><img style={{height: 44}} src={SyrIcon} alt="ZVconnect"/>  </div>
                                    <div className="card-title-devices">Showroom HygBox Kalt</div>
                                </Card.Header>                                                          
                            </Col>  
                            <Col md="6">                                                         
                                <Card.Header className="underMaintenance" onClick={() => { this.underMaintenance() }}>
                                    <div className="card-avatar syr-blue"><img style={{height: 44}} src={SyrIcon} alt="ZVconnect"/>  </div>
                                    <div className="card-title-devices">Showroom HygBox Warm</div>
                                </Card.Header>                                                                                        
                            </Col>  
                        </Row>
                    </div>
                </div>
                {/* <DeviceMenu/> */}
            </div>
        );
    }
}

export default Devices;