import React, {Component, props} from 'react';
import {Route, Link} from 'react-router-dom';
import Icon from "./Icons";

class DeviceMenu extends Component {
    render() {
        return (
            <nav className="navbar fixed-bottom">
                {/* Mobile Button Here */}
                <div className="navbar-collapse">
                    <ul className="navbar-nav margin-auto">
                        <li className="nav-item">
                            <a className="nav-link">
                                <Icon name="dashboard"/>
                                <span>Dashboard</span>
                            </a>                            
                        </li>
                        <li className="nav-item">                        
                            <Link to="../Devices" className="nav-link active">
                                <Icon name="devices"/>
                                <span>Geräte</span>
                            </Link>                            
                        </li>                                   
                    </ul>
                </div>
            </nav>
        );
    }
}

export default DeviceMenu;