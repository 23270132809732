import React, {Component, useState, Fragment, useEffect} from 'react';
import Chart from 'react-apexcharts'
import Button from '@material-ui/core/Button';
import Card from 'react-bootstrap/Card';
import Table from 'react-bootstrap/Table';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Icon from "../../Icons";

class Lexplussltable extends Component {
    
    constructor(props) {
        super(props);

        this.todaysDate = '';
        this.todaysDateSimplified = '';
        this.currentlyDisplayedDate = '';
        this.currentlyDisplayedDateSimplified = '';

        this.state = {

            authToken: '',
            showConfig: 0,        
            syrJson: '',
            getVLV: '',
            getLAN: ''
        } 
    }

    switchRefresh(){
        this.getSensorData(this.state.currentSensorID)
    }

    UNSAFE_componentWillMount() {
        this.getSensorData(this.state.currentSensorID);   
        this.setState({todaysDate: new Date()})
    }

    componentDidUpdate() {

    }

    getSensorData(id){

        const requestOptionsTriTemp = {
            method: 'GET',
            headers: {'Content-Type': 'application/json'},
        }

        fetch('https://api.zvconnect.de/syr/', requestOptionsTriTemp)
        .then(response => response.json())
        .then(responseJson => {this.parseSyrLexData(responseJson);
        })
    }

    parseSyrLexData(responseJson) {        

        var jsonString = JSON.stringify(responseJson);

        //var testJSON = {"Syr": {"getSRN" : "185121984",  "getVER" : "2.9" ,  "getTYP" : "80"}}        

        var unslashedJson = jsonString.slice(12,-5);
        unslashedJson = unslashedJson.replaceAll("\'","\"");
        unslashedJson = unslashedJson.replaceAll("\{","");
        unslashedJson = unslashedJson.replaceAll("\}","");
        unslashedJson = "{" + unslashedJson + "}";

        // unslashedJson = unslashedJson.replace(/,([^,]*)$/, '');
        // unslashedJson = unslashedJson + ']}';
        // unslashedJson = unslashedJson.replaceAll(" ","");

        var jsonJson = JSON.parse(unslashedJson);        

        this.setState({syrJson: (jsonJson)})

        // getVLV
        if (jsonJson.getVLV === "20") {
            this.setState({getVLV: "Geöffnet"})
        }
        else if (jsonJson.getVLV === "10") {
            this.setState({getVLV: "Geschlossen"})
        }
        else if (jsonJson.getVLV === "11") {
            this.setState({getVLV: "Schließend"})
        }
        else if (jsonJson.getVLV === "21") {
            this.setState({getVLV: "Öffnend"})
        }

        // getLAN   
        if (jsonJson.getLAN === "1") {
            this.setState({getLAN: "Deutsch"})
        }
        else if (jsonJson.getLAN === "2") {
            this.setState({getLAN: "Englisch"})
        }

    }
    
    render() {
        return (  
            <div className="tempChart-Wrapper">
                <Row>
                    <Col lg="6">
                        <Card className="tempChart-card">
                            <Card.Header>
                                <div className="card-title">Geräteinformationen</div>
                            </Card.Header> 
                            <Card.Body className='syrTable'>                    
                                <div className='syrTableColorSetter'><span className='spanWidthSetterSyr'>Gerätename:</span><span className='spanRightSetterSyrSyr'>LEX Plus 10 SL</span></div>
                                <div><span className='spanWidthSetterSyr'>Verbindung:</span><span className='spanRightSetterSyrSyr'>Online</span></div>
                                <div className='syrTableColorSetter'><span className='spanWidthSetterSyr'>Absperrung:</span><span className='spanRightSetterSyr'></span>{this.state.getVLV}</div>
                                <div><span className='spanWidthSetterSyr'>Salzvorrat:</span><span className='spanRightSetterSyr'>{this.state.syrJson.getSS1} Wochen</span></div>
                                <div className='syrTableColorSetter'><span className='spanWidthSetterSyr'>Weichwasserhärte:</span><span className='spanRightSetterSyr'>{this.state.syrJson.getOWH} °dH</span></div>
                                {/* <div><span className='spanWidthSetterSyr'>Profil:</span><span className='spanRightSetterSyr'>{this.state.syrJson.getPNx}</span></div> */}
                                <div><span className='spanWidthSetterSyr'>Serien-Nr.:</span><span className='spanRightSetterSyr'>{this.state.syrJson.getSRN}</span></div>
                                <div className='syrTableColorSetter'><span className='spanWidthSetterSyr'>Typ:</span><span className='spanRightSetterSyr'>LEX Plus SL</span></div>
                                <div><span className='spanWidthSetterSyr'>Firmware:</span><span className='spanRightSetterSyr'>LEX PLUS SL {this.state.syrJson.getVER}</span></div>
                                <div className='syrTableColorSetter'><span className='spanWidthSetterSyr'>Busnr. / Intervall:</span><span className='spanRightSetterSyr'>0 / 10 Sekunden</span></div>
                            </Card.Body>
                        </Card>
                        <Card className="tempChart-card">
                                <Card.Header>
                                    <div className="card-title">Netzwerkinformationen</div>
                                </Card.Header> 
                                <Card.Body className='syrTable'>
                                    <div className='syrTableColorSetter'><span className='spanWidthSetterSyr'>IP-Adresse:</span><span className='spanRightSetterSyr'>{this.state.syrJson.getIPA}</span></div>
                                    <div><span className='spanWidthSetterSyr'>Gateway:</span><span className='spanRightSetterSyr'>{this.state.syrJson.getDGW}</span></div>
                                    <div className='syrTableColorSetter'><span className='spanWidthSetterSyr'>MAC-Adresse:</span><span className='spanRightSetterSyr'>{this.state.syrJson.getMAC}</span></div>
                                </Card.Body>
                        </Card>
                    </Col>
                    <Col lg="6">
                        <Card className="tempChart-card">
                            <Card.Header>
                                <div className="card-title">Allgemein</div>
                            </Card.Header> 
                            <Card.Body className='syrTable'>
                                <div className='syrTableColorSetter'><span className='spanWidthSetterSyr'>Eingangsdruck:</span><span className='spanRightSetterSyr'>{this.state.syrJson.getPRS /10} bar</span></div>      
                                <div><span className='spanWidthSetterSyr'>Temperatur:</span><span className='spanRightSetterSyr'>{this.state.syrJson.getCEL /10} °C</span></div>
                                <div className='syrTableColorSetter'><span className='spanWidthSetterSyr'>Aktueller Durchfluss:</span><span className='spanRightSetterSyr'>{this.state.syrJson.getFLO} l/h</span></div>    
                                <div><span className='spanWidthSetterSyr'>Aktuell gezapftes Volumen:</span><span className='spanRightSetterSyr'>{this.state.syrJson.getAVO}</span></div>  
                                <div className='syrTableColorSetter'><span className='spanWidthSetterSyr'>Aktueller Alarm:</span><span className='spanRightSetterSyr'>{this.state.syrJson.getALA}</span></div>  
                                <div><span className='spanWidthSetterSyr'>Leitfähigkeit:</span><span className='spanRightSetterSyr'>{this.state.syrJson.getCND} µS/cm</span></div> 
                                <div className='syrTableColorSetter'><span className='spanWidthSetterSyr'>Nächste Wartung:</span><span className='spanRightSetterSyr'>-</span></div> 
                                <div><span className='spanWidthSetterSyr'>Buzzer bei Alarm:</span><span className='spanRightSetterSyr'>-</span></div>
                                <div className='syrTableColorSetter'><span className='spanWidthSetterSyr'>Drucksensor:</span><span className='spanRightSetterSyr'>{this.state.syrJson.getPST ? 'An' : 'Aus'}</span></div>
                                <div><span className='spanWidthSetterSyr'>Temperatursensor:</span><span className='spanRightSetterSyr'>{this.state.syrJson.getCES ? 'An' : 'Aus'}</span></div>
                                <div className='syrTableColorSetter'><span className='spanWidthSetterSyr'>Leitfähigkeitssensor:</span><span className='spanRightSetterSyr'>{this.state.syrJson.getCNS ? 'An' : 'Aus'}</span></div>
                                <div><span className='spanWidthSetterSyr'>Keine Turbinenimpulse:</span><span className='spanRightSetterSyr'>{this.state.syrJson.getNPS} Sekunden</span></div>
                                <div className='syrTableColorSetter'><span className='spanWidthSetterSyr'>Ventil-Nachlauf:</span><span className='spanRightSetterSyr'>{this.state.syrJson.getTN /10} Sekunden</span></div>
                                <div><span className='spanWidthSetterSyr'>Sprache:</span><span className='spanRightSetterSyr'>{this.state.getLAN}</span></div>
                                <div className='syrTableColorSetter'><span className='spanWidthSetterSyr'>Sommerzeit:</span><span className='spanRightSetterSyr'>-</span></div>
                                <div><span className='spanWidthSetterSyr'>Zeitzone:</span><span className='spanRightSetterSyr'>UTC +1,0</span></div>
                                <div className='syrTableColorSetter'><span className='spanWidthSetterSyr'>Status:</span><span className='spanRightSetterSyr'>Störung Chlorgenerator</span></div>
                                <div><span className='spanWidthSetterSyr'>Nachrichten:</span><span className='spanRightSetterSyr'>-</span></div>
                                <div className='syrTableColorSetter'><span className='spanWidthSetterSyr'>Bildschirmtyp:</span><span className='spanRightSetterSyr'>Single</span></div>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>  
            
            </div>
        );
    }
}
export default Lexplussltable;