import React, { Component, useState, setState, Fragment, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Card from 'react-bootstrap/Card';

import LinkIcon from '@material-ui/icons/Link';
import LinkOffIcon from '@material-ui/icons/LinkOff';
import ArrowBackIosTwoToneIcon from '@material-ui/icons/ArrowBackIosTwoTone';

import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import Button from '@material-ui/core/Button';
import Slider from '@material-ui/core/Slider';

import FensterStrCard from './FensterStrCard';

import stockwerk0 from '../../../assets/images/modules/rauchmeldersystem/rauchmelder_stockwerk0.png';
import stockwerk1 from '../../../assets/images/modules/rauchmeldersystem/rauchmelder_stockwerk1.png';
import stockwerk2 from '../../../assets/images/modules/rauchmeldersystem/rauchmelder_stockwerk2.png';

const marks = [{
        value: 0,
        label: '0%',
    },
    {
        value: 20,
        label: '20%',
    },
    {
        value: 40,
        label: '40%',
    },
    {
        value: 60,
        label: '60%',
    },
    {
        value: 80,
        label: '80%',
    },
    {
        value: 100,
        label: '100%',
    },
];

function Fenstersteuerung() {

    const [stateBridge0, setStateBridge0] = useState([0, [{}, {}, {}, {}], "Showroom Dehoust (2.0.4)"]);
    const [stateBridge1, setStateBridge1] = useState([0, [{}, {}, {}, {}, {}], "ConSoft Räume 2.OG (2.2.3 / 2.2.4)"]);
    const [stateBridge2, setStateBridge2] = useState([0, [{}, {}, {}, {}], "ConSoft Räume 2.OG (2.2.1 / 3.2.2)"]);
    const [stateBridge3, setStateBridge3] = useState([0, [{}, {}, {}, {}, {}, {}, {}, {}, {}, {}], "ConSoft Räume 1.OG (2.1.4 / 2.1.5 / 2.1.6 / 2.1.8)"]);
    const [stateBridge4, setStateBridge4] = useState([0, [{}, {}, {}, {}, {}], "Foyer (3.0.2)"]);
    const [stateBridge5, setStateBridge5] = useState([0, [{}, {}, {}, {}], "Seminarraum 2 (1.0.2)"]);
    const [stateBridge6, setStateBridge6] = useState([0, [{}, {}, {}, {}], "Seminarraum 1 (1.0.1)"]);
    const [stateBridge7, setStateBridge7] = useState([0, [{}, {}, {}, {}, {}, {},{}, {}, {}], "Bewirtung (1.0.5 / 1.0.6)"]);
    const [stateBridge8, setStateBridge8] = useState([0, [{}, {}, {}], "Küche + Bewirtung (1.0.5 / 1.0.6)"]);
    const [stateBridge9, setStateBridge9] = useState([0, [{}, {}, {}, {}], "Seminarraum 3 (1.1.1)"]);
    const [stateBridge10, setStateBridge10] = useState([0, [{}, {}], "Büro Mitarbeiter Böhmke (1.1.4)"]);
    const [stateBridge11, setStateBridge11] = useState([0, [{}, {}], "Showroom TECE (3.1.2)"]);

    const [stateBridge12, setStateBridge12] = useState([0, [{}, {}], "ConSoft Büro 2.OG (2.2.6)"]);
    const [stateBridge13, setStateBridge13] = useState([0, [{}, {}, {}, {}, {}, {}], "VIP-Raum (1.1.5)"]);
    const [stateBridge14, setStateBridge14] = useState([0, [{},{}], "Büro Andre Böhmke (1.1.3)"]);
    const [stateBridge15, setStateBridge15] = useState([0, [{}, {}, {}], "Showroom Arbonia + Artiqua (2.0.5 / 2.0.6)"]);
    const [stateBridge16, setStateBridge16] = useState([0, [{}, {}], "Showroom TECE (2.0.3)"]);
    const [stateBridge17, setStateBridge17] = useState([0, [{}, {}], "Showroom Dehoust Hinten (2.0.4)"]);

    const [stateBridge18, setStateBridge18] = useState([0, [{}, {}, {}, {}], "Bewirtung Glasfront"]);
    const [stateBridge19, setStateBridge19] = useState([0, [{}], "Seminar 1.1"]);
    const [stateBridge20, setStateBridge20] = useState([0, [{}], "Bewirtung 1.0.6.7"]);

    const [stateDebugTableBridge0, setStateDebugTableBridge0] = useState();
    const [stateDebugTableBridge1, setStateDebugTableBridge1] = useState();

    const [stateIsDebug, setStateIsDebug] = useState('isNotDebug');

    function handleDebugChange() {
        setStateIsDebug(previous => previous === 'isNotDebug' ? 'isDebug' : 'isNotDebug')
      }

    let nodesOfBridge0 = ["2.0.4.1", "2.0.4.2", "2.0.4.3", "2.0.4.4"] // ["2.0.4.1", "2.0.4.2"]
    let nodesOfBridge1 = ["2.2.3.1", "2.2.3.2", "2.2.4.1", "2.2.4.2", "2.2.4.3"]
    let nodesOfBridge2 = ["2.2.1.1", "3.2.1.1", "3.2.1.2", "3.2.1.3"]
    let nodesOfBridge3 = ["2.1.4.1", "2.1.4.2", "2.1.5.1", "2.1.5.2", "2.1.5.3", "2.1.5.4", "2.1.5.5", "2.1.6.1", "2.1.6.2", "2.1.8.1"]
    let nodesOfBridge4 = ["3.0.2.1", "3.0.2.2", "3.0.2.3", "3.0.2.4", "3.0.2.5"]
    let nodesOfBridge5 = ["1.0.2.1", "1.0.2.2", "1.0.2.3", "1.0.2.4"]
    let nodesOfBridge6 = [ "1.0.1.1","1.0.1.2", "1.0.1.3", "1.0.1.4"]
    let nodesOfBridge7 = ["1.0.6.1","1.0.6.2", "1.0.6.3", "1.0.6.4", "1.0.6.5", "1.0.6.6", "1.0.6.7", "1.0.6.8", "1.0.5.1"] // ["1.0.6.1", "1.0.6.2", "1.0.6.3", "1.0.6.4", "1.0.6.5", "1.0.6.6"]
    let nodesOfBridge8 = ["x", "x", "x"]  // ["1.0.6.7", "1.0.6.8", "1.0.5.1"]
    let nodesOfBridge9 = ["1.1.1.1", "1.1.1.2", "1.1.1.3", "1.1.1.4"]
    let nodesOfBridge10 = ["1.1.4.1", "1.1.4.2"]
    let nodesOfBridge11 = ["3.1.2.1", "3.1.2.2"]

    let nodesOfBridge12 = ["2.2.6.1", "2.2.6.2", "2.2.5.1"]
    let nodesOfBridge13 = ["1.1.5.1", "1.1.5.2", "1.1.5.3", "1.1.5.4", "1.1.5.5", "1.1.5.6"]
    let nodesOfBridge14 = ["1.1.3.1"]
    let nodesOfBridge15 = ["2.0.5.1", "2.0.5.2", "2.0.6.1"]
    let nodesOfBridge16 = ["2.0.3.1", "2.0.3.2"]
    let nodesOfBridge17 = ["x", "x"] // ["2.0.4.3", "2.0.4.4"]

    let nodesOfBridge18 = ["x", "x", "x", "x"]
    let nodesOfBridge19 = ["x"]
    let nodesOfBridge20 = ["x"]


    let BridgeToBridgeRelation = [[0, 17],[7, 8, 18, 20], [6,19]]
    const bridgesNum = 17
    const totalBridgeNum = 21

    useEffect(() => {

        const mqtt = require('mqtt')
        const Broker = "mqtts://testmqtt.syrconnect.de:8001";
        const options = {
            protocol: 'mqtts',
            useSSL: "true",

            clientId: '1_EControl.2222_' + Math.floor(Math.random() * 1000),
            username: "testEControlTempClient",
            password: "SchneckenTempo22"
        };

        const Topic = "Test/EControl/EZG/painlessMesh/#";

        const client = mqtt.connect(Broker, options);

        function setUserPanelGroupEventListeners(id) {
            console.log(id)
            
            for(let x = 0; x < BridgeToBridgeRelation.length; x++){
                if ( id == BridgeToBridgeRelation[x][1]){
                    return
                }
            }

            for (let i = 0; i <= 10; i++) {
                var button = document.getElementById(id + '_sollwert' + i * 10);
                button.addEventListener('click', (event) => {
                    var val = i * 10;
                    var command = "1235:" + val + "," + val;
                    var topicToBB = "Test/EControl/EZG/painlessMesh/Bridge1" + id + "/to/broadcast"

                    for (let x = 0; x < BridgeToBridgeRelation.length; x ++){
                        if (id == BridgeToBridgeRelation[x][0]){
                            console.log("send to second Bridge as well")
                            console.log(BridgeToBridgeRelation[x])
                            BridgeToBridgeRelation[x].forEach(function(entry){
                                console.log(entry)
                                console.log("Publish to: Test/EControl/EZG/painlessMesh/Bridge1" + entry + "/to/broadcast "   + "Message: " + command);
                                client.publish("Test/EControl/EZG/painlessMesh/Bridge1" + entry + "/to/broadcast", command, {retain: true})
                            }) 
                            
                        }
                    }

                    console.log("Publish to: " + topicToBB + "Message: " + command);
                    // console.log("Publishing " + val + " to " + id);
                    client.publish(topicToBB, command, { retain: true });
                    toggleSendingAnimation(id);
                });
            }
        }

        function setAllPanelGroupEventListeners(id) {
            for (let i = 0; i <= 10; i++) {
                var button = document.getElementById(id + '_sollwert' + i * 10);
                button.addEventListener('click', (event) => {
                    var val = i * 10;
                    var command = "1235:" + val + "," + val;
                    //allLoadingField

                    for (var x = 0; x < totalBridgeNum; x++) {
                        var topicToBB = "Test/EControl/EZG/painlessMesh/Bridge1" + x + "/to/broadcast"
                        console.log("Publish to ALL: " + topicToBB + "Message: " + command);
                        client.publish(topicToBB, command, { retain: true });
                    }

                    toggleSendingAnimation(99);
                });
            }
        }

        function toggleSendingAnimation(id) {
            var btnwpString = id + "_btnWithProgress";
            var btnwpEC = document.getElementById(btnwpString);
            btnwpEC.classList.remove("hidden");
            btnwpEC.classList.add("active");
            setTimeout(() => {
                btnwpEC.classList.remove("active");
            }, 2000);
            setTimeout(() => {
                btnwpEC.classList.add("hidden");
            }, 4000);
        }

        // const publishMessageToAll = () =>{
        //     var command = "1235:"+ sliderValueAll + "," + sliderValueAll;
        //     var topicToBB = "Test/EControl/EZG/painlessMesh/Bridge1" + "0" + "/to/broadcast" ??
        //     for (var x = 0; x < bridgesNum; x++){
        //         var topicToBB = "Test/EControl/EZG/painlessMesh/Bridge1" + x + "/to/broadcast" 
        //         console.log("Publish to ALL: " + topicToBB + "Message: " + command);
        //        // mqttClient.publish(topicToBB, command, {retain: false});
        //     }
        // }

        function setAllEventListeners() {
            for (let i = 0; i <= bridgesNum; i++) {
                setUserPanelGroupEventListeners(i);
            }
        }
        setAllEventListeners();
        setAllPanelGroupEventListeners(99);

        client.on('connect', function() {

            console.log("connected");
            document.getElementById("WindowsOnline").classList.remove("hide");
            document.getElementById("WindowsOffline").classList.add("hide");
            client.subscribe(Topic, function(err) {;
                if (!err) {
                    console.log('SUBBED');
                }
            })
        })

        client.on('message', function(topic, message) {

            var msg = message.toString();
            console.log(message.toString())
            console.log(msg.indexOf(':'))

            if (msg.indexOf('{') >= 0) {
                var nodeId = msg.substring(msg.indexOf('[') + 1, msg.indexOf('{'));
            } 
            else if (msg.indexOf('#') < 10) {
                var nodeId = msg.substring(msg.indexOf('[') + 1, msg.indexOf('#'));
            }

            if (msg.indexOf(':') == 4) {
                console.log("possibly bridge sharing comand");
                return;
            } 
            else if (msg == "Ready!") {
                return;
            } 
            else {
                var values = msg.split('#');
                var values1 = values[1].split(':');
                var values2 = values[2].split(':');
          
                //#1:1:0:0:4:19.2:8:00.00.0000-00>00>00-0|+
                //#2:1:0:0:4:18.8:14:00.00.0000-00>00>00-0|]

                if (nodesOfBridge0.includes(nodeId)) {
                    var nodeList = stateBridge0;
                    var newState = [];
                    var sheetData = {};
                    sheetData.s1istAktiv = values1[1];                    
                    sheetData.s1ist = values1[2];
                    sheetData.s1soll = values1[3];
                    sheetData.s2istAktiv = values2[1];
                    sheetData.s2ist = values2[2];
                    sheetData.s2soll = values1[3];
                    sheetData.nodeId = nodeId;

                    nodeList[0] = values1[3]
                    nodeList[1][nodesOfBridge0.indexOf(nodeId)] = sheetData;
                   
                    newState[0] = nodeList[0] //s1soll
                    newState[1] = nodeList[1] //sheetdata
                    newState[2] = nodeList[2] // ??
                    setStateBridge0(newState);
                }
                if (nodesOfBridge1.includes(nodeId)) {
                    var nodeList = stateBridge1;
                    var sheetData = {};
                    sheetData.s1istAktiv = values1[1];
                    sheetData.s1ist = values1[2];
                    sheetData.s1soll = values1[3];
                    sheetData.s2istAktiv = values2[1];
                    sheetData.s2ist = values2[2];
                    sheetData.s2soll = values1[3];
                    sheetData.nodeId = nodeId;

                    nodeList[1][nodesOfBridge1.indexOf(nodeId)] = sheetData;
                    nodeList[0] = values1[3]
                    var newState = [];
                    newState[0] = nodeList[0]
                    newState[1] = nodeList[1]
                    newState[2] = nodeList[2]
                    setStateBridge1(newState);
                }
                if (nodesOfBridge2.includes(nodeId)) {
                    var nodeList = stateBridge2;
                    var sheetData = {};
                    sheetData.s1istAktiv = values1[1];
                    sheetData.s1ist = values1[2];
                    sheetData.s1soll = values1[3];
                    sheetData.s2istAktiv = values2[1];
                    sheetData.s2ist = values2[2];
                    sheetData.s2soll = values1[3];
                    sheetData.nodeId = nodeId;

                    nodeList[1][nodesOfBridge2.indexOf(nodeId)] = sheetData;
                    nodeList[0] = values1[3]
                    var newState = [];
                    newState[0] = nodeList[0]
                    newState[1] = nodeList[1]
                    newState[2] = nodeList[2]
                    setStateBridge2(newState);
                }
                if (nodesOfBridge3.includes(nodeId)) {
                    var nodeList = stateBridge3;
                    var sheetData = {};
                    sheetData.s1istAktiv = values1[1];
                    sheetData.s1ist = values1[2];
                    sheetData.s1soll = values1[3];
                    sheetData.s2istAktiv = values2[1];
                    sheetData.s2ist = values2[2];
                    sheetData.s2soll = values1[3];
                    sheetData.nodeId = nodeId;

                    nodeList[1][nodesOfBridge3.indexOf(nodeId)] = sheetData;
                    nodeList[0] = values1[3]
                    var newState = [];
                    newState[0] = nodeList[0]
                    newState[1] = nodeList[1]
                    newState[2] = nodeList[2]
                    setStateBridge3(newState);
                }
                if (nodesOfBridge4.includes(nodeId)) {
                    var nodeList = stateBridge4;
                    var sheetData = {};
                    sheetData.s1istAktiv = values1[1];
                    sheetData.s1ist = values1[2];
                    sheetData.s1soll = values1[3];
                    sheetData.s2istAktiv = values2[1];
                    sheetData.s2ist = values2[2];
                    sheetData.s2soll = values1[3];
                    sheetData.nodeId = nodeId;
                    
                    nodeList[1][nodesOfBridge4.indexOf(nodeId)] = sheetData;
                    nodeList[0] = values1[3]
                    var newState = [];
                    newState[0] = nodeList[0]
                    newState[1] = nodeList[1]
                    newState[2] = nodeList[2]
                    setStateBridge4(newState);
                }
                if (nodesOfBridge5.includes(nodeId)) {
                    var nodeList = stateBridge5;
                    var sheetData = {};
                    sheetData.s1istAktiv = values1[1];
                    sheetData.s1ist = values1[2];
                    sheetData.s1soll = values1[3];
                    sheetData.s2istAktiv = values2[1];
                    sheetData.s2ist = values2[2];
                    sheetData.s2soll = values1[3];
                    sheetData.nodeId = nodeId;
                    
                    nodeList[1][nodesOfBridge5.indexOf(nodeId)] = sheetData;
                    nodeList[0] = values1[3]
                    var newState = [];
                    newState[0] = nodeList[0]
                    newState[1] = nodeList[1]
                    newState[2] = nodeList[2]
                    setStateBridge5(newState);
                }
                if (nodesOfBridge6.includes(nodeId)) {
                    var nodeList = stateBridge6;
                    var sheetData = {};
                    sheetData.s1istAktiv = values1[1];
                    sheetData.s1ist = values1[2];
                    sheetData.s1soll = values1[3];
                    sheetData.s2istAktiv = values2[1];
                    sheetData.s2ist = values2[2];
                    sheetData.s2soll = values1[3];
                    sheetData.nodeId = nodeId;
                    
                    nodeList[1][nodesOfBridge6.indexOf(nodeId)] = sheetData;
                    nodeList[0] = values1[3]
                    var newState = [];
                    newState[0] = nodeList[0]
                    newState[1] = nodeList[1]
                    newState[2] = nodeList[2]
                    setStateBridge6(newState);
                }
                if (nodesOfBridge7.includes(nodeId)) {
                    var nodeList = stateBridge7;
                    var sheetData = {};
                    sheetData.s1istAktiv = values1[1];
                    sheetData.s1ist = values1[2];
                    sheetData.s1soll = values1[3];
                    sheetData.s2istAktiv = values2[1];
                    sheetData.s2ist = values2[2];
                    sheetData.s2soll = values1[3];
                    sheetData.nodeId = nodeId;
                    
                    nodeList[1][nodesOfBridge7.indexOf(nodeId)] = sheetData;
                    nodeList[0] = values1[3]
                    var newState = [];
                    newState[0] = nodeList[0]
                    newState[1] = nodeList[1]
                    newState[2] = nodeList[2]
                    setStateBridge7(newState);
                }
                if (nodesOfBridge8.includes(nodeId)) {
                    var nodeList = stateBridge8;
                    var sheetData = {};
                    sheetData.s1istAktiv = values1[1];
                    sheetData.s1ist = values1[2];
                    sheetData.s1soll = values1[3];
                    sheetData.s2istAktiv = values2[1];
                    sheetData.s2ist = values2[2];
                    sheetData.s2soll = values1[3];
                    sheetData.nodeId = nodeId;
                    
                    nodeList[1][nodesOfBridge8.indexOf(nodeId)] = sheetData;
                    nodeList[0] = values1[3]
                    var newState = [];
                    newState[0] = nodeList[0]
                    newState[1] = nodeList[1]
                    newState[2] = nodeList[2]
                    setStateBridge8(newState);
                }
                if (nodesOfBridge9.includes(nodeId)) {
                    var nodeList = stateBridge9;
                    var sheetData = {};
                    sheetData.s1istAktiv = values1[1];
                    sheetData.s1ist = values1[2];
                    sheetData.s1soll = values1[3];
                    sheetData.s2istAktiv = values2[1];
                    sheetData.s2ist = values2[2];
                    sheetData.s2soll = values1[3];
                    sheetData.nodeId = nodeId;
                    
                    nodeList[1][nodesOfBridge9.indexOf(nodeId)] = sheetData;
                    nodeList[0] = values1[3]
                    var newState = [];
                    newState[0] = nodeList[0]
                    newState[1] = nodeList[1]
                    newState[2] = nodeList[2]
                    setStateBridge9(newState);
                }
                if (nodesOfBridge10.includes(nodeId)) {
                    var nodeList = stateBridge10;
                    var sheetData = {};
                    sheetData.s1istAktiv = values1[1];
                    sheetData.s1ist = values1[2];
                    sheetData.s1soll = values1[3];
                    sheetData.s2istAktiv = values2[1];
                    sheetData.s2ist = values2[2];
                    sheetData.s2soll = values1[3];
                    sheetData.nodeId = nodeId;
                    
                    nodeList[1][nodesOfBridge10.indexOf(nodeId)] = sheetData;
                    nodeList[0] = values1[3]
                    var newState = [];
                    newState[0] = nodeList[0]
                    newState[1] = nodeList[1]
                    newState[2] = nodeList[2]
                    setStateBridge10(newState);
                }
                if (nodesOfBridge11.includes(nodeId)) {
                    var nodeList = stateBridge11;
                    var sheetData = {};
                    sheetData.s1istAktiv = values1[1];
                    sheetData.s1ist = values1[2];
                    sheetData.s1soll = values1[3];
                    sheetData.s2istAktiv = values2[1];
                    sheetData.s2ist = values2[2];
                    sheetData.s2soll = values1[3];
                    sheetData.nodeId = nodeId;
                    
                    nodeList[1][nodesOfBridge11.indexOf(nodeId)] = sheetData;
                    nodeList[0] = values1[3]
                    var newState = [];
                    newState[0] = nodeList[0]
                    newState[1] = nodeList[1]
                    newState[2] = nodeList[2]
                    setStateBridge11(newState);
                }
                if (nodesOfBridge12.includes(nodeId)) {
                    var nodeList = stateBridge12;
                    var sheetData = {};
                    sheetData.s1istAktiv = values1[1];
                    sheetData.s1ist = values1[2];
                    sheetData.s1soll = values1[3];
                    sheetData.s2istAktiv = values2[1];
                    sheetData.s2ist = values2[2];
                    sheetData.s2soll = values1[3];
                    sheetData.nodeId = nodeId;
                    
                    nodeList[1][nodesOfBridge12.indexOf(nodeId)] = sheetData;
                    nodeList[0] = values1[3]
                    var newState = [];
                    newState[0] = nodeList[0]
                    newState[1] = nodeList[1]
                    newState[2] = nodeList[2]
                    setStateBridge12(newState);
                }
                if (nodesOfBridge13.includes(nodeId)) {
                    var nodeList = stateBridge13;
                    var sheetData = {};
                    sheetData.s1istAktiv = values1[1];
                    sheetData.s1ist = values1[2];
                    sheetData.s1soll = values1[3];
                    sheetData.s2istAktiv = values2[1];
                    sheetData.s2ist = values2[2];
                    sheetData.s2soll = values1[3];
                    sheetData.nodeId = nodeId;
                    
                    nodeList[1][nodesOfBridge13.indexOf(nodeId)] = sheetData;
                    nodeList[0] = values1[3]
                    var newState = [];
                    newState[0] = nodeList[0]
                    newState[1] = nodeList[1]
                    newState[2] = nodeList[2]
                    setStateBridge13(newState);
                }
                if (nodesOfBridge14.includes(nodeId)) {
                    var nodeList = stateBridge14;
                    var sheetData = {};
                    sheetData.s1istAktiv = values1[1];
                    sheetData.s1ist = values1[2];
                    sheetData.s1soll = values1[3];
                    sheetData.s2istAktiv = values2[1];
                    sheetData.s2ist = values2[2];
                    sheetData.s2soll = values1[3];
                    sheetData.nodeId = nodeId;
                    
                    nodeList[1][nodesOfBridge14.indexOf(nodeId)] = sheetData;
                    nodeList[0] = values1[3]
                    var newState = [];
                    newState[0] = nodeList[0]
                    newState[1] = nodeList[1]
                    newState[2] = nodeList[2]
                    setStateBridge14(newState);
                }
                if (nodesOfBridge15.includes(nodeId)) {
                    var nodeList = stateBridge15;
                    var sheetData = {};
                    sheetData.s1istAktiv = values1[1];
                    sheetData.s1ist = values1[2];
                    sheetData.s1soll = values1[3];
                    sheetData.s2istAktiv = values2[1];
                    sheetData.s2ist = values2[2];
                    sheetData.s2soll = values1[3];
                    sheetData.nodeId = nodeId;
                    
                    nodeList[1][nodesOfBridge15.indexOf(nodeId)] = sheetData;
                    nodeList[0] = values1[3]
                    var newState = [];
                    newState[0] = nodeList[0]
                    newState[1] = nodeList[1]
                    newState[2] = nodeList[2]
                    setStateBridge15(newState);
                }
                if (nodesOfBridge16.includes(nodeId)) {
                    var nodeList = stateBridge16;
                    var sheetData = {};
                    sheetData.s1istAktiv = values1[1];
                    sheetData.s1ist = values1[2];
                    sheetData.s1soll = values1[3];
                    sheetData.s2istAktiv = values2[1];
                    sheetData.s2ist = values2[2];
                    sheetData.s2soll = values1[3];
                    sheetData.nodeId = nodeId;
                    
                    nodeList[1][nodesOfBridge16.indexOf(nodeId)] = sheetData;
                    nodeList[0] = values1[3]
                    var newState = [];
                    newState[0] = nodeList[0]
                    newState[1] = nodeList[1]
                    newState[2] = nodeList[2]
                    setStateBridge16(newState);
                }
                if (nodesOfBridge17.includes(nodeId)) {
                    var nodeList = stateBridge17;
                    var sheetData = {};
                    sheetData.s1istAktiv = values1[1];
                    sheetData.s1ist = values1[2];
                    sheetData.s1soll = values1[3];
                    sheetData.s2istAktiv = values2[1];
                    sheetData.s2ist = values2[2];
                    sheetData.s2soll = values1[3];
                    sheetData.nodeId = nodeId;
                    
                    nodeList[1][nodesOfBridge17.indexOf(nodeId)] = sheetData;
                    nodeList[0] = values1[3]
                    var newState = [];
                    newState[0] = nodeList[0]
                    newState[1] = nodeList[1]
                    newState[2] = nodeList[2]
                    setStateBridge17(newState);
                }
                if (nodesOfBridge18.includes(nodeId)) {
                    var nodeList = stateBridge18;
                    var sheetData = {};
                    sheetData.s1istAktiv = values1[1];
                    sheetData.s1ist = values1[2];
                    sheetData.s1soll = values1[3];
                    sheetData.s2istAktiv = values2[1];
                    sheetData.s2ist = values2[2];
                    sheetData.s2soll = values1[3];
                    sheetData.nodeId = nodeId;
                    
                    nodeList[1][nodesOfBridge18.indexOf(nodeId)] = sheetData;
                    nodeList[0] = values1[3]
                    var newState = [];
                    newState[0] = nodeList[0]
                    newState[1] = nodeList[1]
                    newState[2] = nodeList[2]
                    setStateBridge18(newState);
                }
                if (nodesOfBridge19.includes(nodeId)) {
                    var nodeList = stateBridge19;
                    var sheetData = {};
                    sheetData.s1istAktiv = values1[1];
                    sheetData.s1ist = values1[2];
                    sheetData.s1soll = values1[3];
                    sheetData.s2istAktiv = values2[1];
                    sheetData.s2ist = values2[2];
                    sheetData.s2soll = values1[3];
                    sheetData.nodeId = nodeId;
                    
                    nodeList[1][nodesOfBridge19.indexOf(nodeId)] = sheetData;
                    nodeList[0] = values1[3]
                    var newState = [];
                    newState[0] = nodeList[0]
                    newState[1] = nodeList[1]
                    newState[2] = nodeList[2]
                    setStateBridge19(newState);
                }
                if (nodesOfBridge20.includes(nodeId)) {
                    var nodeList = stateBridge20;
                    var sheetData = {};
                    sheetData.s1istAktiv = values1[1];
                    sheetData.s1ist = values1[2];
                    sheetData.s1soll = values1[3];
                    sheetData.s2istAktiv = values2[1];
                    sheetData.s2ist = values2[2];
                    sheetData.s2soll = values1[3];
                    sheetData.nodeId = nodeId;
                    
                    nodeList[1][nodesOfBridge20.indexOf(nodeId)] = sheetData;
                    nodeList[0] = values1[3]
                    var newState = [];
                    newState[0] = nodeList[0]
                    newState[1] = nodeList[1]
                    newState[2] = nodeList[2]
                    setStateBridge20(newState);
                }
               
            }
        })
    }, []);

    return ( <
        div className = "module-wrapper" >
            <div className = "distancer" > { /* // SUBNAVIGATION */ } 
                <div className = "subnav" >
                    <div className = "fltl" >
                        <Link to = "/Devices" >
                            <Button variant = "contained"
                                color = "primary"
                                className = "back-button"
                                startIcon = { < ArrowBackIosTwoToneIcon / > } > Geräte 
                            </Button> 
                        </Link>                        
                    </div> 
                    <div className = "fltr">
                        <div className='debugCheckboxWrapper'>
                            <input type="checkbox" onChange={handleDebugChange}/>DEBUG
                        </div>                                            
                    </div> 
                    <div className = "fltr" style = {{ display: 'none' }}>
                        <Button id = "WindowsOnline" variant = "outlined" color = "primary" className = "online fltr hide" disableElevation disabled startIcon = { < LinkIcon / > } > Verbunden </Button> 
                        <Button id = "WindowsOffline" variant = "outlined" color = "primary" className = "offline fltr" disableElevation disabled startIcon = { < LinkOffIcon / > } > Verbindung </Button> 
                    </div> 
                    <div className = "spacer"> </div>                      
                </div >
         { /* END SUBNAVIGATION*/ } 
        <div className = "outer-title distancer-bottom" >E - Control Fenster </div> 
        <Card>
            <Card.Header >
                <b> Broadcast an alle Fenster </b>
                <div id = "99_btnWithProgress" className = "btnWithProgressEC hidden" > 
                    <div className = "soft">
                        <a>
                            <span className = "btn-text"> OK! </span>
                        </a>
                    </div>
                </div>
            </Card.Header>
            <Card.Body style = {{ padding: 0 }}>
                <div className = "groupLightswitch" style = { { padding: 10 }}>
                    <div className = "cardLabel" >
                        <div id = "ecSollwertGroup" className = "card-title-buttons" >
                            <Button id = { "99_sollwert0" } variant = "contained" color = "" className = "generic-btn fltl ecbutton" > 0 % </Button> 
                            <Button id = { "99_sollwert10" } variant = "contained" color = "" className = "generic-btn fltl ecbutton" > 10 % </Button> 
                            <Button id = { "99_sollwert20" } variant = "contained" color = "" className = "generic-btn fltl ecbutton" > 20 % </Button> 
                            <Button id = { "99_sollwert30" } variant = "contained" color = "" className = "generic-btn fltl ecbutton" > 30 % </Button> 
                            <Button id = { "99_sollwert40" } variant = "contained" color = "" className = "generic-btn fltl ecbutton" > 40 % </Button> 
                            <Button id = { "99_sollwert50" } variant = "contained" color = "" className = "generic-btn fltl ecbutton" > 50 % </Button>                                        
                            <Button id = { "99_sollwert60" } variant = "contained" color = "" className = "generic-btn fltl ecbutton" > 60 % </Button> 
                            <Button id = { "99_sollwert70" } variant = "contained" color = "" className = "generic-btn fltl ecbutton" > 70 % </Button> 
                            <Button id = { "99_sollwert80" } variant = "contained" color = "" className = "generic-btn fltl ecbutton" > 80 % </Button> 
                            <Button id = { "99_sollwert90" } variant = "contained" color = "" className = "generic-btn fltl ecbutton" > 90 % </Button> 
                            <Button id = { "99_sollwert100" } variant = "contained" color = "" className = "generic-btn fltl ecbutton" > 100 % </Button>  
                        </div > 
                    </div>                        
                </div> 
            </Card.Body> 
        </Card> 
        <br/> 
        <br/>
        <Card className = "lageplan-card" >
            <Card.Body >
                <Tabs id = "uncontrolled-tab-example" defaultActiveKey = "eventEC_H1" >
                    <Tab eventKey = "eventEC_H1" title = "Haus 1" >
                        <div className = {stateIsDebug}>
                            <FensterStrCard room = { stateBridge6 } id = { "6" } isDebug = {stateIsDebug} /> 
                            <FensterStrCard room = { stateBridge5 } id = { "5" } isDebug = {stateIsDebug} />                                
                            <FensterStrCard room = { stateBridge7 } id = { "7" } isDebug = {stateIsDebug} />  
                            {/* <FensterStrCard room = { stateBridge8 }id = { "8" }/> */}
                            <FensterStrCard room = { stateBridge9 }id = { "9" } isDebug = {stateIsDebug} />  
                            <FensterStrCard room = { stateBridge14 }id = { "14" } isDebug = {stateIsDebug} /> 
                            <FensterStrCard room = { stateBridge10 }id = { "10" } isDebug = {stateIsDebug} /> 
                            <FensterStrCard room = { stateBridge13 }id = { "13" } isDebug = {stateIsDebug} />
                        </div>
                    </Tab> 
                    <Tab eventKey = "eventEC_H2" title = "Haus 2" >
                    <div className = {stateIsDebug}>
                            <FensterStrCard room = { stateBridge16 } id = { "16" } isDebug = {stateIsDebug} />  
                            <FensterStrCard room = { stateBridge0 } id = { "0" } isDebug = {stateIsDebug} /> 
                            <FensterStrCard room = { stateBridge15 } id = { "15" } isDebug = {stateIsDebug} />                             
                            <FensterStrCard room = { stateBridge3 } id = { "3" } isDebug = {stateIsDebug} /> 
                            <FensterStrCard room = { stateBridge1 } id = { "1" } isDebug = {stateIsDebug} /> 
                            <FensterStrCard room = { stateBridge12 } id = { "12" } isDebug = {stateIsDebug} /> 
                        </div>
                    </Tab> 
                    <Tab eventKey = "eventEC_H3" title = "Haus3" >
                    <div className = {stateIsDebug}>
                            <FensterStrCard room = { stateBridge4 } id = { "4" } isDebug = {stateIsDebug} /> 
                            <FensterStrCard room = { stateBridge11 }id = { "11" } isDebug = {stateIsDebug} />  
                            {/* <FensterStrCard room = { stateBridge17 }id = { "17" }/> */}
                            <FensterStrCard room = { stateBridge2 } id = { "2" } isDebug = {stateIsDebug} /> 
                        </div> 
                    </Tab> 
                </Tabs> 
            </Card.Body> 
        </Card> 
    </div> 
</div >
)}

export default Fenstersteuerung;