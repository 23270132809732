import React, {Component, useState, Fragment, useEffect} from 'react';
import Chart from 'react-apexcharts'
import Button from '@material-ui/core/Button';
import Card from 'react-bootstrap/Card';
import Table from 'react-bootstrap/Table';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';
import Icon from "../../Icons";

class WpFlowGraph extends Component {

    
    constructor(props) {
        super(props);

            this.todaysDate = '';
            this.todaysDateSimplified = '';
            this.currentlyDisplayedDate = '';
            this.currentlyDisplayedDateSimplified = '';
            // Color Values used by Chart
            this.colorValues = ["#000000", "#0D47A1", "#2196F3", "#BBDEFB", "#B71C1C", "#F44336", "#FE949F", "#194D33", "#388E3C", "#81C784", "#FF6F00", "#FFD54F"];

            this.state = {

            authToken: '',
            showConfig: 0,

            currentSensorID: 7,    
            currentSensorName: 'Sensor 7',
            tempNames: ['Durchfluss'],

            yAxisConfig: [],    
            lastFetchedDate: '',
            lastT1: '',
            lastT2: '',
            lastT3: '',
            lastT4: '',
            lastBat: '',
            lastRssi: '',
            lastTimestamp: '',

            // Default Options
            options: {
                colors: ['#eb4034', '#34b7eb',  '#44b034', '#44b034'],
                chart: {
                    id: 'temperaturverlauf',
                    zoom: {
                        type: 'xy',
                    },
                },
                tooltip: {
                    enabled: true, 
                    x: {
                        format: 'HH:mm'
                    }
                },
                stroke: {
                    width: 2
                }
                ,
                xaxis: {
                    type: 'datetime',  
                    
                    labels: {
                        format: 'HH:mm',
                      }
                },
                yaxis: [{
                    min: 0,
                    max: 50,
                    title: {
                        text: "Durchflussrate in l/min"                     
                    }
                }
                // },
                // {
                //     opposite: true,
                //     title: {
                //         text: "Durchflussrate in l/min"
                //     }
                // }
                ]
            },

            series: [],

            //Series End
        }        
    }

    // STATE EVENT HANDLERS
    onSensorConfigNameChange(value) {this.setState({currentSensorName: value});}
    onSensorConfigNameChange(value) {this.setState({currentSensorName: value});}
    //

    switchSensor(id){    
        // Changing state
        this.setState({currentSensorID: id})
        this.getSensorData(id);

        var BtnSensor6 = document.getElementById("BtnSensor6");
        var BtnSensor7 = document.getElementById("BtnSensor7");

        if (id === 6) {
            BtnSensor6.classList.add('green');
            BtnSensor7.classList.remove('green');
        }
        if (id === 7) {
            BtnSensor6.classList.remove('green');
            BtnSensor7.classList.add('green');
        }        
    }

    switchDayBack(){
        var newDateToFetch = this.currentlyDisplayedDate;
        newDateToFetch.setDate(newDateToFetch.getDate()-1);

        var dd = String(newDateToFetch.getDate()).padStart(2, '0');
        var mm = String(newDateToFetch.getMonth() + 1).padStart(2, '0'); //January is 0!
        var yy = newDateToFetch.getFullYear().toString().substr(-2); 
        var newDateToFetchSimplified = dd + "/" + mm + "/" + yy;

        this.currentlyDisplayedDate = newDateToFetch;
        this.currentlyDisplayedDateSimplified = newDateToFetchSimplified;    

        this.getSensorData(this.state.currentSensorID)

        if (newDateToFetch < this.state.todaysDate) {
            var BtnSwitchDayForward = document.getElementById("BtnSwitchDayForward");
            var BtnSwitchDayToday = document.getElementById("BtnSwitchDayToday");
            BtnSwitchDayForward.classList.remove('hide');
            BtnSwitchDayToday.classList.remove('hide');
        }
    }

    switchDayForward(){
        var newDateToFetch = this.currentlyDisplayedDate;
        newDateToFetch.setDate(newDateToFetch.getDate()+1);

        var dd = String(newDateToFetch.getDate()).padStart(2, '0');
        var mm = String(newDateToFetch.getMonth() + 1).padStart(2, '0'); //January is 0!
        var yy = newDateToFetch.getFullYear().toString().substr(-2); 
        var newDateToFetchSimplified = dd + "/" + mm + "/" + yy;

        this.currentlyDisplayedDate = newDateToFetch;
        this.currentlyDisplayedDateSimplified = newDateToFetchSimplified;    

        this.getSensorData(this.state.currentSensorID)

        //console.log("newDateToFetch" + newDateToFetch + " " + 'this.state.todaysDate' + this.state.todaysDate)
        if (newDateToFetch.toString() === this.state.todaysDate.toString()) {
            var BtnSwitchDayForward = document.getElementById("BtnSwitchDayForward");
            var BtnSwitchDayToday = document.getElementById("BtnSwitchDayToday");
            BtnSwitchDayForward.classList.add('hide');
            BtnSwitchDayToday.classList.add('hide');
        }
    }

    switchDayToday(){
        this.currentlyDisplayedDateSimplified = '';
        this.getSensorData(this.state.currentSensorID)

        var BtnSwitchDayForward = document.getElementById("BtnSwitchDayForward");
        var BtnSwitchDayToday = document.getElementById("BtnSwitchDayToday");
        BtnSwitchDayForward.classList.add('hide');
        BtnSwitchDayToday.classList.add('hide');
    }

    switchRefresh(){
        this.getSensorData(this.state.currentSensorID)
    }

    switchConfig(){
        if (this.state.showConfig === 1) {
            this.setState({showConfig: 0});            
            document.querySelector('#greyOverlay').style.display= 'none';
            document.querySelector('#configWindow').style.display= 'none';
        }
        else {
            this.setState({showConfig: 1});
            document.querySelector('#greyOverlay').style.display= 'block';  
            document.querySelector('#configWindow').style.display= 'block';        
        }
    }

    saveNewConfig(){

        var newConfigJson = {
            nodeId: this.state.currentSensorID,
            nodeName: document.querySelector('#sensorConfigName').value,
            sensor1Descriptor: document.querySelector('#sensorConfigDesc1').value,
            sensor2Descriptor: document.querySelector('#sensorConfigDesc2').value,
            sensor3Descriptor: document.querySelector('#sensorConfigDesc3').value,
            sensor4Descriptor: document.querySelector('#sensorConfigDesc4').value,
            sensor1Color:  parseInt(document.querySelector('#colorSelectorTemp1').value),
            sensor2Color:  parseInt(document.querySelector('#colorSelectorTemp2').value),
            sensor3Color:  parseInt(document.querySelector('#colorSelectorTemp3').value),
            sensor4Color:  parseInt(document.querySelector('#colorSelectorTemp4').value),
            axisYMin: parseInt(document.querySelector('#sensorConfigYMin').value),
            axisYMax:  parseInt(document.querySelector('#sensorConfigYMax').value),
        }

        console.log("POSTING: " + JSON.stringify(newConfigJson));

        const sendConfigTriTemp = {
            method: 'POST',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify(newConfigJson)
        }

        // POST TO API
        fetch('https://api.zvconnect.de/quadtemphyg/sensors/', sendConfigTriTemp)
        .then(response => response.json())
        .then(responseJson => console.log("HERE" + JSON.stringify(responseJson)))

        // SEND TO LOCAL CONFIG PARSER
        this.parseSensorUIConfig(newConfigJson);        
        // HIDE CONFIG WINDOW
        this.switchConfig()
        // REFRESH CHART
        //this.getSensorData(this.state.currentSensorID);

        setTimeout(function() { //Start the timer
            this.getSensorData(this.state.currentSensorID);           
        }.bind(this), 1000)
    }

    UNSAFE_componentWillMount() {
        this.getFlowData();
        this.setState({todaysDate: new Date()})
    }

    componentDidUpdate() {

    }

    getFlowData() {
        console.log("Getting Flow...")
        const requestOptionsFlowDat = {
            method: 'GET',
            headers: {'Content-Type': 'application/x-www-form-urlencoded'}                        
        }

        // FETCH 
        fetch('https://test.zvconnect.de/flow/getFlowData.php', requestOptionsFlowDat)
        .then(response => response.json())
        .then(responseJson => {this.setFlowData(responseJson);}) 
    }

    parseSensorUIConfig(responseJson){

        var configData = responseJson;

        console.log("Processing Config: " + JSON.stringify(configData));

        // Name of the Sensor. Only for Labels. Goes into State.
        this.setState({currentSensorName: configData.nodeName});
        // Labels for Temp 1-3. Used by 'splitDataPoints' when formatting the Data Series.
        this.setState({tempNames: [configData.sensor1Descriptor, configData.sensor2Descriptor, configData.sensor3Descriptor, configData.sensor4Descriptor]});
        document.querySelector('#sensorConfigDesc1').value = [configData.sensor1Descriptor];
        document.querySelector('#sensorConfigDesc2').value = [configData.sensor2Descriptor];   
        document.querySelector('#sensorConfigDesc3').value = [configData.sensor3Descriptor];
        document.querySelector('#sensorConfigDesc4').value = [configData.sensor4Descriptor];
        // Colors for the chart. Goes into chart config.
        var uiConfigColors = [this.colorValues[configData.sensor1Color], this.colorValues[configData.sensor2Color], this.colorValues[configData.sensor3Color], this.colorValues[configData.sensor4Color]];
        // Set Colors in Config Panel
        document.querySelector('#colorSelectorTemp1').value = [configData.sensor1Color];
        document.querySelector('#colorSelectorTemp2').value = [configData.sensor2Color];
        document.querySelector('#colorSelectorTemp3').value = [configData.sensor3Color];
        document.querySelector('#colorSelectorTemp4').value = [configData.sensor4Color];

        // Y-Axis Limits for the chart. Goes into chart config.
        var uiConfigYAxis = {min: configData.axisYMin, max: configData.axisYMax};
        //this.setState({yAxisConfig: [configData.axisYMin, configData.axisYMax]});
        document.querySelector('#sensorConfigYMin').value = [configData.axisYMin];
        document.querySelector('#sensorConfigYMax').value = [configData.axisYMax];
        

        var newUiConfig = {
            colors: ["#FF1654", "#247BA0"],
            chart: {
                id: 'Flow',
                zoom: {
                    type: 'xy',
                },
            },
            tooltip: {
                enabled: true, 
                x: {
                    format: 'HH:mm'
                }
            },              
            xaxis: {
                type: 'datetime'
            },
            yaxis: {
                min: 15,
                max: 50
            }
        }

        this.setState({options: newUiConfig});
    }

    setFlowData(responseJson) {
        console.log(responseJson);
        console.log("Flow Entries:" + responseJson.length);

       // var reverseJson = responseJson.reverse;

        // Create Split Arrays of Temps for Graph           
        var flowData = {name: this.state.tempNames[0], data: []};
        var totalData = {name: this.state.tempNames[2], data: []};   
        var powerData = {name: this.state.tempNames[1], data: []};   

        responseJson.forEach(element => {

            var converso = new Date(element.Timestamp);
            converso.setHours(converso.getHours() + 1);

            var timeDataPoint = converso;
            var flowDataPoint = element.Flow;
            //var totalDataPoint = element.Total;
            //var powerDataPoint = element.Power;

            flowData.data.push({x: timeDataPoint, y: parseFloat(flowDataPoint)})
            //totalData.data.push({x: timeDataPoint, y: parseFloat(totalDataPoint)})    
            //powerData.data.push({x: timeDataPoint, y: parseFloat(powerDataPoint)})    
        });

        this.setState({series: [flowData]})
    }

    render() {
    return (            
            <div>
                <div style={{display: "none", justifyContent: "right"}}>                
                    <Button id="BtnSwitchRefresh" variant="contained" color="primary" className="configButton" onClick={() => { this.switchRefresh() }}><Icon name="refresh"/></Button>
                </div>  
                <div className="tempChart-Wrapper">         
                    <Card className="tempChart-card">
                        <Card.Header>
                            <div className="card-title">Durchfluss: Letzten 250 Messungen</div>
                        </Card.Header> 
                        <Card.Body>                    
                            <Chart options={this.state.options} series={this.state.series} type="line" style={{height: '100%'}} height={400} />
                        </Card.Body>
                    </Card>
                </div>
            </div> 
        );
    }
}
export default WpFlowGraph;