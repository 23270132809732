import React, {Component, useState, Fragment, useEffect} from 'react';
import {Link} from 'react-router-dom';
import Card from 'react-bootstrap/Card';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import ArrowBackIosTwoToneIcon from '@material-ui/icons/ArrowBackIosTwoTone';
import Button from '@material-ui/core/Button';
import Slider from '@material-ui/core/Slider';
import LinkIcon from '@material-ui/icons/Link';
import LinkOffIcon from '@material-ui/icons/LinkOff';

function Volt() {
    
    const [valueSlider1, setValueSlider1] = useState([0]);
    const updateRange1=(e,data)=>{
        setValueSlider1(data)       
    }

    const [valueSlider2, setValueSlider2] = useState([0]);
    const updateRange2=(e,data)=>{
        setValueSlider2(data)
    }

    useEffect(()=>{
        // do stuff here...
        var mqtt = require('mqtt')
        var Broker = "mqtts://testmqtt.syrconnect.de:8001";
        var options = {
            protocol: 'mqtts',
            clientId: 'EZGWebsiban' + Math.floor(Math.random() * 1000)
        };

        var Topic1 = "Dehoust/DehoustConnect/0B0B0C0D0E0D/OUT/#";
        var Topic2 = "Dehoust/DehoustConnect/0B0B0C0D0E0D/IN/CH1";
        var Topic3 = "Dehoust/DehoustConnect/0B0B0C0D0E0D/IN/CH2";     

        var client  = mqtt.connect(Broker, options);

        client.on('connect', function () {
            var voltOfflineIndicator = document.getElementById("voltOffline");
            var voltOnlineIndicator = document.getElementById("voltOnline");            

            if (voltOnlineIndicator !=  null) {
                voltOfflineIndicator.classList.add('hide');   
                voltOnlineIndicator.classList.remove('hide'); 
            }         

            console.log('Raumtemperatur CONNECTED as ' + options.clientId);            
            client.subscribe(Topic1, function (err) {;
                if (!err) {
                console.log('SUBBED TOPIC 1');                             
                }
            })
            client.subscribe(Topic2, function (err) {;
                if (!err) {
                console.log('SUBBED TOPIC 2');                             
                }
            })  
            client.subscribe(Topic3, function (err) {;
                if (!err) {
                console.log('SUBBED TOPIC 3');                             
                }
            })    
        })

        client.on('message', function (topic, message) {
        // message is Buffer  
        console.log("onMessageArrived Message:" + message);
        console.log("onMessageArrived Topic:" + topic);

            if (topic.endsWith("CH1")) {
                setValueSlider1(parseInt(message) / 100)
            }
            if (topic.endsWith("CH2")) {
                setValueSlider2(parseInt(message) / 100)
            }
        })

        client.on('close', function () {            
            console.log("Raumtemperatur DISCONNECTED")        
        })

        const voltSubmit1 = document.getElementById('voltSubmit1');
        voltSubmit1.addEventListener('click', (event) => {
            if (event.target.click) {                
                setSollCH1();
               }
        });

        const voltSubmit2 = document.getElementById('voltSubmit2');
        voltSubmit2.addEventListener('click', (event) => {
            if (event.target.click) {                
                setSollCH2();
               }
        });       

        function setSollCH1() {
            var sli1 = document.getElementById('slider1');
            var daVal =  parseFloat(sli1.innerText) * 100;
            client.publish("Dehoust/DehoustConnect/0B0B0C0D0E0D/IN/CH1", daVal.toString());
        }
    
        function setSollCH2() {
            var sli2 = document.getElementById('slider2');
            var daVal =  parseFloat(sli2.innerText) * 100;
            client.publish("Dehoust/DehoustConnect/0B0B0C0D0E0D/IN/CH2", daVal.toString());
        }
    
        return () => client.end(); // <-- kill on unmount
    }, []) // <-- empty dependency array

    return (
        <div className="module-wrapper">
            <div className="distancer">
                {/* // SUBNAVIGATION */}
                <div className="subnav">
                    <div className="fltl">
                        <Link to="/Devices">
                            <Button variant="contained" color="primary" className="back-button" startIcon={<ArrowBackIosTwoToneIcon/>}>Geräte</Button>
                        </Link>
                    </div>
                    <div className="fltr">
                        <Button id="voltOnline" variant="outlined" color="primary" className="online fltr hide" disableElevation disabled startIcon={<LinkIcon/>}>Verbunden</Button>
                        <Button id="voltOffline" variant="outlined" color="primary" className="offline fltr" disableElevation disabled startIcon={<LinkOffIcon/>}>Verbindung</Button>
                    </div>
                    <div className="spacer"></div>                     
                </div>
                {/* END SUBNAVIGATION*/}  
                <div className="outer-title">
                    0-10 Volt Steuerung
                </div>
                <Row>
                    <Col md="12" lg="6">
                        <Card className="deviceCard">
                            <Card.Header>
                                <div className="card-title">Kanal 1</div>                              
                            </Card.Header>
                            <Card.Body>
                                <br></br>
                                <div>
                                    <Slider name="slider1" id="slider1" getarialabel="custom thumb label" min={0} max={10.0} step={0.1} defaultValue={0} valueLabelDisplay="on" value={valueSlider1} onChange={updateRange1}/>                                    
                                </div>         
                                <Button id="voltSubmit1" variant="contained" color="primary"  >Setze Kanal 1</Button>                                
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col md="12" lg="6">
                        <Card className="deviceCard">
                            <Card.Header>
                                <div className="card-title">Kanal 2</div>                            
                            </Card.Header>
                            <Card.Body>
                                <br></br>
                                <div>
                                    <Slider name="slider2" id="slider2" getarialabel="custom thumb label" min={0} max={10.0} step={0.1} defaultValue={0} valueLabelDisplay="on" value={valueSlider2} onChange={updateRange2}/>   
                                </div>
                                    <Button id="voltSubmit2" variant="contained" color="primary">Setze Kanal 2</Button>         
                            </Card.Body>                       
                        </Card>
                    </Col>              
                </Row> 
            </div>
        </div>
    )
}

export default Volt;