import React, {Component, useState, Fragment, useEffect} from 'react';
import Chart from 'react-apexcharts'
import Button from '@material-ui/core/Button';
import Card from 'react-bootstrap/Card';
import Table from 'react-bootstrap/Table';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';
import Icon from "../../Icons";

class HygboxlightGraph extends Component {
    
    constructor(props) {
        super(props);

        this.todaysDate = '';
        this.todaysDateSimplified = '';
        this.currentlyDisplayedDate = '';
        this.currentlyDisplayedDateSimplified = '';
        this.currentData = [];

        // Color Values used by Chart
        this.colorValues = ["#000000", "#0D47A1", "#2196F3", "#BBDEFB", "#B71C1C", "#F44336", "#FE949F", "#194D33", "#388E3C", "#81C784", "#FF6F00", "#FFD54F"];

        this.state = {

            authToken: '',
            showConfig: 0,

            currentSensorID: 1,    
            currentSensorName: 'Sensor 1',
            tempNames: ['HygboxLight','HygboxLight','HygboxLight'],

            yAxisConfig: [],

            // Default Options
            options: {
                colors: ['#34b7eb', '#eb4034', '#44b034', '#44b034'],
                chart: {
                    id: 'HygboxLight',
                    zoom: {
                        type: 'xy',
                    },
                },
                tooltip: {
                    enabled: true, 
                    x: {
                        format: 'HH:mm'
                    }
                },
                stroke: {
                    width: 2
                }
                ,
                xaxis: {
                    type: 'datetime',             
                   // categories: ['X1'],
                    labels: {
                        format: 'HH:mm',
                      }
                },
                yaxis: {
                    min: 0,
                    max: 65
                },
                annotations: {
                    xaxis: []
                }
            },

            seriesHygBoxLight: []

            //Series End
        } 
    }

    switchDayBack(){
        var newDateToFetch = this.currentlyDisplayedDate;
        newDateToFetch.setDate(newDateToFetch.getDate()-1);

        var dd = String(newDateToFetch.getDate()).padStart(2, '0');
        var mm = String(newDateToFetch.getMonth() + 1).padStart(2, '0'); //January is 0!
        var yy = newDateToFetch.getFullYear().toString().substr(-2); 
        var newDateToFetchSimplified = dd + "/" + mm + "/" + yy;

        this.currentlyDisplayedDate = newDateToFetch;
        this.currentlyDisplayedDateSimplified = newDateToFetchSimplified;    

        this.getSensorData(this.state.currentSensorID)

        if (newDateToFetch < this.state.todaysDate) {
            var BtnSwitchDayForward = document.getElementById("BtnSwitchDayForward");
            var BtnSwitchDayToday = document.getElementById("BtnSwitchDayToday");
            BtnSwitchDayForward.classList.remove('hide');
            BtnSwitchDayToday.classList.remove('hide');
        }
    }

    switchDayForward(){
        var newDateToFetch = this.currentlyDisplayedDate;
        newDateToFetch.setDate(newDateToFetch.getDate()+1);

        var dd = String(newDateToFetch.getDate()).padStart(2, '0');
        var mm = String(newDateToFetch.getMonth() + 1).padStart(2, '0'); //January is 0!
        var yy = newDateToFetch.getFullYear().toString().substr(-2); 
        var newDateToFetchSimplified = dd + "/" + mm + "/" + yy;

        this.currentlyDisplayedDate = newDateToFetch;
        this.currentlyDisplayedDateSimplified = newDateToFetchSimplified;    

        this.getSensorData(this.state.currentSensorID)

        //console.log("newDateToFetch" + newDateToFetch + " " + 'this.state.todaysDate' + this.state.todaysDate)
        if (newDateToFetch.toString() === this.state.todaysDate.toString()) {
            var BtnSwitchDayForward = document.getElementById("BtnSwitchDayForward");
            var BtnSwitchDayToday = document.getElementById("BtnSwitchDayToday");
            BtnSwitchDayForward.classList.add('hide');
            BtnSwitchDayToday.classList.add('hide');
        }
    }

    switchDayToday(){
        this.currentlyDisplayedDateSimplified = '';
        this.getSensorData(this.state.currentSensorID)

        var BtnSwitchDayForward = document.getElementById("BtnSwitchDayForward");
        var BtnSwitchDayToday = document.getElementById("BtnSwitchDayToday");
        BtnSwitchDayForward.classList.add('hide');
        BtnSwitchDayToday.classList.add('hide');
    }

    switchRefresh(){
        this.getSensorData(this.state.currentSensorID)
    }

    UNSAFE_componentWillMount() {
        this.getSensorData(this.state.currentSensorID);
        this.setState({todaysDate: new Date()})
    }

    componentDidUpdate() {

    }

    getSensorData(id){

        const requestOptionsTriTemp = {
            method: 'GET',
            headers: {'Content-Type': 'application/json'},
        }
        
        // GET SENSOR HISTORY DATA
        console.log("DateToFetchWith= " + currentDateToGet)
        var currentDateToGet = this.currentlyDisplayedDateSimplified;

        // IF NO DATE -> FETCH FOR TODAY
        if (currentDateToGet === '') {
            var today = new Date();
            var dd = String(today.getDate()).padStart(2, '0');
            var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
            var yy = today.getFullYear().toString().substr(-2);            
            currentDateToGet = dd + "/" + mm + "/" + yy;
            
            // Push to Global Variable
            this.currentlyDisplayedDate = today;
            this.currentlyDisplayedDateSimplified = currentDateToGet;
                       
        }

        // PUSH CURRENTLY USED DATE TO STATE
        this.setState({lastFetchedDate: currentDateToGet})        
        console.log("DateToFetchWith= " + currentDateToGet)

        fetch('https://api.zvconnect.de/hygboxlight/?devid=1&startdate='+ currentDateToGet +'&enddate='+ currentDateToGet +'&resolution=1', requestOptionsTriTemp)
        .then(response => response.json())
        .then(responseJson => {this.splitDataPointsForHygBoxLight(responseJson);
        })
    }

    updateSeries(){
        //this.setState({series: [...this.state.seriesSpuka1, ...this.state.seriesTemp72]})
    }

    splitDataPointsForHygBoxLight(responseJson) {
        console.log(responseJson);
        console.log("Entries:" + responseJson.length);

        this.setState({currentData:  responseJson})        

        // Create Split Arrays of Temps for Graph
        var temp1Data = {name: this.state.tempNames[0], data: []};     
        var hbl1AnnoXaxis = {xaxis: []};
        var hbl1AnnoXaxisLastValue = '';

        responseJson.forEach(element => {

            var timeDataPoint = element.created_at;
            var temp1DataPoint = element.temp / 10;
            var timeDataPointHBL = new Date(timeDataPoint).getTime()
            var hbl1AnnoXaxisColor = '#DB423A'; //2eb41c
            if (!(temp1DataPoint > 90) && !(temp1DataPoint < 0)) {
                temp1Data.data.push({ x: timeDataPoint, y: parseFloat(temp1DataPoint)});                
            }

            if (element.status.toString() !== hbl1AnnoXaxisLastValue) {
                if (element.status.toString() === '1'){
                    hbl1AnnoXaxisColor = '#2eb41c'; // Green
                }
                else {
                    hbl1AnnoXaxisColor = '#DB423A'; // Red
                }
                hbl1AnnoXaxis.xaxis.push({x: timeDataPointHBL, strokeDashArray: 0, borderColor: hbl1AnnoXaxisColor});
                hbl1AnnoXaxisLastValue = element.status.toString();
            }
        });

        var newOptions = {
            colors: ['#34b7eb', '#eb4034', '#44b034', '#44b034'],
            chart: {
                id: 'HygboxLight',
                zoom: {
                    type: 'xy',
                },
            },
            tooltip: {
                enabled: true, 
                x: {
                    format: 'HH:mm'
                }
            },
            stroke: {
                width: 3
            }
            ,
            xaxis: {
                type: 'datetime',             
               // categories: ['X1'],
                labels: {
                    format: 'HH:mm',
                  }
            },
            yaxis: {
                min: 0,
                max: 60
            },
            annotations: hbl1AnnoXaxis
        }

        this.setState({options:  newOptions})
        this.setState({seriesHygBoxLight: [temp1Data]})           
    }

    splitDataPointsForQuadTemp(responseJson) {
        console.log(responseJson);
        console.log("Entries:" + responseJson.length);

        // Set Table with last known Status
        // if (responseJson.length > 0) {
        //     var lastPosition = responseJson.length-1;
        //     this.setState({lastT2: (responseJson[lastPosition].temp2)/10})
        //     var isoDate = responseJson[lastPosition].time;
        //     var timestamp = isoDate.match(/\d\d:\d\d/);

        //     this.setState({lastTimestamp: timestamp})
        // }

        // else {
        //     this.setState({lastT2: ''})
        //     this.setState({lastTimestamp: ''})
        // }

        // Create Split Arrays of Temps for Graph
        var temp2Data = {name: this.state.tempNames[2], data: []};       

        responseJson.forEach(element => {
            var timeDataPoint = element.time;

            var temp2DataPoint = element.temp2 / 10;
            if (!(temp2DataPoint > 50) && !(temp2DataPoint < 0)) {
                temp2Data.data.push({ x: timeDataPoint, y: parseFloat(temp2DataPoint)});                
            }            
        });

        this.setState({seriesTemp72: [temp2Data]})
        this.updateSeries();     
    }
    
    render() {
        return (  
            <div className="tempChart-Wrapper">
                <br/><br/>
                <div>
                        <Button id="BtnSwitchDayBack" variant="contained" color="primary" className="fltr" onClick={() => { this.switchDayBack() }}>Tag Zurück</Button>
                        <Button id="BtnSwitchDayForward" variant="contained" color="primary" className="fltr hide" onClick={() => { this.switchDayForward() }}>Tag Vor</Button>
                        <Button id="BtnSwitchDayToday" variant="contained" color="primary" className="fltr hide" onClick={() => { this.switchDayToday() }}>Heute</Button>
                        {/* <Button id="BtnConfig" variant="contained" color="primary" className="fltr configButton" onClick={() => { this.switchConfig() }}><Icon name="settings"/></Button> */}
                        <Button id="BtnSwitchRefresh" variant="contained" color="primary" className="fltr configButton" onClick={() => { this.switchRefresh() }}><Icon name="refresh"/></Button>                    
                </div>                
                <Card className="tempChart-card">
                    <Card.Header>
                        <div className="card-title">Temperaturverlauf: {this.state.lastFetchedDate}</div>
                    </Card.Header> 
                    <Card.Body>                    
                        <Chart options={this.state.options} series={this.state.seriesHygBoxLight} type="line" style={{height: '100%'}} height={400} />
                    </Card.Body>
                </Card>
            </div>
        );
    }
}
export default HygboxlightGraph;