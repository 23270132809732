import React, {Component, useState, Fragment, useEffect} from 'react';
import {Link} from 'react-router-dom';
import Card from 'react-bootstrap/Card';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import ArrowBackIosTwoToneIcon from '@material-ui/icons/ArrowBackIosTwoTone';
import Button from '@material-ui/core/Button';
import LinkIcon from '@material-ui/icons/Link';
import LinkOffIcon from '@material-ui/icons/LinkOff';
import Tempgraph from './Tempgraph.js';

function Hygienespuelkasten() {
    useEffect(()=>{
        // do stuff here...
        var mqtt = require('mqtt')
        var Broker = "mqtts://testmqtt.syrconnect.de:8001";
        var options = {
            protocol: 'mqtts',
            clientId: 'TWTC' + Math.floor(Math.random() * 1000)
        };

        var MasterTopic = "Dehoust/HSK/ZVC/";

        var client  = mqtt.connect(Broker, options);

        client.on('connect', function () {

            var twOfflineIndicator = document.getElementById("hygienespuelkastenOffline");
            var twOnlineIndicator = document.getElementById("hygienespuelkastenOnline");            

            if (twOnlineIndicator !=  null) {
                twOfflineIndicator.classList.add('hide');   
                twOnlineIndicator.classList.remove('hide'); 
            }         

            console.log('Hygienespuelkasten CONNECTED as ' + options.clientId);            
            client.subscribe(MasterTopic + "IN/temp", function (err) {;
                if (!err) {
                console.log('SUBBED TOPIC: temp');                             
                }
            })
            client.subscribe(MasterTopic + "OUT/abs1state", function (err) {;
                if (!err) {
                console.log('SUBBED TOPIC: abs1state');                             
                }
            })
        })

        client.on('message', function (topic, message) {
        // message is Buffer  
            console.log("onMessageArrived Message:" + message);
            console.log("onMessageArrived Topic:" + topic);

            if (topic.endsWith('temp')) document.getElementById("card1temp").innerHTML = parseInt(message) / 10.0;        
            if (topic.endsWith('abs1state')) {abs1State(message);
            }
        })

        client.on('close', function () {            
            console.log("Hygienespuelkasten DISCONNECTED")        
        })

        function abs1State(msg) {
            var status = msg.toString();
            console.log("Status Abs1 Received:" + msg)

            // its closed
            if (status === "0") {
                document.getElementById("openAbs1Btn").classList.remove("btnGreyed");
                document.getElementById("closeAbs1Btn").classList.add("btnGreyed");
                document.getElementById("abs1Status").innerHTML = "geschlossen";
                document.getElementById("abs1Status").classList.remove("textGreen");
                document.getElementById("abs1Status").classList.add("textRed");
            } 

            //its open
            if (status === "1") {
                document.getElementById("openAbs1Btn").classList.add("btnGreyed");
                document.getElementById("closeAbs1Btn").classList.remove("btnGreyed");
                document.getElementById("abs1Status").innerHTML = "offen";
                document.getElementById("abs1Status").classList.remove("textRed");
                document.getElementById("abs1Status").classList.add("textGreen");
            } 
        
        }

        const ConstAbs1auf = document.getElementById('openAbs1Btn');
        ConstAbs1auf.addEventListener('click', (event) => {
            if (event.target.click) {                
                sendAbs1(1);
               }
        });

        const ConstAbs1zu = document.getElementById('closeAbs1Btn');
        ConstAbs1zu.addEventListener('click', (event) => {
            if (event.target.click) {                
                sendAbs1(0);
               }
        });

        const ConstAbs1trig = document.getElementById('triggerAbs1Btn');
        ConstAbs1trig.addEventListener('click', (event) => {
            if (event.target.click) {                
                sendAbs1(3);
               }
        });

        function sendAbs1(value) {
            console.log("Sending Abs1:" + value)
            client.publish("Dehoust/HSK/ZVC/IN/abs1state", value.toString());
        }
        
        return () => client.end(); // <-- kill on unmount
    }, []) // <-- empty dependency array

    return (
    <div className="module-wrapper">
        <div className="distancer">
            {/* // SUBNAVIGATION */}
            <div className="subnav">
                <div className="fltl">
                    <Link to="/Devices">
                        <Button variant="contained" color="primary" className="back-button" startIcon={<ArrowBackIosTwoToneIcon/>}>Geräte</Button>
                    </Link>
                </div>
                <div className="fltr">
                    <Button id="hygienespuelkastenOnline" variant="outlined" color="primary" className="online fltr hide" disableElevation disabled startIcon={<LinkIcon/>}>Verbunden</Button>
                    <Button id="hygienespuelkastenOffline" variant="outlined" color="primary" className="offline fltr" disableElevation disabled startIcon={<LinkOffIcon/>}>Verbindung</Button>
                </div>
                <div className="spacer"></div>                     
            </div>
            {/* END SUBNAVIGATION*/}  
            <div className="outer-title distancer-bottom">
                Spülkasten Einfach
            </div>             
            <Row>
                <Col md="12" lg="6">
                <Card className="deviceCard">
                        <Card.Header>
                            <div className="card-title">Temperatur</div>    
                            <div className="card-title-right"><span id="card1temp">0</span> °C</div>
                        </Card.Header>
                        <Card.Body className="grenzewerte-card-body">
                        <div className="groupLightswitch">
                            <div className="cardLabel">
                                <span className=""><b>Abspülung 1</b></span><br>
                                </br>
                                <span>Status: </span><span id="abs1Status"></span>
                            </div>
                            <div className="cardGroupSwitch">
                                <div className="card-title-buttons">
                                    <Button id="openAbs1Btn" variant="contained" className="generic-btn fltr">Öffnen</Button>
                                    <Button id="closeAbs1Btn" variant="contained" className="generic-btn fltr">Schließen</Button>
                                </div>
                                <br/><br/>  
                                <div className="card-title-buttons">
                                    <Button id="triggerAbs1Btn" variant="contained" className="generic-btn">Spülung auslösen</Button>    
                                </div>
                            </div>                            
                        </div>             
                        </Card.Body>
                    </Card>
                </Col>               
            </Row>
            <div>
                <Tempgraph/>
            </div>
        </div>        
    </div>
    )
}

export default Hygienespuelkasten;