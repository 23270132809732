import React, {Component, useState, Fragment, useEffect} from 'react';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';

const SmokeDataTableRow = (props) => {
    return (
        <TableRow>
            <TableCell >{props.GC}</TableCell >
            <TableCell >{props.ort}</TableCell >
            <TableCell>{props.mainVoltage}</TableCell>
            <TableCell >{props.batVoltage}</TableCell >
            <TableCell >{props.batCharge == false ? 'Nein' : 'Ja'}</TableCell >
            <TableCell >{props.batErr == false ? 'Nein' : 'Ja'}</TableCell >
            <TableCell className="hideXS">{props.Stats}</TableCell>
            <TableCell className="syncTimes" id={'syncfield'+ props.GC}>{props.timer}</TableCell>
            <TableCell >{props.status}</TableCell>
        </TableRow>
    );
};

export default SmokeDataTableRow;