import React, {Component, useState, setState, Fragment, useEffect} from 'react';
import {Link} from 'react-router-dom';
import Card from 'react-bootstrap/Card';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import TablePagination from  'react-bootstrap/Table';
import Table from 'react-bootstrap/Table';
import ECGlassRow from '../econtrol/ECGlassRow';
import ECGlassHistoryRow from './ECGlassHistoryRow';
import Chart from 'react-apexcharts'
import ArrowBackIosTwoToneIcon from '@material-ui/icons/ArrowBackIosTwoTone';
import Button from '@material-ui/core/Button';
import LinkIcon from '@material-ui/icons/Link';
import LinkOffIcon from '@material-ui/icons/LinkOff';
import Icon from "../Icons";
import jwt_decode from "jwt-decode";

import eclogo from '../../assets/ec_logo.png';

// MQTT Readme
// https://www.npmjs.com/package/mqtt

function Econtrol() {

    const [currentImageDate, setCurrentImageDate] = useState(['']);
    const [ecImgFileUrl, setEcImgFileUrl] = useState(['']);

    const [bank1Name, setBank1Name] = useState(['Schaltschrank A']);
    const [bank2Name, setBank2Name] = useState(['Schaltschrank B']);

    const [bank1State, setBank1State] = useState([]);
    const [bank2State, setBank2State] = useState([]);  

    const [currentSollwert, setCurrentSollwert] = useState(['']);
    const [currentMode, setCurrentMode] = useState(['']);

    const [currentEinstrahlung, setCurrentEinstrahlung] = useState(['']);
    const [currentModeString, setCurrentModeString] = useState(['']);  
  
    const [currentVizTimeMaster, setCurrentVizTimeMaster] = useState(['']);
    const [currentVizTimeSlave, setCurrentVizTimeSlave] = useState(['']);  
    const [currentUser, setCurrentUser] = useState([]);

    const [optionsMaster, setOptionsMaster] = useState(
        {
        colors: ['#34b7eb', '#eb4034', '#44b034', '#44b034'],
        chart: {type: 'bar'},
        tooltip: {enabled: true},
        dataLabels: {enabled: false},
        xaxis: {min: 1,max: 230, title: {text: 'Scheibe'}},
        yaxis: {min: 0,max: 100,title: {text: 'Prozent'}}
    })

    const [optionsSlave, setOptionsSlave] = useState(
        {
        colors: ['#34b7eb', '#eb4034', '#44b034', '#44b034'],
        chart: {type: 'bar'},
        tooltip: {enabled: true},
        dataLabels: {enabled: false},
        xaxis: {min: 1,max: 222, title: {text: 'Scheibe'}},
        yaxis: {min: 0,max: 100,title: {text: 'Prozent'}}
    })   

    const [seriesMaster, setSeriesMaster] = useState([]);
    const [seriesSlave, setSeriesSlave] = useState([]);
    
    useEffect(()=>{

        var userName = sessionStorage.getItem('username');

        // LIFE STARTS HERE

        if (userName !== null) {
            //var decodedJwt = jwt_decode(token.token);
            //var userName = decodedJwt.username;
            var isAdmin = false;
            
            if (userName !== null && userName !== undefined && userName !== '')
            {
                // Set Username in State
                setCurrentUser(userName)

                // TODO
                if (userName.toLowerCase() == "mohr"
                || userName.toLowerCase() == "aue"
                || userName.toLowerCase() == "piette"
                || userName.toLowerCase() == "gaertner"
                || userName.toLowerCase() == "schaefer"
                || userName.toLowerCase() == "saidi.schmidt@consoft.de"
                || userName.toLowerCase() == "pickl"
                || userName.toLowerCase() == "bostelmann"
                || userName.toLowerCase() == "schwanda"
                )            
                { 
                    isAdmin = true;
                }
            }

            if (isAdmin) {
                // var wienWebcam = document.getElementById("wienWebcam");        
                // if (wienWebcam !=  null) {
                //     wienWebcam.classList.remove('hide');                
                // }
                var wienSchaltschrankA = document.getElementById("wienSchaltschrankA");        
                if (wienSchaltschrankA !=  null) {
                    wienSchaltschrankA.classList.remove('hide');                
                }
                var wienSchaltschrankB = document.getElementById("wienSchaltschrankB");        
                if (wienSchaltschrankB !=  null) {
                    wienSchaltschrankB.classList.remove('hide');                
                }
                var wienControlSchaltschrankA = document.getElementById("wienControlSchaltschrankA");        
                if (wienControlSchaltschrankA !=  null) {
                    wienControlSchaltschrankA.classList.remove('hide');                
                }
                var wienControlSchaltschrankB = document.getElementById("wienControlSchaltschrankB");        
                if (wienControlSchaltschrankB !=  null) {
                    wienControlSchaltschrankB.classList.remove('hide');                
                }
                var btnECGallery = document.getElementById("btnECGallery"); 
                if (btnECGallery != null) {
                    btnECGallery.classList.remove('hide')
                }
            }
        }

        //getECIMG();

        // if (isAdmin) {
        //     getECIMG();
        // }

        function setECIMG(responseJson) {       
            const ecImgFilenameArray = responseJson.split(";");      
            var ecImgFileName = ecImgFilenameArray[0];
            var ecImgFileTimestamp = ecImgFilenameArray[1];
    
            var ecImgFileTimestampA = ecImgFileTimestamp.substring(0,10);
            var ecImgFileTimestampB = ecImgFileTimestamp.substring(10,13);
            ecImgFileTimestampB = (parseInt(ecImgFileTimestampB)) + 1;
    
            var ecImgFileTimestampC = ecImgFileTimestamp.substring(13,16);

            setCurrentImageDate(ecImgFileTimestampA + ' ' + ecImgFileTimestampB + ecImgFileTimestampC)
            setEcImgFileUrl(("https://firedice.de/" + ecImgFileName + ".jpg"));
        }
    
        function getECIMG() {
            const requestOptionsECImg = {
                method: 'GET',
                headers: {'Content-Type': 'application/x-www-form-urlencoded'}                        
            }
    
            // FETCH 
            fetch('https://firedice.de/stamp.php?count=1', requestOptionsECImg)
            .then(response => response.text())
            .then(responseText => {setECIMG(responseText);}) 
        }

        function parseForGraph(message, bankNumber) { 
        
            var incomingData = JSON.parse(message);
    
            // if (bankNumber === 0) {
            //     let start = 5;            
            //     let deleteCount = 0;
    
            //     incomingData.splice(start, deleteCount, '0');
            //     incomingData.splice(start, deleteCount, '0');
            // }
    
            // console.log("IncomingData Bank" + bankNumber + " Length " + incomingData.length)
    
            var visData = {name: "Wert" ,data:[]};
    
            for(var i = 0; i< incomingData.length; i++) {
                if (incomingData[i] !== undefined) {
                    visData.data.push({x: i+1, y: incomingData[i]})
                }
            }
            
            let currentDate = new Date();
            let cDay = currentDate.getDate()
            let cMonth = currentDate.getMonth() + 1
            let cYear = currentDate.getFullYear()
            let cHours = currentDate.getHours()
            let cMinutes = currentDate.getMinutes()
         
    
            var dayCheck = cDay.toString();
            var monthCheck = cMonth.toString();
            var hoursCheck = cHours.toString();
            var minCheck = cMinutes.toString();
           
            if (dayCheck.length === 1)
            {
                dayCheck = '0' + dayCheck;
            }       
    
            if (monthCheck.length === 1)
            {
                monthCheck = '0' + monthCheck;
            }  
    
            if (hoursCheck.length === 1)
            {
                hoursCheck = '0' + hoursCheck;
            }  
    
            if (minCheck.length === 1)
            {
                minCheck = '0' + minCheck;
            }
    
            var vizTime = dayCheck + "." + monthCheck + "." + cYear + " " +  hoursCheck + ":" +  minCheck;
    
            if (bankNumber === 0) {
                var scheibeWaitingSpan = document.getElementById("scheibeWaitingMaster");
                if (scheibeWaitingSpan !=  null) {
                    scheibeWaitingSpan.classList.add('hide');
                }
    
                setCurrentVizTimeMaster(vizTime);
                setSeriesMaster([visData]);
            }
    
            if (bankNumber === 1) {
                var scheibeWaitingSpan = document.getElementById("scheibeWaitingSlave");
                if (scheibeWaitingSpan !=  null) {
                    scheibeWaitingSpan.classList.add('hide');
                }

                setCurrentVizTimeSlave(vizTime);
                setSeriesSlave([visData]);
            }      
        }

        function setBtnColors(newSollwert) {
            var sollwert0btn = document.getElementById("sollwert0");
            if (sollwert0btn !== undefined && sollwert0btn !== null) {
                sollwert0btn.classList.remove('green');
            }
    
            var sollwert10btn = document.getElementById("sollwert10");
            if (sollwert10btn !== undefined && sollwert10btn !== null) {
                sollwert10btn.classList.remove('green');
            }
    
            var sollwert20btn = document.getElementById("sollwert20");
            if (sollwert20btn !== undefined && sollwert20btn !== null) {
                sollwert20btn.classList.remove('green');
            }
    
            var sollwert30btn = document.getElementById("sollwert30");
            if (sollwert30btn !== undefined && sollwert30btn !== null) {
                sollwert30btn.classList.remove('green');
            }
    
            var sollwert40btn = document.getElementById("sollwert40");
            if (sollwert40btn !== undefined && sollwert40btn !== null) {
                sollwert40btn.classList.remove('green');
            }
    
            var sollwert50btn = document.getElementById("sollwert50");
            if (sollwert50btn !== undefined && sollwert50btn !== null) {
                sollwert50btn.classList.remove('green');
            }
    
            var sollwert60btn = document.getElementById("sollwert60");
            if (sollwert60btn !== undefined && sollwert60btn !== null) {
                sollwert60btn.classList.remove('green');
            }
    
            var sollwert70btn = document.getElementById("sollwert70");
            if (sollwert70btn !== undefined && sollwert70btn !== null) {
                sollwert70btn.classList.remove('green');
            }
    
            var sollwert80btn = document.getElementById("sollwert80");
            if (sollwert80btn !== undefined && sollwert80btn !== null) {
                sollwert80btn.classList.remove('green');
            }
    
            var sollwert90btn = document.getElementById("sollwert90");
            if (sollwert90btn !== undefined && sollwert90btn !== null) {
                sollwert90btn.classList.remove('green');
            }
    
            var sollwert100btn = document.getElementById("sollwert100");
            if (sollwert100btn !== undefined && sollwert100btn !== null) {
                sollwert100btn.classList.remove('green');
            }
    
            if (newSollwert !== 9999) {
                var setToGreen = document.getElementById("sollwert" + newSollwert);
                if (setToGreen !== undefined) {
                    setToGreen.classList.add('green');
                }
                else {
                    console.log("Error setting button colors for new value: " + newSollwert)
                }
            }
        }

        function setModeColors (mode){
            var wienSollwertGroup = document.getElementById("wienSollwertGroup");
            var btnSitzungmode = document.getElementById("btnSitzungmode");        
            var btnAutomode = document.getElementById("btnAutomode");
    
            btnAutomode.classList.remove('green');
            btnSitzungmode.classList.remove('green'); 
            
            if (mode === 1) {
                btnAutomode.classList.add('green');            
                wienSollwertGroup.classList.add('disabled');     
                setBtnColors(9999);       
            }
            else if (mode === 2) {
                btnSitzungmode.classList.add('green');
                wienSollwertGroup.classList.remove('disabled');
            }
        }

        const mqtt = require('mqtt')
        const Broker = "mqtts://testmqtt.syrconnect.de:8001";
        const options = {
            username : "testEControlTempClient",
            password : "SchneckenTempo22",
            protocol: 'mqtts',
            clientId: '1_Test.EControl.Temp.Ecki_' + Math.floor(Math.random() * 1000)
        };
        const MasterTopic = "Test/EControl/65c0c3fc-7835-11ec-90d6-0242ac120003/";
        
        const client = mqtt.connect(Broker, options);
            
        client.on('connect', function () {
            var voltOfflineIndicator = document.getElementById("ECGOffline");
            var voltOnlineIndicator = document.getElementById("ECGOnline");            
        
            if (voltOnlineIndicator !=  null) {
                voltOfflineIndicator.classList.add('hide');   
                voltOnlineIndicator.classList.remove('hide'); 
            }                   

            //TODO
            if (isAdmin) {                
                console.log('ECONTROLWIEN CONNECTED');   
            }            
        
            client.subscribe(MasterTopic, function (err) {;
                if (!err) {
                console.log('Subscribed to MasterTopic');                                       
                }
            })

			client.subscribe(MasterTopic+"OUT/#", 1);
			client.subscribe(MasterTopic+"command",1);
            client.subscribe(MasterTopic+"status",1);
        })

        client.on('message', function (topic, message) {

            if (topic.toString().endsWith('bank0')) {
                if (isAdmin) {
                    //console.log("Bank0:" + message.toString());                
                    parseForGraph(message, 0);                    
                }
            }

            if (topic.toString().endsWith('bank1')) {                
                var newMsg = JSON.parse(message);
                if (newMsg.ActiveCount !== null && newMsg.ActiveCount !== undefined)
                {
                    //console.log("Bank1:" + message.toString());
                    setBank1State([newMsg]);                    
                }
                else {
                    if (isAdmin) {
                        //console.log("Bank1:" + message.toString());
                        parseForGraph(message, 1);
                    }
                }
            }

            if (topic.toString().endsWith('bank2')) {                
                var newMsg = JSON.parse(message);                
                if (newMsg.ActiveCount !== null && newMsg.ActiveCount !== undefined)
                {
                    //console.log("Bank2:" + message.toString());
                    setBank2State([newMsg]);                    
                }
            }

            if (topic.toString().endsWith('status')) {

                if (message.length !== 0) {
                    var commandParsed = JSON.parse(message);

                    if (commandParsed.hasOwnProperty('sollwert')) {
                        var newSW = commandParsed.sollwert;
                        console.log("Sollwert: " + newSW);
                        setCurrentSollwert([newSW]);
                        setBtnColors(newSW);
                    }
                    
                    if (commandParsed.hasOwnProperty('modus')) {
                        var newMode = commandParsed.modus;
                        console.log("Mode: " + newMode);
                        setCurrentMode([newMode]);

                        if (newMode === 1) {
                            setCurrentModeString('Automatik')
                        }
                        else if (newMode === 2) {
                            setCurrentModeString('Sitzungsmodus')
                        }

                        setModeColors(newMode);
                    }

                    if (commandParsed.hasOwnProperty('einstrahlung')) {
                        var newEinstr = commandParsed.einstrahlung;
                        console.log("Einstrahlung: " + newEinstr);
                        setCurrentEinstrahlung([newEinstr]);                        
                    }
                }
            }        
        })

        client.on('close', function () {            
            console.log("EControlWien DISCONNECTED")        
        })

        //Sollwert 0
        const sollwert0Button = document.getElementById('sollwert0');
        sollwert0Button.addEventListener('click', (event) => {     
            var val = 0;
            var user = sessionStorage.getItem('username');
            var data = {"sollwert":val, "username":user};

            client.publish(MasterTopic+"command", JSON.stringify(data), {retain: false});           
            console.log(JSON.stringify(data))
            
        });

        //Sollwert 10
        const sollwert10Button = document.getElementById('sollwert10');
        sollwert10Button.addEventListener('click', (event) => {     
            var val = 10;
            var user = sessionStorage.getItem('username');
            var data = {"sollwert":val, "username":user};

            client.publish(MasterTopic+"command", JSON.stringify(data), {retain: false});           
            console.log(JSON.stringify(data))            
        });

        //Sollwert 20
        const sollwert20Button = document.getElementById('sollwert20');
        sollwert20Button.addEventListener('click', (event) => {    
            var val = 20;
            var user = sessionStorage.getItem('username');
            var data = {"sollwert":val, "username":user};
            

                client.publish(MasterTopic+"command", JSON.stringify(data), {retain: false});           
                console.log(JSON.stringify(data))

        });

        //Sollwert 30
        const sollwert30Button = document.getElementById('sollwert30');
        sollwert30Button.addEventListener('click', (event) => {     
            var val = 30;
            var user = sessionStorage.getItem('username');
            var data = {"sollwert":val, "username":user};
            

                client.publish(MasterTopic+"command", JSON.stringify(data), {retain: false});           
                console.log(JSON.stringify(data))

        });        

       //Sollwert 40
        const sollwert40Button = document.getElementById('sollwert40');
        sollwert40Button.addEventListener('click', (event) => {      
            var val = 40;
            var user = sessionStorage.getItem('username');
            var data = {"sollwert":val, "username":user};
            

                client.publish(MasterTopic+"command", JSON.stringify(data), {retain: false});           
                console.log(JSON.stringify(data))

        });

        //Sollwert 50
        const sollwert50Button = document.getElementById('sollwert50');
        sollwert50Button.addEventListener('click', (event) => {     
            var val = 50;
            var user = sessionStorage.getItem('username');
            var data = {"sollwert":val, "username":user};
            

                client.publish(MasterTopic+"command", JSON.stringify(data), {retain: false});           
                console.log(JSON.stringify(data))

        });         

        //Sollwert 60
        const sollwert60Button = document.getElementById('sollwert60');
        sollwert60Button.addEventListener('click', (event) => {        
            var val = 60;
            var user = sessionStorage.getItem('username');
            var data = {"sollwert":val, "username":user};
            

                client.publish(MasterTopic+"command", JSON.stringify(data), {retain: false});           
                console.log(JSON.stringify(data))

        });

        //Sollwert 70
        const sollwert70Button = document.getElementById('sollwert70');
        sollwert70Button.addEventListener('click', (event) => {     
            var val = 70;
            var user = sessionStorage.getItem('username');
            var data = {"sollwert":val, "username":user};
            

                client.publish(MasterTopic+"command", JSON.stringify(data), {retain: false});           
                console.log(JSON.stringify(data))

        });  

        //Sollwert 80
        const sollwert80Button = document.getElementById('sollwert80');
        sollwert80Button.addEventListener('click', (event) => { 
            var val = 80;
            var user = sessionStorage.getItem('username');
            var data = {"sollwert":val, "username":user};
            

                client.publish(MasterTopic+"command", JSON.stringify(data), {retain: false});           
                console.log(JSON.stringify(data))

        });

        //Sollwert 90
        const sollwert90Button = document.getElementById('sollwert90');
        sollwert90Button.addEventListener('click', (event) => {     
            var val = 90;
            var user = sessionStorage.getItem('username');
            var data = {"sollwert":val, "username":user};
            

                client.publish(MasterTopic+"command", JSON.stringify(data), {retain: false});           
                console.log(JSON.stringify(data))

        });        

        //Sollwert 100
        const sollwert100Button = document.getElementById('sollwert100');
        sollwert100Button.addEventListener('click', (event) => {
            var val = 100;
            var user = sessionStorage.getItem('username');
            var data = {"sollwert":val, "username":user};

                client.publish(MasterTopic+"command", JSON.stringify(data), {retain: false});           
                console.log(JSON.stringify(data))

        });  

        // Automodus
        const automodeButton = document.getElementById('btnAutomode');
        automodeButton.addEventListener('click', (event) => {
            var val = 1;
            var user = sessionStorage.getItem('username');
            var data = {"modus":val, "username":user};
            client.publish(MasterTopic+"command", JSON.stringify(data), {retain: false});           
            console.log(JSON.stringify(data))    
        });     
        
        // Sitzungsmodus
        const sitzungsmodeButton = document.getElementById('btnSitzungmode');
        sitzungsmodeButton.addEventListener('click', (event) => {
            var val = 2;
            var user = sessionStorage.getItem('username');
            var data = {"modus":val, "username":user};
            client.publish(MasterTopic+"command", JSON.stringify(data), {retain: false});           
            console.log(JSON.stringify(data))    
        }); 

        // Clear Retained
        const clearRetainedButton = document.getElementById('btnClearretained');
        clearRetainedButton.addEventListener('click', (event) => {
            client.publish(MasterTopic+"command", null ,{retain: false});           
            console.log('Clearing Retained')    
        });        


        //SetScheibeEinzeln A
        const setScheibeEinzelnABtn = document.getElementById('btnSetSingleA');
        setScheibeEinzelnABtn.addEventListener('click', (event) => {

            var bank = 0;
            var scheibeANumber =  document.getElementById('inputScheibeSetSingleA').value;
            var scheibeASollwert = document.getElementById('inputValueSetSingleA').value;            

            if (scheibeANumber !== undefined && scheibeANumber !== null && scheibeASollwert !== undefined && scheibeASollwert !== null) {
            var data={"isOffBank":true, "atBank":bank , "atAddr":scheibeANumber, "OffBankSoll":scheibeASollwert};
            client.publish(MasterTopic+"command", JSON.stringify(data), {retain: false});

            console.log('Sending::: Bank:' + bank + ", Scheibe:" + scheibeANumber + ", Value:" + scheibeASollwert)        
            }
            else {
                console.log('Error: Invalid or missing parameters for setting single window');      
            }
        });         
        
        //SetScheibeEinzeln B
        const setScheibeEinzelnBBtn = document.getElementById('btnSetSingleB');
        setScheibeEinzelnBBtn.addEventListener('click', (event) => {

            var bank = 1;
            var scheibeBNumber =  document.getElementById('inputScheibeSetSingleB').value;
            var scheibeBSollwert = document.getElementById('inputValueSetSingleB').value;
            
            if (scheibeBNumber !== undefined && scheibeBNumber !== null && scheibeBSollwert !== undefined && scheibeBSollwert !== null) {
                var data={"isOffBank":true, "atBank":bank , "atAddr":scheibeBNumber, "OffBankSoll":scheibeBSollwert};
                client.publish(MasterTopic+"command", JSON.stringify(data), {retain: false});
                console.log('Sending::: Bank:' + bank + ", Scheibe:" + scheibeBNumber + ", Value:" + scheibeBSollwert)      
            }
            else {
                console.log('Error: Invalid or missing parameters for setting single window');      
            }              
        });

        //ResetScheibeEinzeln A
        const resetScheibeEinzelnABtn = document.getElementById('btnResetSingleA');
        resetScheibeEinzelnABtn.addEventListener('click', (event) => {

            var bank = 0;
            var scheibeANumber =  document.getElementById('inputScheibeSetSingleA').value;

            if (scheibeANumber !== undefined && scheibeANumber !== null) {
                var data={"isOffBank":false, "atBank":bank , "atAddr":scheibeANumber};
                client.publish(MasterTopic+"command", JSON.stringify(data), {retain: false});
    
                console.log('Sending Reset::: Bank:' + bank + ", Scheibe:" + scheibeANumber)  
            }
            else {
                console.log('Error: Invalid or missing parameters for resetting single window');      
            } 
        });  

        //ResetScheibeEinzeln B
        const resetScheibeEinzelnBBtn = document.getElementById('btnResetSingleB');
        resetScheibeEinzelnBBtn.addEventListener('click', (event) => {

            var bank = 1;
            var scheibeBNumber =  document.getElementById('inputScheibeSetSingleB').value;

            if (scheibeBNumber !== undefined && scheibeBNumber !== null) {
                var data={"isOffBank":false, "atBank":bank , "atAddr":scheibeBNumber};
                client.publish(MasterTopic+"command", JSON.stringify(data), {retain: false});
    
                console.log('Sending Reset::: Bank:' + bank + ", Scheibe:" + scheibeBNumber)  
            }
            else {
                console.log('Error: Invalid or missing parameters for resetting single window');      
            } 
        });

        return () => client.end(); // <-- kill on unmount
    }, []) // <-- empty dependency array

    return (
    <div className="module-wrapper">
            <div className="distancer"></div>
            {/* // SUBNAVIGATION */}
            <div className="subnav">
                <div className="fltl">
                    <Link to="/">
                        <Button variant="contained" color="primary" className="back-button"  startIcon={<ArrowBackIosTwoToneIcon/>}>Projekte</Button>
                    </Link>
                </div>
                <div className="fltr">   
                    <Button id="ECGOnline" variant="outlined" color="primary" className="online fltr hide" disableElevation disabled startIcon={<LinkIcon/>}>Verbunden</Button>
                    <Button id="ECGOffline" variant="outlined" color="primary" className="offline fltr" disableElevation disabled startIcon={<LinkOffIcon/>}>Verbindung</Button>
                </div>
                <div className="spacer"></div>                     
            </div>                
            {/* END SUBNAVIGATION*/}  
            <div className="outer-title kuppelControlHeader">
                <img className="" src={eclogo} alt="Logo" style={{height: "50px", margin: "0px 0px 5px 0px"}}/> Kuppel Parlament Wien
                <div className='linkButtons'>
                    <Link to="ECGallery">
                        <Button id="btnECGallery" variant="contained" color="primary" className="generic-btn fltr ecbutton hide" style={{marginRight: "10px", float: "right"}}>Bildergallerie</Button>   
                    </Link>
                    <Link to="ECHistory" target="_blank">
                        <Button variant="contained" color="primary" className="generic-btn fltr ecbutton" style={{marginRight: "10px", float: "right"}}>Historie</Button>   
                    </Link> 
                    <a href='https://webtv.feratel.com/webtv/?cam=5970&design=v3&c0=0&c2=1&lg=de&s=0' target='_blank'>
                        <Button variant="contained" color="primary" className="generic-btn fltr ecbutton" style={{marginRight: "10px", float: "right"}}>Livecam Wien</Button>   
                    </a>
                </div>
            </div>
            <div style={{clear: 'both'}}></div>              
            <Row>
                <Col>
                    <Card className="deviceCard">
                        <Card.Header>
                            <div style={{float: 'left'}}>
                                <span style={{}}><span style={{display: 'inline-block', width: '165px'}}>Aktueller Sollwert: </span>{currentSollwert}%</span><br></br>
                                <span style={{}}><span style={{display: 'inline-block', width: '165px'}}>Aktueller Modus: </span>{currentModeString}</span><br></br>
                                <span style={{}}><span style={{display: 'inline-block', width: '165px'}}>Aktuelle Einstrahlung: </span>{currentEinstrahlung} Watt/m²</span>
                            </div>     
                            <Button id="btnSitzungmode" variant="contained" color="primary" style={{float: 'right'}} className="generic-btn ecbutton">Sitzungsmodus</Button>                     
                            <Button id="btnAutomode" variant="contained" color="primary" style={{float: 'right', marginRight: 10}} className="generic-btn ecbutton">Automatik</Button>
                            <Button id="btnClearretained" variant="contained" color="" style={{float: 'right', display: 'none', marginRight: 10}} className="generic-btn ecbutton">Clear Retained</Button>                               
                        </Card.Header>
                        <Card.Body>
                            <div className="groupLightswitch">
                                <div className="cardLabel">
                                    <div id="wienSollwertGroup"  className="card-title-buttons">
                                        <Button id="sollwert0" variant="contained" color="" className="generic-btn fltl ecbutton">0%</Button>
                                        <Button id="sollwert10" variant="contained" color="" className="generic-btn fltl ecbutton hide">10%</Button>
                                        <Button id="sollwert20" variant="contained" color="" className="generic-btn fltl ecbutton">20%</Button>
                                        <Button id="sollwert30" variant="contained" color="" className="generic-btn fltl ecbutton">30%</Button>
                                        <Button id="sollwert40" variant="contained" color="" className="generic-btn fltl ecbutton">40%</Button>
                                        <Button id="sollwert50" variant="contained" color="" className="generic-btn fltl ecbutton">50%</Button>                                       
                                        <Button id="sollwert60" variant="contained" color="" className="generic-btn fltl ecbutton">60%</Button>
                                        <Button id="sollwert70" variant="contained" color="" className="generic-btn fltl ecbutton">70%</Button>
                                        <Button id="sollwert80" variant="contained" color="" className="generic-btn fltl ecbutton">80%</Button>
                                        <Button id="sollwert90" variant="contained" color="" className="generic-btn fltl ecbutton">90%</Button>
                                        <Button id="sollwert100" variant="contained" color="" className="generic-btn fltl ecbutton">100%</Button> 
                                    </div>  
                                </div>
                                <div className="cardGroupSwitch"></div>
                            </div>                                       
                        </Card.Body>
                    </Card>
                    <Card className="deviceCard">
                        <Card.Header>
                            Live-Status
                        </Card.Header>
                        <Card.Body>
                            <Table striped bordered hover size="sm" aria-sort="descending">
                            <thead>
                                <tr>
                                    <th style={{width: 180}}></th>
                                    <th>Scheiben<br/>Aktiv</th>
                                    <th>Scheiben<br/>Hinweis</th>
                                    <th>Scheiben<br/>Ruhezustand</th>
                                    <th>Scheiben<br/>Initialisierend</th>
                                    <th>Scheiben<br/>Regelnd</th>
                                    <th>Scheiben<br/>Wartend</th>
                                    <th>∅ Ist-Wert</th>                                                
                                    <th>Temperatur<br/>Min.</th>
                                    <th>Temperatur<br/>Max.</th>
                                    <th style={{width: 150}}>Uhrzeit</th>                                                 
                                </tr>
                            </thead>
                            <tbody id="ECGTableBody">
                                {bank1State.map(dataSet =>  (
                                    <ECGlassRow Name={bank1Name} AC={dataSet.ActiveCount} Error={dataSet.Error} Idle={dataSet.Idle} Init={dataSet.Init} Operating={dataSet.Operating} Waiting={dataSet.Waiting} Percent={dataSet.Percent} TempMin={dataSet.TempMin} TempMax={dataSet.TempMax} Time={dataSet.Time}/>
                                ))}
                                {bank2State.map(dataSet =>  (
                                    <ECGlassRow Name={bank2Name} AC={dataSet.ActiveCount} Error={dataSet.Error} Idle={dataSet.Idle} Init={dataSet.Init} Operating={dataSet.Operating} Waiting={dataSet.Waiting} Percent={dataSet.Percent} TempMin={dataSet.TempMin} TempMax={dataSet.TempMax} Time={dataSet.Time}/>
                                ))}
                            </tbody>
                        </Table>                     
                        </Card.Body>
                    </Card>
                </Col>
            </Row>                             
            <Row hidden="true">
                <Col>
                    <Card className="deviceCard" id="wienWebcam">
                        <Card.Header>
                            <span style={{lineHeight: "36px"}}>Letzte Aufnahme: {currentImageDate}</span>
                            {/* <Button id="BtnSwitchRefresh" variant="contained" color="primary" className="fltr configButton" onClick={getECIMG}><Icon name="refresh"/></Button> */}
                        </Card.Header>
                        <Card.Body>
                            <img src={ecImgFileUrl} style={{width: "100%"}}></img>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
            {/* <div id="luxGraphWrapper" className="hide">
                <LuxGraph/>
            </div>                 */}
            <Row>
                <Col>
                    <Card className="deviceCard hide" id="wienSchaltschrankA">
                        <Card.Header>Visualisierung Schaltschrank A: 
                            <span id="scheibeWaitingMaster" className="scheibeWaiting"> Wartet auf Daten</span>
                            <span id="scheibeDatetimeMaster"> {currentVizTimeMaster}</span>
                        </Card.Header>
                        <Card.Body>
                            <Chart options={optionsMaster} series={seriesMaster} type="bar" style={{height: '100%'}} height={400} />
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Card className="deviceCard hide" id="wienSchaltschrankB">
                        <Card.Header>Visualisierung Schaltschrank B: 
                            <span id="scheibeWaitingSlave" className="scheibeWaiting"> Wartet auf Daten</span>
                            <span id="scheibeDatetimeSlave"> {currentVizTimeSlave}</span>
                        </Card.Header>
                        <Card.Body>
                            <Chart options={optionsSlave} series={seriesSlave} type="bar" style={{height: '100%'}} height={400} />
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
            <Row>
                <Col sm="12" md="6" lg="6" xl="6">
                    <Card className="deviceCard hide" id="wienControlSchaltschrankA">
                        <Card.Header>Steuerung einzelner Scheiben: Schrank A
                        </Card.Header>
                        <Card.Body>                                
                            <div style={{marginBottom: 12}}>Scheibe #: <input id="inputScheibeSetSingleA" className="floatRight" type="number" min="0" max="228"></input></div>
                            <div>Sollwert %: <input id="inputValueSetSingleA" className="floatRight" type="number" min="0" max="100"></input></div>
                            <br/>                                
                            <Button id="btnSetSingleA" variant="contained" color="primary" className="generic-btn floatRight" style={{marginLeft: 10, marginTop: 10}}>Sollwert Senden</Button>
                            <Button id="btnResetSingleA" variant="contained" color="primary" className="generic-btn floatRight" style={{marginTop: 10}}>Scheibe Zurücksetzen</Button>
                            <br/><br/>      
                        </Card.Body>
                    </Card>
                </Col>
                <Col sm="12" md="6" lg="6" xl="6">
                    <Card className="deviceCard hide" id="wienControlSchaltschrankB">
                        <Card.Header>Steuerung einzelner Scheiben: Schrank B
                        </Card.Header>
                        <Card.Body>
                            <div style={{marginBottom: 12}}>Scheibe #: <input id="inputScheibeSetSingleB"  className="floatRight" type="number" min="0" max="222"></input></div>
                            <div>Sollwert %: <input id="inputValueSetSingleB" className="floatRight" type="number" min="0" max="100"></input></div>
                            <br/>                                
                            <Button id="btnSetSingleB" variant="contained" color="primary" className="generic-btn floatRight" style={{marginLeft: 10, marginTop: 10}}>Sollwert Senden</Button>
                            <Button id="btnResetSingleB" variant="contained" color="primary" className="generic-btn floatRight" style={{marginTop: 10}}>Scheibe Zurücksetzen</Button>
                            <br/><br/>                          
                        </Card.Body>
                    </Card>
                </Col>                   
            </Row>                
        </div>
    );
}

export default Econtrol;