import React, {Component, useState, Fragment, useEffect} from 'react';
import {Route, Link} from 'react-router-dom';
import ezglogo from '../../assets/ezg_logo.png';
import eclogo from '../../assets/ec_logo.png';
import Card from "react-bootstrap/Card";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from '@material-ui/core/Button';

const Econtrol3Group = (props) => {

    console.log(props);

    return (
        <div className="card projectCard">          
            <Card.Header className="text-muted">
                <div className="card-project-footer-text">Aktueller Sollwert: <span id={props.panelGroupGuid + "_currentSollwert"}></span>%<br></br>{props.panelGroupName} {props.panelGroupDesc}</div>
                <Button id={props.panelGroupGuid + "_ModeManual"} variant="contained" color="primary" style={{float: 'right'}} className="generic-btn ecbutton">Manueller Modus</Button>                     
                <Button id={props.panelGroupGuid + "_ModeAuto"}  variant="contained" color="primary" style={{float: 'right', marginRight: 10}} className="generic-btn ecbutton">Automatik</Button>
            </Card.Header>   
            <Card.Body>
                <div className="groupLightswitch">
                    <div className="cardLabel">
                        <div id="ecSollwertGroup"  className="card-title-buttons">
                            <Button id={props.panelGroupGuid + "_sollwert0"} variant="contained" color="" className="generic-btn fltl ecbutton">0%</Button>
                            <Button id={props.panelGroupGuid + "_sollwert10"} variant="contained" color="" className="generic-btn fltl ecbutton">10%</Button>
                            <Button id={props.panelGroupGuid + "_sollwert20"} variant="contained" color="" className="generic-btn fltl ecbutton">20%</Button>
                            <Button id={props.panelGroupGuid + "_sollwert30"} variant="contained" color="" className="generic-btn fltl ecbutton">30%</Button>
                            <Button id={props.panelGroupGuid + "_sollwert40"} variant="contained" color="" className="generic-btn fltl ecbutton">40%</Button>
                            <Button id={props.panelGroupGuid + "_sollwert50"} variant="contained" color="" className="generic-btn fltl ecbutton">50%</Button>                                       
                            <Button id={props.panelGroupGuid + "_sollwert60"} variant="contained" color="" className="generic-btn fltl ecbutton">60%</Button>
                            <Button id={props.panelGroupGuid + "_sollwert70"} variant="contained" color="" className="generic-btn fltl ecbutton">70%</Button>
                            <Button id={props.panelGroupGuid + "_sollwert80"} variant="contained" color="" className="generic-btn fltl ecbutton">80%</Button>
                            <Button id={props.panelGroupGuid + "_sollwert90"} variant="contained" color="" className="generic-btn fltl ecbutton">90%</Button>
                            <Button id={props.panelGroupGuid + "_sollwert100"} variant="contained" color="" className="generic-btn fltl ecbutton">100%</Button> 
                        </div>  
                    </div>
                    <div className="cardGroupSwitch"></div>
                </div>                                       
            </Card.Body>         
        </div>
    );
};

export default Econtrol3Group;