import React, {Component, useState, setState, Fragment, useEffect} from 'react';
import {Link} from 'react-router-dom';
import Card from 'react-bootstrap/Card';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Table from 'react-bootstrap/Table';
import ECGlassRow from '../econtrol/ECGlassRow';
import ArrowBackIosTwoToneIcon from '@material-ui/icons/ArrowBackIosTwoTone';
import Button from '@material-ui/core/Button';
import LinkIcon from '@material-ui/icons/Link';
import LinkOffIcon from '@material-ui/icons/LinkOff';
import Icon from "../Icons";

import eclogo from '../../assets/ec_logo.png';



export default class ECGallery extends Component{

    render() {   
        return (
            <div className="module-wrapper">
                <div className="distancer">
                    {/* // SUBNAVIGATION */}
                    <div className="subnav">
                        <div className="fltl">
                            <Link to="EControl">
                                <Button variant="contained" color="primary" className="back-button"  startIcon={<ArrowBackIosTwoToneIcon/>}>Zurück</Button>
                            </Link>
                        </div>
                        <div className="spacer"></div>                     
                    </div>
                    {/* END SUBNAVIGATION*/}  
                    <div className="outer-title">
                            <img className="" src={eclogo} alt="Logo" style={{height: "50px", margin: "0px 0px 5px 0px"}}/> Kuppel Parlament Wien
                    </div>
                    <Row>
                    <Col>
                        <Card className="deviceCard">
                            <Card.Header>
                                Bilder
                            </Card.Header>
                            <Card.Body>
                                <a href="https://zvconnect.de/downloads/bilder/wien/IMG_0693.jpg" target="_blank">
                                    <img class="galleryImg" src="https://zvconnect.de/downloads/bilder/wien/IMG_0693.jpg"></img>
                                </a>
                                <a href="https://zvconnect.de/downloads/bilder/wien/IMG_0700.jpg" target="_blank">
                                    <img class="galleryImg" src="https://zvconnect.de/downloads/bilder/wien/IMG_0700.jpg"></img>
                                </a> 
                                <a href="https://zvconnect.de/downloads/bilder/wien/IMG_0701.jpg" target="_blank">
                                    <img class="galleryImg" src="https://zvconnect.de/downloads/bilder/wien/IMG_0701.jpg"></img>
                                </a>
                                <a href="https://zvconnect.de/downloads/bilder/wien/IMG_0702.jpg" target="_blank">
                                    <img class="galleryImg" src="https://zvconnect.de/downloads/bilder/wien/IMG_0702.jpg"></img>
                                </a>  
                                <a href="https://zvconnect.de/downloads/bilder/wien/IMG_0709.jpg" target="_blank">
                                    <img class="galleryImg" src="https://zvconnect.de/downloads/bilder/wien/IMG_0709.jpg"></img>
                                </a>
                                <a href="https://zvconnect.de/downloads/bilder/wien/IMG_0722.jpg" target="_blank">
                                    <img class="galleryImg" src="https://zvconnect.de/downloads/bilder/wien/IMG_0722.jpg"></img>
                                </a>      
                                <a href="https://zvconnect.de/downloads/bilder/wien/IMG_0723.jpg" target="_blank">
                                    <img class="galleryImg" src="https://zvconnect.de/downloads/bilder/wien/IMG_0723.jpg"></img>
                                </a>      
                                <a href="https://zvconnect.de/downloads/bilder/wien/IMG_0724.jpg" target="_blank">
                                    <img class="galleryImg" src="https://zvconnect.de/downloads/bilder/wien/IMG_0724.jpg"></img>
                                </a>
                                <a href="https://zvconnect.de/downloads/bilder/wien/IMG_0728.jpg" target="_blank">
                                    <img class="galleryImg" src="https://zvconnect.de/downloads/bilder/wien/IMG_0728.jpg"></img>
                                </a>
                                <a href="https://zvconnect.de/downloads/bilder/wien/IMG_0740.jpg" target="_blank">
                                    <img class="galleryImg" src="https://zvconnect.de/downloads/bilder/wien/IMG_0740.jpg"></img>
                                </a> 
                                <a href="https://zvconnect.de/downloads/bilder/wien/IMG_0750.jpg" target="_blank">
                                    <img class="galleryImg" src="https://zvconnect.de/downloads/bilder/wien/IMG_0750.jpg"></img>
                                </a>
                                <a href="https://zvconnect.de/downloads/bilder/wien/IMG_0762.jpg" target="_blank">
                                    <img class="galleryImg" src="https://zvconnect.de/downloads/bilder/wien/IMG_0762.jpg"></img>
                                </a>
                                <a href="https://zvconnect.de/downloads/bilder/wien/IMG_0772.jpg" target="_blank">
                                    <img class="galleryImg" src="https://zvconnect.de/downloads/bilder/wien/IMG_0772.jpg"></img>
                                </a>
                                <a href="https://zvconnect.de/downloads/bilder/wien/IMG_0773.jpg" target="_blank">
                                    <img class="galleryImg" src="https://zvconnect.de/downloads/bilder/wien/IMG_0773.jpg"></img>
                                </a>
                                <a href="https://zvconnect.de/downloads/bilder/wien/IMG_0778.jpg" target="_blank">
                                    <img class="galleryImg" src="https://zvconnect.de/downloads/bilder/wien/IMG_0778.jpg"></img>
                                </a>  
                                <a href="https://zvconnect.de/downloads/bilder/wien/IMG_0781.jpg" target="_blank">
                                    <img class="galleryImg" src="https://zvconnect.de/downloads/bilder/wien/IMG_0781.jpg"></img>
                                </a>
                                <a href="https://zvconnect.de/downloads/bilder/wien/IMG_0797.jpg" target="_blank">
                                    <img class="galleryImg" src="https://zvconnect.de/downloads/bilder/wien/IMG_0797.jpg"></img>
                                </a>  
                                <a href="https://zvconnect.de/downloads/bilder/wien/IMG_0799.jpg" target="_blank">
                                    <img class="galleryImg" src="https://zvconnect.de/downloads/bilder/wien/IMG_0799.jpg"></img>
                                </a>
                                <a href="https://zvconnect.de/downloads/bilder/wien/IMG_2114.jpg" target="_blank">
                                    <img class="galleryImg" src="https://zvconnect.de/downloads/bilder/wien/IMG_2114.jpg"></img>
                                </a>  
                                <a href="https://zvconnect.de/downloads/bilder/wien/IMG_2117.jpg" target="_blank">
                                    <img class="galleryImg" src="https://zvconnect.de/downloads/bilder/wien/IMG_2117.jpg"></img>
                                </a>
                                <a href="https://zvconnect.de/downloads/bilder/wien/IMG_2119.jpg" target="_blank">
                                    <img class="galleryImg" src="https://zvconnect.de/downloads/bilder/wien/IMG_2119.jpg"></img>
                                </a>  
                                <a href="https://zvconnect.de/downloads/bilder/wien/IMG_2121.jpg" target="_blank">
                                    <img class="galleryImg" src="https://zvconnect.de/downloads/bilder/wien/IMG_2121.jpg"></img>
                                </a> 
                                <a href="https://zvconnect.de/downloads/bilder/wien/IMG_2123.jpg" target="_blank">
                                    <img class="galleryImg" src="https://zvconnect.de/downloads/bilder/wien/IMG_2123.jpg"></img>
                                </a> 
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
                </div>
            </div>
        );
    }
}
