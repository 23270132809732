import React, {Component, useState, Fragment, useEffect} from 'react';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';

const ECGlassHistoryRow = (props) => {

    var Istwert = '';
    var Sollwert = '';

    if (props.Event === 'Neuer Sollwert') {
        Sollwert = props.Value;
    }
    else {
        Istwert = props.Value;
    }

    return (
        <TableRow>
            <TableCell style={{textAlign: 'right'}}>{props.Event}</TableCell >
            <TableCell style={{textAlign: 'right'}}>{props.User}</TableCell > 
            <TableCell style={{textAlign: 'right'}}>{Sollwert}</TableCell >   
            <TableCell style={{textAlign: 'right'}}>{Istwert}</TableCell >               
            <TableCell style={{textAlign: 'right'}}>{props.Time}</TableCell>
        </TableRow>
    );
};

export default ECGlassHistoryRow;