import React, {Component} from 'react';
import Chart from 'react-apexcharts'
import Button from '@material-ui/core/Button';
import Card from 'react-bootstrap/Card';
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';
import Icon from "../../Icons";

class SensorGraph extends Component {   
    constructor(props) {
        super(props);

            this.todaysDate = '';
            this.todaysDateSimplified = '';
            this.currentlyDisplayedDate = '';
            this.currentlyDisplayedDateSimplified = '';
            // Color Values used by Chart
            this.colorValues = ["#000000", "#0D47A1", "#2196F3", "#BBDEFB", "#B71C1C", "#F44336", "#FE949F", "#194D33", "#388E3C", "#81C784", "#FF6F00", "#FFD54F"];

            this.state = {

            authToken: '',
            showConfig: 0,

            currentSensorID: 7,    
            currentSensorName: 'Sensor 1',
            tempNames: ['Kaltwasser', 'Warmwasser', 'Temp3'],

            yAxisConfig: [],    
            lastFetchedDate: '',
            lastT1: '',
            lastT2: '',
            lastT3: '',
            lastBat: '',
            lastRssi: '',
            lastTimestamp: '',

            temp1Data: [],
            temp2Data: [],
            temp3Data: [],

            // Default Options
            options: {
                colors: ['#34b7eb', '#eb4034', '#44b034'],
                chart: {
                    id: 'temperaturverlauf',
                    zoom: {
                        type: 'xy',
                    },
                },
                tooltip: {
                    enabled: true, 
                    x: {
                        format: 'HH:mm'
                    }
                },              
                xaxis: {
                    type: 'datetime',             
                    categories: ['X1','X2', 'X3'],
                    labels: {
                        format: 'HH:mm',
                      }
                },
                yaxis: {
                    min: 10,
                    max: 35
                }
            },

            optionsBat: {
                colors: ['#ffda00'],
                chart: {
                    id: 'batteriestand',
                    zoom: {
                        type: 'xy',
                    },
                },
                xaxis: {
                    type: "datetime",
                },
                yaxis: {
                }
            },

            series: []
        }         
    }

    // STATE EVENT HANDLERS
    onSensorConfigNameChange(value) {this.setState({currentSensorName: value});}
    onSensorConfigNameChange(value) {this.setState({currentSensorName: value});}
    //

    switchDayBack(){
        var newDateToFetch = this.currentlyDisplayedDate;
        newDateToFetch.setDate(newDateToFetch.getDate()-1);


        var dd = String(newDateToFetch.getDate()).padStart(2, '0');
        var mm = String(newDateToFetch.getMonth() + 1).padStart(2, '0'); //January is 0!
        var yy = newDateToFetch.getFullYear().toString().substr(-2); 
        var newDateToFetchSimplified = dd + "/" + mm + "/" + yy;

        this.currentlyDisplayedDate = newDateToFetch;
        this.currentlyDisplayedDateSimplified = newDateToFetchSimplified;    

        this.getSensorData(this.state.currentSensorID)
    }

    switchDayForward(){
        var newDateToFetch = this.currentlyDisplayedDate;
        newDateToFetch.setDate(newDateToFetch.getDate()+1);

        var dd = String(newDateToFetch.getDate()).padStart(2, '0');
        var mm = String(newDateToFetch.getMonth() + 1).padStart(2, '0'); //January is 0!
        var yy = newDateToFetch.getFullYear().toString().substr(-2); 
        var newDateToFetchSimplified = dd + "/" + mm + "/" + yy;

        this.currentlyDisplayedDate = newDateToFetch;
        this.currentlyDisplayedDateSimplified = newDateToFetchSimplified;    

        this.getSensorData(this.state.currentSensorID)
    }

    switchDayToday(){
        this.currentlyDisplayedDateSimplified = '';
        this.getSensorData(this.state.currentSensorID)
    }

    switchRefresh(){
        this.getSensorData(this.state.currentSensorID)
    }

    switchConfig(){
        if (this.state.showConfig === 1) {
            this.setState({showConfig: 0});            
            document.querySelector('#greyOverlay').style.display= 'none';
            document.querySelector('#configWindow').style.display= 'none';
        }
        else {
            this.setState({showConfig: 1});
            document.querySelector('#greyOverlay').style.display= 'block';  
            document.querySelector('#configWindow').style.display= 'block';        
        }
    }

    saveNewConfig(){

        var newConfigJson = {
            nodeId: this.state.currentSensorID,
            nodeName: document.querySelector('#sensorConfigName').value,
            sensor1Descriptor: document.querySelector('#sensorConfigDesc1').value,
            sensor2Descriptor: document.querySelector('#sensorConfigDesc2').value,
            sensor3Descriptor: document.querySelector('#sensorConfigDesc3').value,
            sensor1Color:  parseInt(document.querySelector('#colorSelectorTemp1').value),
            sensor2Color:  parseInt(document.querySelector('#colorSelectorTemp2').value),
            sensor3Color:  parseInt(document.querySelector('#colorSelectorTemp3').value),
            axisYMin: parseInt(document.querySelector('#sensorConfigYMin').value),
            axisYMax:  parseInt(document.querySelector('#sensorConfigYMax').value),
        }

        console.log("POSTING: " + JSON.stringify(newConfigJson));

        const sendConfigTriTemp = {
            method: 'POST',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify(newConfigJson)
        }

        // POST TO API
        fetch('https://api.zvconnect.de/tritemphyg/sensors/', sendConfigTriTemp)
        .then(response => response.json())
        .then(responseJson => console.log("HERE" + JSON.stringify(responseJson)))

        // SEND TO LOCAL CONFIG PARSER
        this.parseSensorUIConfig(newConfigJson);        
        // HIDE CONFIG WINDOW
        this.switchConfig()
        // REFRESH CHART
        this.getSensorData(this.state.currentSensorID);
    }

    UNSAFE_componentWillMount() {
        this.getSensorData(this.state.currentSensorID);    
    }

    getSensorData(id){ 

        const requestOptionsTriTemp = {
            method: 'GET',
            headers: {'Content-Type': 'application/json'},
        }

        // FETCH SENSOR UI CONFIG
        fetch('https://api.zvconnect.de/tritemphyg/sensors/' + id + '/', requestOptionsTriTemp)
        .then(response => response.json())
        .then(responseJson => {this.parseSensorUIConfig(responseJson);
        })      
        
        // GET SENSOR HISTORY DATA       
        var currentDateToGet = this.currentlyDisplayedDateSimplified;
        console.log("currentDateToGet= " + currentDateToGet)

        // IF NO DATE -> FETCH FOR TODAY
        if (currentDateToGet === '') {
            var today = new Date();
            var dd = String(today.getDate()).padStart(2, '0');
            var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
            var yy = today.getFullYear().toString().substr(-2);            
            currentDateToGet = dd + "/" + mm + "/" + yy;
            
            // Push to Global Variable
            this.currentlyDisplayedDate = today;
            this.currentlyDisplayedDateSimplified = currentDateToGet;
                       
        }

        // PUSH CURRENTLY USED DATE TO STATE
        this.setState({lastFetchedDate: currentDateToGet})        
        //console.log("DateToFetchWith= " + currentDateToGet)

        // FETCH SENSOR HISTORY DATA
        fetch('https://api.zvconnect.de/tritemphyg/?sensorid=' + id + '&startdate='+ currentDateToGet +'&enddate='+ currentDateToGet +'&resolution=2', requestOptionsTriTemp)
        .then(response => response.json())
        .then(responseJson => {this.splitDataPoints(responseJson);
        })
    }
    
    splitDataPoints(responseJson) {
        console.log('responseJson');
        console.log(responseJson);
        console.log("Entries:" + responseJson.length);

        // Create Split Arrays of Temps for Graph
        var temp1Data = {name: this.state.tempNames[0], data: []};
        var temp2Data = {name: this.state.tempNames[1], data: []};
        //var temp3Data = {name: this.state.tempNames[2], data: []};            

        responseJson.forEach(element => {
            var timeDataPoint = element.time;
            var temp1DataPoint = element.temp1 / 100;
            //if (!(temp1DataPoint > 100) && !(temp1DataPoint < -100)) {
                temp1Data.data.push({ x: timeDataPoint, y: parseFloat(temp1DataPoint)});                
            //}
            var temp2DataPoint = element.temp2 / 100;
            //if (!(temp2DataPoint > 50) && !(temp2DataPoint < 0)) {
                temp2Data.data.push({ x: timeDataPoint, y: parseFloat(temp2DataPoint)});                
            //}
            //var temp3DataPoint = element.temp3 / 10;
            //if (!(temp3DataPoint > 50) && !(temp3DataPoint < 0)) {
            //    temp3Data.data.push({ x: timeDataPoint, y: parseFloat(temp3DataPoint)});                
            //}            
        });


        this.setState({temp1Data: [temp1Data]});
        this.setState({temp2Data: [temp2Data]});

        this.setState({series: [temp1Data, temp2Data]})        
    }

    parseSensorUIConfig(responseJson){

        var configData = responseJson;

        console.log("Processing Config: " + JSON.stringify(configData));

        // Name of the Sensor. Only for Labels. Goes into State.
        this.setState({currentSensorName: configData.nodeName});
        // Labels for Temp 1-3. Used by 'splitDataPoints' when formatting the Data Series.
        this.setState({tempNames: [configData.sensor1Descriptor, configData.sensor2Descriptor, configData.sensor3Descriptor]});
        document.querySelector('#sensorConfigDesc1').value = [configData.sensor1Descriptor];
        document.querySelector('#sensorConfigDesc2').value = [configData.sensor2Descriptor];   
        document.querySelector('#sensorConfigDesc3').value = [configData.sensor3Descriptor];      
        // Colors for the chart. Goes into chart config.
        var uiConfigColors = [this.colorValues[configData.sensor1Color], this.colorValues[configData.sensor2Color], this.colorValues[configData.sensor3Color]];
        // Set Colors in Config Panel
        document.querySelector('#colorSelectorTemp1').value = [configData.sensor1Color];
        document.querySelector('#colorSelectorTemp2').value = [configData.sensor2Color];
        document.querySelector('#colorSelectorTemp3').value = [configData.sensor3Color];

        // Y-Axis Limits for the chart. Goes into chart config.
        var uiConfigYAxis = {min: configData.axisYMin, max: configData.axisYMax};
        //this.setState({yAxisConfig: [configData.axisYMin, configData.axisYMax]});
        document.querySelector('#sensorConfigYMin').value = [configData.axisYMin];
        document.querySelector('#sensorConfigYMax').value = [configData.axisYMax];
        

        var newUiConfig = {
            colors: uiConfigColors,
            chart: {
                id: 'Temperaturverlauf',
                zoom: {
                    type: 'xy',
                },
            },
            tooltip: {
                enabled: true, 
                x: {
                    format: 'HH:mm'
                }
            },              
            xaxis: {
                type: 'datetime',                    
                labels: {
                    format: 'HH:mm',
                }
            },
            yaxis: uiConfigYAxis
        }

        this.setState({options: newUiConfig});
    }

    render() {
    return (            
            <div className="tempChart-Wrapper">
                <div id="greyOverlay" style={{display: "none"}}> </div>
                <div id="configWindow" style={{display: "none"}}>
                    <div className="configWindowTitle">
                        Konfiguration: {this.state.currentSensorName}
                    </div>
                    <hr/>
                    <div>
                        <Form>
                            <Form.Group>
                                <Form.Row>
                                    <Form.Label column="sm" lg={5}>Name Sensor</Form.Label>
                                    <Col><Form.Control id="sensorConfigName" size="sm" type="text" value={this.state.currentSensorName} onChange={e => this.onSensorConfigNameChange(e.target.value)} /></Col>     
                                </Form.Row>
                                <hr/>
                                <Form.Row>
                                    <Form.Label column="sm" lg={5}>Name Temp. 1</Form.Label>
                                    <Col><Form.Control id="sensorConfigDesc1" size="sm" type="text" /></Col> 
                                </Form.Row>
                                <br/>
                                <Form.Row>
                                    <Form.Label column="sm" lg={5}>Farbe Temp. 1</Form.Label>
                                    <Col><Form.Control size="sm" as="select" id="colorSelectorTemp1">
                                        <option value="0" style={{color: '#000000'}}>Schwarz</option> 
                                        <option value="1" style={{color: '#0D47A1'}}>Dunkelblau</option>                                    
                                        <option value="2" style={{color: '#2196F3'}}>Blau</option>
                                        <option value="3" style={{color: '#BBDEFB'}}>Hellbau</option>
                                        <option value="4" style={{color: '#B71C1C'}}>Dunkelrot</option>
                                        <option value="5" style={{color: '#F44336'}}>Rot</option>
                                        <option value="6" style={{color: '#FE949F'}}>Hellrot</option>
                                        <option value="7" style={{color: '#194D33'}}>Dunkelgrün</option>
                                        <option value="8" style={{color: '#388E3C'}}>Grün</option>
                                        <option value="9" style={{color: '#81C784'}}>Hellgrün</option>
                                        <option value="10" style={{color: '#FF6F00'}}>Orange</option>
                                        <option value="11" style={{color: '#FFD54F'}}>Gelb</option>                                                                                                         
                                    </Form.Control></Col> 
                                </Form.Row>
                                <hr/>
                                <Form.Row>
                                    <Form.Label column="sm" lg={5}>Name Temp. 2</Form.Label>
                                    <Col><Form.Control id="sensorConfigDesc2" size="sm" type="text"/></Col> 
                                </Form.Row>
                                <br/>
                                <Form.Row>
                                    <Form.Label column="sm" lg={5}>Farbe Temp. 2</Form.Label>
                                    <Col><Form.Control size="sm" as="select" id="colorSelectorTemp2">
                                        <option value="0" style={{color: '#000000'}}>Schwarz</option> 
                                        <option value="1" style={{color: '#0D47A1'}}>Dunkelblau</option>                                    
                                        <option value="2" style={{color: '#2196F3'}}>Blau</option>
                                        <option value="3" style={{color: '#BBDEFB'}}>Hellbau</option>
                                        <option value="4" style={{color: '#B71C1C'}}>Dunkelrot</option>
                                        <option value="5" style={{color: '#F44336'}}>Rot</option>
                                        <option value="6" style={{color: '#FE949F'}}>Hellrot</option>
                                        <option value="7" style={{color: '#194D33'}}>Dunkelgrün</option>
                                        <option value="8" style={{color: '#388E3C'}}>Grün</option>
                                        <option value="9" style={{color: '#81C784'}}>Hellgrün</option>
                                        <option value="10" style={{color: '#FF6F00'}}>Orange</option>
                                        <option value="11" style={{color: '#FFD54F'}}>Gelb</option>  
                                    </Form.Control></Col> 
                                </Form.Row>
                                <hr/>
                                <Form.Row>
                                    <Form.Label column="sm" lg={5}>Name Temp. 3</Form.Label>
                                    <Col><Form.Control id="sensorConfigDesc3" size="sm" type="text"/></Col> 
                                </Form.Row>
                                <br/>
                                <Form.Row>
                                    <Form.Label column="sm" lg={5}>Farbe Temp. 3</Form.Label>
                                    <Col><Form.Control size="sm" as="select" id="colorSelectorTemp3">
                                        <option value="0" style={{color: '#000000'}}>Schwarz</option> 
                                        <option value="1" style={{color: '#0D47A1'}}>Dunkelblau</option>                                    
                                        <option value="2" style={{color: '#2196F3'}}>Blau</option>
                                        <option value="3" style={{color: '#BBDEFB'}}>Hellbau</option>
                                        <option value="4" style={{color: '#B71C1C'}}>Dunkelrot</option>
                                        <option value="5" style={{color: '#F44336'}}>Rot</option>
                                        <option value="6" style={{color: '#FE949F'}}>Hellrot</option>
                                        <option value="7" style={{color: '#194D33'}}>Dunkelgrün</option>
                                        <option value="8" style={{color: '#388E3C'}}>Grün</option>
                                        <option value="9" style={{color: '#81C784'}}>Hellgrün</option>
                                        <option value="10" style={{color: '#FF6F00'}}>Orange</option>
                                        <option value="11" style={{color: '#FFD54F'}}>Gelb</option>  
                                    </Form.Control></Col> 
                                </Form.Row>
                                <hr/>
                                <Form.Row>
                                    <Form.Label column="sm" lg={5}>Y-Achse Minimum</Form.Label>
                                    <Col><Form.Control id="sensorConfigYMin" size="sm" type="number"/></Col> 
                                </Form.Row>
                                <br/>
                                <Form.Row>
                                    <Form.Label column="sm" lg={5}>Y-Achse Maximum</Form.Label>
                                    <Col><Form.Control id="sensorConfigYMax" size="sm" type="number"/></Col> 
                                </Form.Row>                                                                                                                              
                            </Form.Group>                                                                  
                        </Form>
                    </div>
                    <hr/>
                    <div className="configWindowButtons">
                        <Button id="BtnConfig" variant="contained" color="" className="btnSuccess" onClick={() => { this.saveNewConfig() }}>Speichern</Button>
                        <Button id="BtnConfig" variant="contained" color="" className="btnCancel" onClick={() => { this.switchConfig() }}>Schliessen</Button>
                    </div>
                </div>
                <div>
                    <Button id="BtnSwitchDayBack" variant="contained" color="primary" className="fltr" onClick={() => { this.switchDayBack() }}>Tag Zurück</Button>
                    <Button id="BtnSwitchDayForward" variant="contained" color="primary" className="fltr" onClick={() => { this.switchDayForward() }}>Tag Vor</Button>
                    <Button id="BtnSwitchDayToday" variant="contained" color="primary" className="fltr" onClick={() => { this.switchDayToday() }}>Heute</Button>
                    <Button id="BtnConfig" variant="contained" color="primary" className="fltr configButton" onClick={() => { this.switchConfig() }}><Icon name="settings"/></Button>
                    <Button id="BtnSwitchRefresh" variant="contained" color="primary" className="fltr configButton" onClick={() => { this.switchRefresh() }}><Icon name="refresh"/></Button>                    
                </div>
                <Card className="tempChart-card">
                    <Card.Header>
                        <div className="card-title">Temperaturverlauf: {this.state.lastFetchedDate}</div>
                    </Card.Header> 
                    <Card.Body>                    
                        <Chart options={this.state.options} series={this.state.series} type="line" style={{height: '100%'}} height={400} />
                    </Card.Body>
                </Card>
            </div>        
        );
    }
}
export default SensorGraph;