import React, {Component, useState, Redirect} from 'react';
import {Route, Link, useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import zvclogo_text from '../assets/zvclogo_text.png';
import zvclogo_icon from '../assets/images/zvconnect_logo_icon.png';
import zvclogo_full from '../assets/zvclogo_full.png';
import useToken from '../security/useToken';

//HOW TO
//https://www.digitalocean.com/community/tutorials/how-to-add-login-authentication-to-react-applications

async function loginUser(credentials) {
  return fetch('https://api.zvconnect.de/auth/token/', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(credentials)
  })
    .then(data => data.json())
    
}

async function resetUserPassword(email) {

  var resetBody = {
    "email" : email
  }

  console.log(JSON.stringify(resetBody));

  return fetch('https://api.zvconnect.de/password_reset/', {
    method: 'POST',
    headers: {'Content-Type': 'application/json'},
    body: JSON.stringify(resetBody),
  })
    .then(data => console.log(data))    
}

export default function Login({ setToken }) {
  const [username, setUserName] = useState();  
  const [password, setPassword] = useState();
  const [rememberMe, setRememberMe] = useState();
  const [resetName, setUserNameReset] = useState();

  const handleSubmitLogin = async e => {
    e.preventDefault();
    const token = await loginUser({
      username,
      password
    });
      
     if ("access" in token) {
      var re = token.refresh
      var token2 = {token: re}
      console.log(token2)
      console.log(JSON.stringify(token2))
      console.log("approved")

       // Call setToken with Result
       if (rememberMe === true) {
        console.log("Local based login approved")
        localStorage.setItem('token', JSON.stringify(token2));
        sessionStorage.setItem('username', username)       
       }

        console.log("Session based login approved")
        sessionStorage.setItem('username', username)
        localStorage.setItem('token', JSON.stringify(token2)); 
        setToken(token2);       
     }

     else {
      document.getElementById("login-card").className = "login-card shake";      
      removeShake("login-card");
      console.log("Login Failed");
     } 
  }

  const handleSubmitReset = async e => {
    e.preventDefault();

    if (resetName !== null && resetName !== "" && resetName !== undefined)
    {
      resetUserPassword(resetName);
      switchToForgotPasswordSuccess();
    }
    else {
      document.getElementById("reset-card").className = "login-card shake";      
      removeShake("reset-card");
      console.log("Reset Failed");
    }
  }

  function removeShake(controlName) {
    setTimeout(function(){ 
      document.getElementById(controlName).className = "login-card";    
    }, 500);
  }   

  function switchToLogin() {
    document.getElementById("pnlLogin").classList.remove("hide");
    document.getElementById("pnlReset").classList.add("hide");
    document.getElementById("pnlResetSuccess").classList.add("hide");
  }

  function switchToForgotPassword() {
    document.getElementById("pnlLogin").classList.add("hide");
    document.getElementById("pnlReset").classList.remove("hide");
    document.getElementById("pnlResetSuccess").classList.add("hide");
  }

  function switchToForgotPasswordSuccess() {
    document.getElementById("pnlLogin").classList.add("hide");
    document.getElementById("pnlReset").classList.add("hide");
    document.getElementById("pnlResetSuccess").classList.remove("hide");
  }

  return(
    <div className="login-wrapper"> 
				<div className="flex login-flex-wrapper">					
					<div className="login-background">
            {/* DECORATIVE CSS GRID */}
						<div className="login-background-grid-container">
							<div className="flex borderBox grid-1">
								<div className="borderBox grid-1a"></div>
							</div>
							<div className="flex borderBox grid-2">
								<div className="borderBox grid-2a"></div>
							</div>
              <div className="flex borderBox grid-3">
								<div className="borderBox grid-3a"></div>
							</div>
              <div className="flex borderBox grid-4">
								<div className="borderBox grid-4a"></div>
							</div>
              <div className="flex borderBox grid-5">
								<div className="borderBox grid-5a"></div>
							</div>
              <div className="flex borderBox grid-6">
								<div className="borderBox grid-6a"></div>
							</div>
              <div className="flex borderBox grid-7">
								<div className="borderBox grid-7a"></div>
							</div>
              <div className="flex borderBox grid-8">
								<div className="borderBox grid-8a"></div>
							</div>
              <div className="flex borderBox grid-9">
								<div className="borderBox grid-9a"></div>
							</div>
              <div className="flex borderBox grid-10">
								<div className="borderBox grid-10a"></div>
							</div>              
						</div>
					</div>
          {/* TOP CENTER ZVC LOGO */}
					<div className="login-logo-wrapper">			
             {/* <img className="login-logo-icon" src={zvclogo_icon} alt="ZVconnectIcon"/>
             <img className="login-logo-text" src={zvclogo_text} alt="ZVconnectLogo"/> */}
             <img className="login-logo-full" src={zvclogo_full} alt="ZVconnectLogo"/>             
					</div>
          {/* LOGIN PANEL*/}
					<div id="pnlLogin" className="login-content">	
						<table id="Login1" className="login-table" >
		          <tbody><tr>
	          		<td>
							  	<div>
							  		<div id="login-card" className="login-card">
                      <form onSubmit={handleSubmitLogin}>
                        <div className="login-card-title">Anmeldung</div>
                        <div className="login-card-row">
                          <div className="login-label-s">Benutzername</div>                            	
                          <input className="login-input" type="text" onChange={e => setUserName(e.target.value)}/>
                        </div>
                        <div className="login-card-row">
                          <span className="login-label-s">Passwort</span><span className="login-label-s floatRight clickableText" onClick={switchToForgotPassword}>Passwort vergessen?</span>
                          <input className="login-input" type="password" onChange={e => setPassword(e.target.value)}/>
                        </div>
                        <div className="login-card-row">
                          <input className="login-checkbox" type="checkbox" onChange={e => setRememberMe(e.target.checked)}/>
                          <span className="login-label-s"> Angemeldet bleiben</span>
                        </div>
                        <div className="login-card-row">
                          <button className="login-button" type="submit">Login</button>
                        </div>
                      </form>										
								  	</div>
							  	</div>
						  	</td>
	          	</tr></tbody>
           </table>					
        </div>
        <div id="pnlReset" className="login-content hide">	
						<table className="login-table">
		          <tbody><tr>
	          		<td>
							  	<div>
							  		<div id="reset-card" className="login-card">
                      <form id="reset-form" onSubmit={handleSubmitReset}>
                        <div className="login-card-title">Passwort vergessen</div>
                        <div className="login-card-row">                          
                          <div className="login-label-s">E-Mail</div>                            	
                          <input className="reset-input" type="text" onChange={e => setUserNameReset(e.target.value)}/>
                        </div>
                        <div className="login-card-row">
                          <button className="login-button" type="submit">Passwort Zurücksetzen</button>
                        </div>                        
                        <div className="login-back-label clickableText" onClick={switchToLogin}>Zurück zum Login</div>                        
                      </form>										
								  	</div>
							  	</div>
						  	</td>
	          	</tr></tbody>
           </table>					
        </div>
        <div id="pnlResetSuccess" className="login-content hide">	
						<table className="login-table">
		          <tbody><tr>
	          		<td>
							  	<div>
							  		<div id="reset-card" className="login-card">
                      <form id="reset-form">
                        <div className="login-card-title">Anfrage erfolgreich</div>
                        <div className="login-card-row">
                          <div className="login-label-s">Falls Ihre E-Mail in unserem System vorliegt werden Sie innerhalb der nächsten 5 Minuten eine E-Mail von uns erhalten.</div>       
                        </div>                        
                        <div className="login-back-label clickableText" onClick={switchToLogin}>Zurück zum Login</div>                        
                      </form>										
								  	</div>
							  	</div>
						  	</td>
	          	</tr></tbody>
           </table>					
        </div>
      {/* LOGIN FOOTER LINKS */}
      <div id="loginFooter" className="login-footer">
        <div>
          <a href="https://www.consoft.de" target="_blank"><span className="login-footer-label clickableText">Powered by ConSoft</span></a> |
          <a href="https://www.consoft.de/Impressum/" target="_blank"><span className="login-footer-label clickableText">Impressum</span></a> |
          <a href="https://www.consoft.de/Datenschutz/" target="_blank"><span className="login-footer-label clickableText">Datenschutz</span></a>
        </div>	
      </div>
		</div>
</div>
  )
}

Login.propTypes = {
  setToken: PropTypes.func.isRequired
}