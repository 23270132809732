import React, { Component } from 'react';
import Chart from 'react-apexcharts'
import Button from '@material-ui/core/Button';
import Card from 'react-bootstrap/Card';
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';

import Icon from "../../Icons";
import Close from '@material-ui/icons/CloseOutlined';

class TrinkwasserHygGraph extends Component {
    constructor(props) {
        super(props);

        this.todaysDate = '';
        this.todaysDateSimplified = '';
        this.currentlyDisplayedDate = '';
        this.currentlyDisplayedDateSimplified = '';
        // Color Values used by Chart
        this.colorValues = ["#000000", "#0D47A1", "#2196F3", "#BBDEFB", "#B71C1C", "#F44336", "#FE949F", "#194D33", "#388E3C", "#81C784", "#FF6F00", "#FFD54F"];

        this.state = {

            authToken: '',
            showConfig: 0,

            currentSensorID: 7,
            currentSensorName: 'Sensor 1',
            tempNames: ['Kaltwasser', 'Warmwasser', 'Temp3'],

            yAxisConfig: [],
            lastFetchedDate: '',
            lastT1: '',
            lastT2: '',
            lastT3: '',
            lastBat: '',
            lastRssi: '',
            lastTimestamp: '',

            temp1Data: [],
            temp2Data: [],
            temp3Data: [],

            // Default Options
            options: {
                colors: ['#34b7eb', '#eb4034', '#44b034'],
                chart: {
                    id: 'temperaturverlauf',
                    zoom: {
                        type: 'xy',
                    },
                },
                tooltip: {
                    enabled: true,
                    x: {
                        format: 'HH:mm'
                    }
                },
                xaxis: {
                    type: 'datetime',
                    categories: ['X1', 'X2', 'X3'],
                    labels: {
                        format: 'HH:mm',
                    }
                },
                yaxis: {
                    min: 10,
                    max: 35
                }
            },

            optionsBat: {
                colors: ['#ffda00'],
                chart: {
                    id: 'batteriestand',
                    zoom: {
                        type: 'xy',
                    },
                },
                xaxis: {
                    type: "datetime",
                },
                yaxis: {}
            },

            series: [],
            seriesTeceTemp1: [],
            seriesTeceTemp2: [],
            seriesTeceValve1: [],
            seriesTeceValve2: [],
        }
    }

    // STATE EVENT HANDLERS
    onSensorConfigNameChange(value) { this.setState({ currentSensorName: value }); }
    onSensorConfigNameChange(value) { this.setState({ currentSensorName: value }); }
        //

    switchDayBack() {
        var newDateToFetch = this.currentlyDisplayedDate;
        newDateToFetch.setDate(newDateToFetch.getDate() - 1);

        var dd = String(newDateToFetch.getDate()).padStart(2, '0');
        var mm = String(newDateToFetch.getMonth() + 1).padStart(2, '0'); //January is 0!
        var yy = newDateToFetch.getFullYear().toString().substr(-2);
        var newDateToFetchSimplified = dd + "/" + mm + "/" + yy;

        this.currentlyDisplayedDate = newDateToFetch;
        this.currentlyDisplayedDateSimplified = newDateToFetchSimplified;

        this.getSensorData(this.state.currentSensorID)

        console.log('newDateToFetch' + newDateToFetch);
        console.log('this.state.todaysDate' + this.state.todaysDate)

        if (newDateToFetch < this.state.todaysDate) {

            var BtnSwitchDayForward = document.getElementById("BtnSwitchDayForward");
            var BtnSwitchDayToday = document.getElementById("BtnSwitchDayToday");
            BtnSwitchDayForward.classList.remove('hide');
            BtnSwitchDayToday.classList.remove('hide');
        }
    }

    switchDayForward() {
        var newDateToFetch = this.currentlyDisplayedDate;
        newDateToFetch.setDate(newDateToFetch.getDate() + 1);

        var dd = String(newDateToFetch.getDate()).padStart(2, '0');
        var mm = String(newDateToFetch.getMonth() + 1).padStart(2, '0'); //January is 0!
        var yy = newDateToFetch.getFullYear().toString().substr(-2);
        var newDateToFetchSimplified = dd + "/" + mm + "/" + yy;

        this.currentlyDisplayedDate = newDateToFetch;
        this.currentlyDisplayedDateSimplified = newDateToFetchSimplified;

        this.getSensorData(this.state.currentSensorID)

        //console.log("newDateToFetch" + newDateToFetch + " " + 'this.state.todaysDate' + this.state.todaysDate)
        if (newDateToFetch.toString() === this.state.todaysDate.toString()) {
            var BtnSwitchDayForward = document.getElementById("BtnSwitchDayForward");
            var BtnSwitchDayToday = document.getElementById("BtnSwitchDayToday");
            BtnSwitchDayForward.classList.add('hide');
            BtnSwitchDayToday.classList.add('hide');
        }
    }

    switchDayToday() {
        this.currentlyDisplayedDateSimplified = '';
        this.getSensorData(this.state.currentSensorID)

        var BtnSwitchDayForward = document.getElementById("BtnSwitchDayForward");
        var BtnSwitchDayToday = document.getElementById("BtnSwitchDayToday");
        BtnSwitchDayForward.classList.add('hide');
        BtnSwitchDayToday.classList.add('hide');
    }

    switchRefresh() {
        this.getSensorData(this.state.currentSensorID)
    }

    switchConfig() {
        if (this.state.showConfig === 1) {
            this.setState({ showConfig: 0 });
            document.querySelector('#greyOverlay').style.display = 'none';
            document.querySelector('#configWindow').style.display = 'none';
        } else {
            this.setState({ showConfig: 1 });
            document.querySelector('#greyOverlay').style.display = 'block';
            document.querySelector('#configWindow').style.display = 'block';
        }
    }

    saveNewConfig() {
        var newConfigJson = {
            nodeId: this.state.currentSensorID,
            nodeName: document.querySelector('#sensorConfigName').value,
            sensor1Descriptor: document.querySelector('#sensorConfigDesc1').value,
            sensor2Descriptor: document.querySelector('#sensorConfigDesc2').value,
            sensor3Descriptor: document.querySelector('#sensorConfigDesc3').value,
            sensor1Color: parseInt(document.querySelector('#colorSelectorTemp1').value),
            sensor2Color: parseInt(document.querySelector('#colorSelectorTemp2').value),
            sensor3Color: parseInt(document.querySelector('#colorSelectorTemp3').value),
            axisYMin: parseInt(document.querySelector('#sensorConfigYMin').value),
            axisYMax: parseInt(document.querySelector('#sensorConfigYMax').value),
        }

        console.log("POSTING: " + JSON.stringify(newConfigJson));

        const sendConfigTriTemp = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(newConfigJson)
        }

        // POST TO API
        fetch('https://api.zvconnect.de/tritemphyg/sensors/', sendConfigTriTemp)
            .then(response => response.json())

        .then(responseJson => console.log("HERE" + JSON.stringify(responseJson)))

        // SEND TO LOCAL CONFIG PARSER
        this.parseSensorUIConfig(newConfigJson);

        // HIDE CONFIG WINDOW
        this.switchConfig()

        // REFRESH CHART
        //this.getSensorData(this.state.currentSensorID);

        setTimeout(function() { //Start the timer
            this.getSensorData(this.state.currentSensorID);
        }.bind(this), 1000)
    }

    UNSAFE_componentWillMount() {
        this.getSensorData(this.state.currentSensorID);
        this.setState({ todaysDate: new Date() })
    }

    getSensorData(id) {
        const requestOptionsTriTemp = {
            method: 'GET',
            headers: { 'Content-Type': 'application/json' },
        }

        // FETCH SENSOR UI CONFIG
        fetch('https://api.zvconnect.de/tritemphyg/sensors/' + this.state.currentSensorID + '/', requestOptionsTriTemp)
            .then(response => response.json())
            .then(responseJson => {
                this.parseSensorUIConfig(responseJson);
            })

        // GET SENSOR HISTORY DATA       
        var currentDateToGet = this.currentlyDisplayedDateSimplified;
        console.log("currentDateToGet= " + currentDateToGet)

        // IF NO DATE -> FETCH FOR TODAY
        if (currentDateToGet === '') {
            var today = new Date();
            var dd = String(today.getDate()).padStart(2, '0');
            var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
            var yy = today.getFullYear().toString().substr(-2);
            currentDateToGet = dd + "/" + mm + "/" + yy;

            // Push to Global Variable
            this.currentlyDisplayedDate = today;
            this.currentlyDisplayedDateSimplified = currentDateToGet;

        }

        // PUSH CURRENTLY USED DATE TO STATE
        this.setState({ lastFetchedDate: currentDateToGet })
            //console.log("DateToFetchWith= " + currentDateToGet)

        // FETCH VALVE HISTORY DATA

        /* //Fetchen der Valve-Daten temporär deaktiviert
        fetch('https://api.zvconnect.de/tece/?startdate=' + currentDateToGet + '&enddate=' + currentDateToGet, requestOptionsTriTemp)
            .then(response => response.json())
            .then(responseJson => {
                this.splitValveDataPoints(responseJson);
            })
        */

        // FETCH SENSOR HISTORY DATA
        fetch('https://api.zvconnect.de/quadtemphyg/?sensorid=' + id + '&startdate=' + currentDateToGet + '&enddate=' + currentDateToGet + '&resolution=1', requestOptionsTriTemp)
            .then(response => response.json())
            .then(responseJson => {
                this.splitDataPoints(responseJson);
            })
    }

    // splitValveDataPointsIntoAnnotations(responseJson) {
    //     console.log('responseJsonValves');
    //     console.log(responseJson);

    //     var teceAnnoXaxis = {xaxis: []};

    //     responseJson.forEach(element => {
    //         var valveTimeDataPoint = element.time;
    //         var valveStatusDataPoint = element.status * 10;
    //         var valveTimeDataPointDV = new Date(valveTimeDataPoint).getTime()

    //         if (element.status.toString()) {
    //                 teceAnnoXaxis.xaxis.push({x: valveTimeDataPointDV, strokeDashArray: 0, borderColor: "#775DD0", label: {    
    //                     style: {
    //                         color: "#303f9f",
    //                         // background: "#303f9f",
    //                         fontSize: "10px"                    
    //                     },
    //                     offsetX: -4,
    //                     offsetY: 14,
    //                     orientation: "horizontal",
    //                     text: valveStatusDataPoint.toString()+ "%"}})
    //         }
    //     });

    //     this.setState({seriesTeceAnnotations: [teceAnnoXaxis]})
    // }

    splitValveDataPoints(responseJson) {
        console.log('responseJsonValves');
        console.log(responseJson);

        var valve1Data = { name: 'Ventil-Kalt', type: 'column', data: [] }; //devid = 1
        var valve2Data = { name: 'Ventil-Warm', type: 'column', data: [] }; //devid = 2
        // timestamp = time // value = status

        responseJson.forEach(element => {
            var timeDataPoint = new Date(element.time).getTime()
            var timeTravelDataPoint = timeDataPoint - 1;
            var valveId = parseInt(element.devid);
            var valveDataPoint = (element.status) * 100;

            if (valveId === 1) {
                valve1Data.data.push({ x: timeTravelDataPoint, y: parseInt(valveDataPoint) });
                valve1Data.data.push({ x: timeDataPoint, y: parseInt(valveDataPoint) });
            }
            if (valveId === 2) {
                valve2Data.data.push({ x: timeDataPoint, y: parseFloat(valveDataPoint) });
            }
        });

        this.setState({ seriesTeceValve1: [valve1Data] })
        this.setState({ seriesTeceValve2: [valve2Data] })
    }

    splitDataPoints(responseJson) {
        console.log('responseJsonTemps');
        console.log(responseJson);

        // Create Split Arrays of Temps for Graph
        var temp1Data = { name: this.state.tempNames[0], data: [] };
        var temp2Data = { name: this.state.tempNames[1], data: [] };
        var temp3Data = { name: this.state.tempNames[2], data: [] };
        //var temp3Data = {name: this.state.tempNames[2], data: []};     

        //var teceAnnoXaxis = {xaxis: []};
        //var teceAnnoXaxisLastValue = '';

        responseJson.forEach(element => {
            var timeDataPoint = element.time;
            var temp1DataPoint = element.temp1 / 10;

            temp1Data.data.push({ x: timeDataPoint, y: parseFloat(temp1DataPoint) });

            var temp2DataPoint = element.temp2 / 10;

            temp2Data.data.push({ x: timeDataPoint, y: parseFloat(temp2DataPoint) });


            var temp3DataPoint = element.temp3 / 10;
            temp3Data.data.push({ x: timeDataPoint, y: parseFloat(temp3DataPoint) });
        });

        this.setState({ seriesTeceTemp1: [temp1Data] })
        this.setState({ seriesTeceTemp2: [temp2Data] })

        //this.setState({series: [temp1Data, temp2Data]})

        this.updateSeries();
    }

    parseSensorUIConfig(responseJson) {

        var configData = responseJson;

        console.log("Processing Config: " + JSON.stringify(configData));

        // Name of the Sensor. Only for Labels. Goes into State.
        this.setState({ currentSensorName: configData.nodeName });
        // Labels for Temp 1-3. Used by 'splitDataPoints' when formatting the Data Series.
        this.setState({ tempNames: [configData.sensor1Descriptor, configData.sensor2Descriptor, configData.sensor3Descriptor] });
        document.querySelector('#sensorConfigDesc1').value = [configData.sensor1Descriptor];
        document.querySelector('#sensorConfigDesc2').value = [configData.sensor2Descriptor];
        document.querySelector('#sensorConfigDesc3').value = [configData.sensor3Descriptor];
        // Colors for the chart. Goes into chart config.
        var uiConfigColors = [this.colorValues[configData.sensor1Color], this.colorValues[configData.sensor2Color], this.colorValues[configData.sensor3Color]];
        // Set Colors in Config Panel
        document.querySelector('#colorSelectorTemp1').value = [configData.sensor1Color];
        document.querySelector('#colorSelectorTemp2').value = [configData.sensor2Color];
        document.querySelector('#colorSelectorTemp3').value = [configData.sensor3Color];

        // Y-Axis Limits for the chart. Goes into chart config.
        var uiConfigYAxis = [{
                min: configData.axisYMin,
                max: configData.axisYMax
            },
            {
                opposite: true,
                min: 0,
                max: 100,
            }
        ];

        //this.setState({yAxisConfig: [configData.axisYMin, configData.axisYMax]});
        document.querySelector('#sensorConfigYMin').value = [configData.axisYMin];
        document.querySelector('#sensorConfigYMax').value = [configData.axisYMax];

        var newUiConfig = {
            //colors: uiConfigColors,
            colors: ['#34b7eb', '#eb4034', '#3F48CC', '#AD2424'],
            chart: {
                id: 'Temperaturverlauf',
                zoom: {
                    type: 'xy',
                },
            },
            plotOptions: {
                bar: {
                    horizontal: true
                }
            },
            tooltip: {
                enabled: true,
                x: {
                    format: 'HH:mm'
                }
            },
            stroke: {
                width: 2,
                curve: 'stepline',
            },
            xaxis: {
                type: 'datetime',
                labels: {
                    format: 'HH:mm',
                }
            },
            yaxis: uiConfigYAxis,
        }

        this.setState({ options: newUiConfig });
    }

    updateSeries() {
        //this.setState({ series: [...this.state.seriesTeceTemp1, ...this.state.seriesTeceTemp2, ...this.state.seriesTeceValve1, ...this.state.seriesTeceValve2] })
        this.setState({series: [...this.state.seriesTeceTemp1, ...this.state.seriesTeceTemp2]})
    }

    render() {
        return ( <
            div className = "tempChart-Wrapper" >
            <
            div id = "greyOverlay"
            style = {
                { display: "none" } } > < /div> <
            div id = "configWindow"
            style = {
                { display: "none" } } >
            <
            div className = "configWindowTitle" >
            Konfiguration: { this.state.currentSensorName } < span style = {
                { display: 'inline-block', float: 'right' } } > < Close id = 'closeConfigBtnTece'
            style = {
                { color: '#B71C1C', cursor: 'pointer' } }
            onClick = {
                () => { this.switchConfig() } } > < /Close></span >
            <
            /div>                     <
            hr / >
            <
            div >
            <
            Form >
            <
            Form.Group >
            <
            Form.Row >
            <
            Form.Label column = "sm"
            xs = { 4 } > Name Sensor < /Form.Label> <
            Col > < Form.Control id = "sensorConfigName"
            size = "sm"
            type = "text"
            value = { this.state.currentSensorName }
            onChange = { e => this.onSensorConfigNameChange(e.target.value) }
            /></Col >
            <
            /Form.Row> <
            hr / >
            <
            Form.Row >
            <
            Form.Label column = "sm"
            xs = { 4 } > Name Temp.1 < /Form.Label> <
            Col > < Form.Control id = "sensorConfigDesc1"
            size = "sm"
            type = "text" / > < /Col>  <
            /Form.Row> <
            br / >
            <
            Form.Row >
            <
            Form.Label column = "sm"
            xs = { 4 } > Farbe Temp.1 < /Form.Label> <
            Col > < Form.Control size = "sm"
            as = "select"
            id = "colorSelectorTemp1" >
            <
            option value = "0"
            style = {
                { color: '#000000' } } > Schwarz < /option>  <
            option value = "1"
            style = {
                { color: '#0D47A1' } } > Dunkelblau < /option>                                     <
            option value = "2"
            style = {
                { color: '#2196F3' } } > Blau < /option> <
            option value = "3"
            style = {
                { color: '#BBDEFB' } } > Hellbau < /option> <
            option value = "4"
            style = {
                { color: '#B71C1C' } } > Dunkelrot < /option> <
            option value = "5"
            style = {
                { color: '#F44336' } } > Rot < /option> <
            option value = "6"
            style = {
                { color: '#FE949F' } } > Hellrot < /option> <
            option value = "7"
            style = {
                { color: '#194D33' } } > Dunkelgrün < /option> <
            option value = "8"
            style = {
                { color: '#388E3C' } } > Grün < /option> <
            option value = "9"
            style = {
                { color: '#81C784' } } > Hellgrün < /option> <
            option value = "10"
            style = {
                { color: '#FF6F00' } } > Orange < /option> <
            option value = "11"
            style = {
                { color: '#FFD54F' } } > Gelb < /option>                                                                                                          <
            /Form.Control></Col >
            <
            /Form.Row> <
            hr / >
            <
            Form.Row >
            <
            Form.Label column = "sm"
            xs = { 4 } > Name Temp.2 < /Form.Label> <
            Col > < Form.Control id = "sensorConfigDesc2"
            size = "sm"
            type = "text" / > < /Col>  <
            /Form.Row> <
            br / >
            <
            Form.Row >
            <
            Form.Label column = "sm"
            xs = { 4 } > Farbe Temp.2 < /Form.Label> <
            Col > < Form.Control size = "sm"
            as = "select"
            id = "colorSelectorTemp2" >
            <
            option value = "0"
            style = {
                { color: '#000000' } } > Schwarz < /option>  <
            option value = "1"
            style = {
                { color: '#0D47A1' } } > Dunkelblau < /option>                                     <
            option value = "2"
            style = {
                { color: '#2196F3' } } > Blau < /option> <
            option value = "3"
            style = {
                { color: '#BBDEFB' } } > Hellbau < /option> <
            option value = "4"
            style = {
                { color: '#B71C1C' } } > Dunkelrot < /option> <
            option value = "5"
            style = {
                { color: '#F44336' } } > Rot < /option> <
            option value = "6"
            style = {
                { color: '#FE949F' } } > Hellrot < /option> <
            option value = "7"
            style = {
                { color: '#194D33' } } > Dunkelgrün < /option> <
            option value = "8"
            style = {
                { color: '#388E3C' } } > Grün < /option> <
            option value = "9"
            style = {
                { color: '#81C784' } } > Hellgrün < /option> <
            option value = "10"
            style = {
                { color: '#FF6F00' } } > Orange < /option> <
            option value = "11"
            style = {
                { color: '#FFD54F' } } > Gelb < /option>   <
            /Form.Control></Col >
            <
            /Form.Row> { /* <hr/> */ } <
            Form.Row style = {
                { display: "none" } } >
            <
            Form.Label column = "sm"
            xs = { 4 } > Name Temp.3 < /Form.Label> <
            Col > < Form.Control id = "sensorConfigDesc3"
            size = "sm"
            type = "text" / > < /Col>  <
            /Form.Row> <
            br / >
            <
            Form.Row style = {
                { display: "none" } } >
            <
            Form.Label column = "sm"
            xs = { 4 } > Farbe Temp.3 < /Form.Label> <
            Col > < Form.Control size = "sm"
            as = "select"
            id = "colorSelectorTemp3" >
            <
            option value = "0"
            style = {
                { color: '#000000' } } > Schwarz < /option>  <
            option value = "1"
            style = {
                { color: '#0D47A1' } } > Dunkelblau < /option>                                     <
            option value = "2"
            style = {
                { color: '#2196F3' } } > Blau < /option> <
            option value = "3"
            style = {
                { color: '#BBDEFB' } } > Hellbau < /option> <
            option value = "4"
            style = {
                { color: '#B71C1C' } } > Dunkelrot < /option> <
            option value = "5"
            style = {
                { color: '#F44336' } } > Rot < /option> <
            option value = "6"
            style = {
                { color: '#FE949F' } } > Hellrot < /option> <
            option value = "7"
            style = {
                { color: '#194D33' } } > Dunkelgrün < /option> <
            option value = "8"
            style = {
                { color: '#388E3C' } } > Grün < /option> <
            option value = "9"
            style = {
                { color: '#81C784' } } > Hellgrün < /option> <
            option value = "10"
            style = {
                { color: '#FF6F00' } } > Orange < /option> <
            option value = "11"
            style = {
                { color: '#FFD54F' } } > Gelb < /option>   <
            /Form.Control></Col >
            <
            /Form.Row> <
            hr / >
            <
            Form.Row >
            <
            Form.Label column = "sm"
            xs = { 4 } > Y - Achse Minimum < /Form.Label> <
            Col > < Form.Control id = "sensorConfigYMin"
            size = "sm"
            type = "number" / > < /Col>  <
            /Form.Row> <
            br / >
            <
            Form.Row >
            <
            Form.Label column = "sm"
            xs = { 4 } > Y - Achse Maximum < /Form.Label> <
            Col > < Form.Control id = "sensorConfigYMax"
            size = "sm"
            type = "number" / > < /Col>  <
            /Form.Row>                                                                                                                               <
            /Form.Group>                                                                   <
            /Form> <
            /div> <
            hr / >
            <
            div className = "configWindowButtons" >
            <
            Button id = "BtnConfig"
            variant = "contained"
            color = ""
            className = "btnSuccess"
            onClick = {
                () => { this.saveNewConfig() } } > Speichern < /Button> <
            Button id = "BtnConfig"
            variant = "contained"
            color = ""
            className = "btnCancel"
            onClick = {
                () => { this.switchConfig() } } > Schliessen < /Button> <
            /div> <
            /div> <
            div >
            <
            Button id = "BtnSwitchDayBack"
            variant = "contained"
            color = "primary"
            className = "fltr"
            onClick = {
                () => { this.switchDayBack() } } > Tag Zurück < /Button> <
            Button id = "BtnSwitchDayForward"
            variant = "contained"
            color = "primary"
            className = "fltr hide"
            onClick = {
                () => { this.switchDayForward() } } > Tag Vor < /Button> <
            Button id = "BtnSwitchDayToday"
            variant = "contained"
            color = "primary"
            className = "fltr hide"
            onClick = {
                () => { this.switchDayToday() } } > Heute < /Button> <
            Button id = "BtnConfig"
            variant = "contained"
            color = "primary"
            className = "fltr configButton"
            onClick = {
                () => { this.switchConfig() } } > < Icon name = "settings" / > < /Button> <
            Button id = "BtnSwitchRefresh"
            variant = "contained"
            color = "primary"
            className = "fltr configButton"
            onClick = {
                () => { this.switchRefresh() } } > < Icon name = "refresh" / > < /Button>                     <
            /div> <
            Card className = "tempChart-card" >
            <
            Card.Header >
            <
            div className = "card-title" > Temperaturverlauf: { this.state.lastFetchedDate } < /div> <
            /Card.Header>  <
            Card.Body >
            <
            Chart options = { this.state.options }
            series = { this.state.series }
            type = "line"
            style = {
                { height: '100%' } }
            height = { 400 }
            /> <
            /Card.Body> <
            /Card> <
            /div>        
        );
    }
}
export default TrinkwasserHygGraph;