import React, {Component, useState, setState, Fragment, useEffect} from 'react';
import {Link} from 'react-router-dom';
import Card from 'react-bootstrap/Card';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Slider from '@material-ui/core/Slider';
import { display } from '@mui/system';
import Button from '@material-ui/core/Button';
import SheetData from './SheetData';

function FensterStrCard(props){

    var setSoll;
    var sollWert;
    var avrIst = 0;
    var counter = 0;
    for (var x = 0; x < props.room[1].length; x++){

        if(props.room[1][x].s1istAktiv == 1){
            avrIst += parseInt(props.room[1][x].s1ist);
            counter ++;
        }
        if(props.room[1][x].s2istAktiv == 1){
            avrIst += parseInt(props.room[1][x].s2ist);
            counter ++;
        }        
    }

    if(counter > 0){
        avrIst = avrIst / counter;
        avrIst = Math.floor(avrIst) + "%";
    } else {
        avrIst = "Lädt... ";
    }

    if(props.room[1][0].s1soll){
        sollWert = props.room[1][0] + "%"
    } else if (props.room[1][1].s1soll){
        sollWert = props.room[1][1] + "%"
    } else {
        sollWert = "Lädt..."
    }
      return(
        <div className='fensterCard'>
            <br/>
            <Card>
                <Card.Header>
                    <div>
                        <b>{props.room[2]}</b><br/>
                        {"Aktueller Wert: " + avrIst}<br/>
                        {"Aktueller Sollwert: " +  (props.room[1][0].s1soll ? props.room[1][0].s1soll + "%": props.room[1][1].s1soll ? props.room[1][1].s1soll + "%": "Lädt...")}
                        <div id={props.id + "_btnWithProgress"}  className="btnWithProgressEC hidden"><div className="soft"><a> <span className="btn-text">OK!</span></a></div></div>                       
                    </div>
                </Card.Header>
                <Card.Body>                    
                    <div className="groupLightswitch" style={{padding: 10}}>
                        <div className="cardLabel">
                            <div id="ecSollwertGroup"  className="card-title-buttons">
                                <Button id={props.id + "_sollwert0"} variant="contained" color="" className="generic-btn fltl ecbutton">0%</Button>
                                <Button id={props.id + "_sollwert10"} variant="contained" color="" className="generic-btn fltl ecbutton">10%</Button>
                                <Button id={props.id + "_sollwert20"} variant="contained" color="" className="generic-btn fltl ecbutton">20%</Button>
                                <Button id={props.id + "_sollwert30"} variant="contained" color="" className="generic-btn fltl ecbutton">30%</Button>
                                <Button id={props.id + "_sollwert40"} variant="contained" color="" className="generic-btn fltl ecbutton">40%</Button>
                                <Button id={props.id + "_sollwert50"} variant="contained" color="" className="generic-btn fltl ecbutton">50%</Button>                                       
                                <Button id={props.id + "_sollwert60"} variant="contained" color="" className="generic-btn fltl ecbutton">60%</Button>
                                <Button id={props.id + "_sollwert70"} variant="contained" color="" className="generic-btn fltl ecbutton">70%</Button>
                                <Button id={props.id + "_sollwert80"} variant="contained" color="" className="generic-btn fltl ecbutton">80%</Button>
                                <Button id={props.id + "_sollwert90"} variant="contained" color="" className="generic-btn fltl ecbutton">90%</Button>
                                <Button id={props.id + "_sollwert100"} variant="contained" color="" className="generic-btn fltl ecbutton">100%</Button> 
                            </div>  
                        </div>                       
                    </div>
                </Card.Body>                                  
                <SheetData className='sheetData' data={props.room[1]} isDebug={props.isDebug} />                
            </Card>
        </div>
      )
}

export default FensterStrCard;