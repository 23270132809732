import './App.scss';
import 'bootstrap/dist/css/bootstrap.min.css';
import React, {Component, useState} from 'react';
import {HashRouter, Route, Switch} from 'react-router-dom';
import Header from './components/Header';
import Footer from './components/Footer';
import Login from './components/Login';

// import Projects from './components/Projects_old';
import Projects from './components/projects/Projects';

import Devices from './components/Devices';
import Econtrol from './components/econtrol/Econtrol';
import Econtrol2 from './components/econtrol2/Econtrol2';
import Econtrol3 from './components/econtrol3/Econtrol3';
import Econtrol4 from './components/econtrol4/Econtrol4';
import Econtrol5 from './components/econtrol5/Econtrol5';
import ECgallery from './components/econtrol/ECGallery';
import EChistory from './components/econtrol/ECHistory';
import Fenstersteuerung from './components/devices/fenstersteuerung/Fenstersteuerung';
import Fuellstandswaechter from './components/devices/fuellstandswaechter/Fuellstandswaechter';
import Trinkwasserhygiene from './components/devices/trinkwasser/Trinkwasserhygiene';
import Raumtemperatur from './components/devices/raumtemperatur/Raumtemperatur';
import Lights from './components/devices/lights/Lights';
import Volt from './components/devices/volt/Volt';
import Rauchmeldersystem from './components/devices/rauchmeldersystem/Rauchmeldersystem';
import Temperaturfuhler from './components/devices/temperaturfuhler/Temperaturfuhler';
import Temperaturfuhler4 from './components/devices/temperaturfuhler4/Temperaturfuhler4';
import Hygienespuelkasten from './components/devices/hygienespuelkasten/Hygienespuelkasten';
import Trennstation from './components/devices/trennstation/Trennstation';
import Lexplussl from './components/devices/syrconnect/Lexplussl';

import Hygboxlight from './components/devices/hygboxlight/Hygboxlight';
import Digitalventil from './components/devices/digitalventil/Digitalventil';

import useToken from './security/useToken';
import ezglogo from './assets/ezg_logo.png';

import Container from 'react-bootstrap/Container';
import Waermepumpe from './components/devices/waermepumpe/Waermepumpe'; 

function App() {    
  
  const { token, setToken } = useToken();
  console.log(token)
    // VERIFY CREDENTIALS WITH API & GET TOKEN
    if(!token) {
      console.log("failed to log in")
      return <Login setToken={setToken} />
    }
    
    return (
      <Container fluid="lg"> 
          <Header/>
          <HashRouter basename="/">
            <Switch>              
              <Route exact path="/"><Projects/></Route>
              <Route path="/devices"><Devices/></Route>
              <Route path="/econtrol"><Econtrol/></Route>
              <Route path="/econtrol2"><Econtrol2/></Route>
              <Route path="/econtrol3"><Econtrol3/></Route>
              <Route path="/econtrol4"><Econtrol4/></Route>
              <Route path="/econtrol5"><Econtrol5/></Route>
              <Route path="/ECGallery"><ECgallery/></Route>
              <Route path="/echistory"><EChistory/></Route>

              <Route path="/hygboxlight"><Hygboxlight/></Route>
              <Route path="/digitalventil"><Digitalventil/></Route>

              <Route path="/trinkwasserhygiene"><Trinkwasserhygiene/></Route>
              <Route path="/raumtemperatur"><Raumtemperatur/></Route>    
              <Route path="/lights"><Lights/></Route>
              <Route path="/volt"><Volt/></Route>
              <Route path="/rauchmeldersystem"><Rauchmeldersystem/></Route>
              <Route path="/temperaturfuhler"><Temperaturfuhler/></Route>
              <Route path="/temperaturfuhler4"><Temperaturfuhler4/></Route>   
              <Route path="/hygienespuelkasten"><Hygienespuelkasten/></Route>  
              <Route path="/waermepumpe"><Waermepumpe/></Route>  
              <Route path="/fenstersteuerung"><Fenstersteuerung/></Route>
              <Route path="/fuellstandswaechter"><Fuellstandswaechter/></Route>

              <Route path="/trennstation"><Trennstation/></Route>  
              <Route path="/lexplussl"><Lexplussl/></Route>              
            </Switch>                        
          </HashRouter>
      </Container>
    );
}

export default App;