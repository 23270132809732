import React, {Component, useState, setState, Fragment, useEffect} from 'react';
import Card from 'react-bootstrap/Card';
function SheetData(props){
    return(
        <div className='sheetDataFooter'>  
            <div className='sheetDataTable'>
                <b>DebugMode</b>
                <table>
                    <tr key={"header"}>
                        {Object.keys(props.data[0]).map((key) => (
                        <th>{key}</th>
                        ))}
                    </tr>
                    {props.data.map((item) => (
                        <tr key={item.id}>
                            {Object.values(item).map((val) => (
                                <td>{val}</td>
                            ))}
                        </tr>
                    ))}
                </table>       
            </div>
        </div>      
        )
    }    
export default SheetData;

