import React, {Component, useState, Fragment, useEffect} from 'react';
import {Link} from 'react-router-dom';
import Card from 'react-bootstrap/Card';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import ArrowBackIosTwoToneIcon from '@material-ui/icons/ArrowBackIosTwoTone';
import Button from '@material-ui/core/Button';
import Slider from '@material-ui/core/Slider';
import LinkIcon from '@material-ui/icons/Link';
import LinkOffIcon from '@material-ui/icons/LinkOff';
import HygboxlightGraph from './HygboxlightGraph.js';

function Hygboxlight() {
    
    const [valueSlider1, setValueSlider1] = useState([0]);

    useEffect(()=>{
        // do stuff here...
        var mqtt = require('mqtt')
        var Broker = "mqtts://testmqtt.syrconnect.de:8001";
        var options = {
            protocol: 'mqtts',
            clientId: 'EZGWebsiban' + Math.floor(Math.random() * 1000)
        };

       var Topic1 = "Dehoust/HBL/ZVC/";        

        var client  = mqtt.connect(Broker, options);

        client.on('connect', function () {
            var hygBoxLightOfflineIndicator = document.getElementById("hygBoxLightOffline");
            var hygBoxLightOnlineIndicator = document.getElementById("hygBoxLightOnline");            

            if (hygBoxLightOfflineIndicator !=  null) {
                hygBoxLightOfflineIndicator.classList.add('hide');   
                hygBoxLightOnlineIndicator.classList.remove('hide'); 
            }         

            console.log('HygBoxLight CONNECTED as ' + options.clientId);      
                 
            client.subscribe(Topic1 + "IN/temp", function (err) {;
                if (!err) {
                console.log('SUBBED TOPIC: temp');                             
                }
            })

            client.subscribe(Topic1 + "OUT/abs1state", function (err) {;
                if (!err) {
                console.log('SUBBED TOPIC: abs1state');                             
                }
            })
        })

        client.on('message', function (topic, message) {
        // message is Buffer  
            console.log("onMessageArrived Message:" + message);
            console.log("onMessageArrived Topic:" + topic);


            if (topic.endsWith('temp')) {
                document.getElementById("hygBoxLightCard1temp").innerHTML = parseInt(message) / 10.0;  
            }       
            if (topic.endsWith('abs1state')) {
                hygBoxLight1State(message);
            }
        })

        function hygBoxLight1State(msg) {
            var status = msg.toString();
            
            console.log("Status HygBoxLight Received:" + msg)

            // its closed
            if (status === "0") {
                document.getElementById("openhBL1Btn").classList.remove("btnGreyed");
                document.getElementById("closehBL1Btn").classList.add("btnGreyed");
                document.getElementById("hBL1Status").innerHTML = "geschlossen";
                document.getElementById("hBL1Status").classList.remove("textGreen");
                document.getElementById("hBL1Status").classList.add("textRed");
            } 

            //its open
            if (status === "1") {
                document.getElementById("openhBL1Btn").classList.add("btnGreyed");
                document.getElementById("closehBL1Btn").classList.remove("btnGreyed");
                document.getElementById("hBL1Status").innerHTML = "offen";
                document.getElementById("hBL1Status").classList.remove("textRed");
                document.getElementById("hBL1Status").classList.add("textGreen");
            }        
        }

        const ConstAbs1auf = document.getElementById('openhBL1Btn');
        ConstAbs1auf.addEventListener('click', (event) => {
            if (event.target.click) {                
                sendHBLAbs1(1);
               }
        });

        const ConstAbs1zu = document.getElementById('closehBL1Btn');
        ConstAbs1zu.addEventListener('click', (event) => {
            if (event.target.click) {                
                sendHBLAbs1(0);
               }
        });

        function sendHBLAbs1(value) {
            console.log("SENDING HBL STATE:" + value)
            client.publish("Dehoust/HBL/ZVC/IN/abs1state", value.toString());
        }

        client.on('close', function () {            
            console.log("HygBoxLight DISCONNECTED")        
        })
    
        return () => client.end(); // <-- kill on unmount
    }, []) // <-- empty dependency array

    return (
        <div className="module-wrapper">
            <div className="distancer">
                {/* // SUBNAVIGATION */}
                <div className="subnav">
                    <div className="fltl">
                        <Link to="/Devices">
                            <Button variant="contained" color="primary" className="back-button" startIcon={<ArrowBackIosTwoToneIcon/>}>Geräte</Button>
                        </Link>
                    </div>
                    <div className="fltr">
                        <Button id="hygBoxLightOnline" variant="outlined" color="primary" className="online fltr hide" disableElevation disabled startIcon={<LinkIcon/>}>Verbunden</Button>
                        <Button id="hygBoxLightOffline" variant="outlined" color="primary" className="offline fltr" disableElevation disabled startIcon={<LinkOffIcon/>}>Verbindung</Button>
                    </div>
                    <div className="spacer"></div>                     
                </div>
                {/* END SUBNAVIGATION*/}  
                <div className="outer-title">
                    Hygbox Light
                </div>
                <Row>
                    <Col md="12" lg="6">
                    <Card className="deviceCard">
                            <Card.Header>
                                <div className="card-title">Temperatur</div>    
                                <div className="card-title-right"><span id="hygBoxLightCard1temp">0</span> °C</div>
                            </Card.Header>
                            <Card.Body className="grenzewerte-card-body">
                            <div className="groupLightswitch">
                                <div className="cardLabel">
                                    <span className=""><b>Hygbox Light 1</b></span><br>
                                    </br>
                                    <span>Status: </span><span id="hBL1Status"></span>
                                </div>
                                <div className="cardGroupSwitch">
                                    <div className="card-title-buttons">
                                        <Button id="openhBL1Btn" variant="contained" className="generic-btn fltr">Öffnen</Button>
                                        <Button id="closehBL1Btn" variant="contained" className="generic-btn fltr">Schließen</Button>
                                    </div>
                                </div>                            
                            </div>             
                            </Card.Body>
                        </Card>
                    </Col>               
                </Row>
                <div>
                    <HygboxlightGraph/>
                </div>
            </div>
        </div>
    )
}

export default Hygboxlight;