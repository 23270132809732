import React, {Component, useState, setState, Fragment, useEffect} from 'react';
import {Link} from 'react-router-dom';
import Card from 'react-bootstrap/Card';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Table from 'react-bootstrap/Table';
import ECGlassHistory from './ECGlassHistoryRow';

import ArrowBackIosTwoToneIcon from '@material-ui/icons/ArrowBackIosTwoTone';
import Button from '@material-ui/core/Button';
import LinkIcon from '@material-ui/icons/Link';
import LinkOffIcon from '@material-ui/icons/LinkOff';
import Icon from "../Icons";
import eclogo from '../../assets/ec_logo.png';

export default class ECHistory extends Component{
    constructor(props) {
        super(props);

        this.state = {
            history: [],
            historyEntryAmount: '',
            historyCurrentPageNumber: 1,
            historyCurrentPageData: [],
            historyTotalPageCount: 0
        };
    }

    componentDidMount() {
        this.getECHistory();        
    }
    
    getECHistory() {
        const requestOptionsECHistory = {
            method: 'GET',
            headers: {'Content-Type': 'application/x-www-form-urlencoded'}                        
        }

        // FETCH 
        fetch('https://api.zvconnect.de/smartglashistory/', requestOptionsECHistory)
        .then(response => response.json())
        .then(responseJson => {this.parseECHistory(responseJson);
        })        
    }

    setTablePaging() {
        var pN = this.state.historyCurrentPageNumber;
        var start = (pN*25) - 25;
        var end = (pN*25); 

        const historySlice = this.state.history.slice(start, end);
        this.setState({historyCurrentPageData: historySlice})

        // Hide Next Button if Current Page = Last Page
        if (this.state.historyCurrentPageNumber === this.state.historyTotalPageCount) {
            document.querySelector('#BtnECHistoryNext').style.display= 'none';            
        }
        else {
            document.querySelector('#BtnECHistoryNext').style.display= 'block';
        }

        // Hide Previous Button if Current Page = First Page
        if (this.state.historyCurrentPageNumber === 1) {
            document.querySelector('#BtnECHistoryPrevious').style.display= 'none';            
        }
        else {
            document.querySelector('#BtnECHistoryPrevious').style.display= 'block';
        }

    }

    pageECHistoryNext(){
        var newPNNxt = this.state.historyCurrentPageNumber + 1;        
        this.setState({historyCurrentPageNumber: newPNNxt})

        var pN = newPNNxt;
        var start = (pN*25) - 25;
        var end = (pN*25); 

        const historySlice = this.state.history.slice(start, end);
        this.setState({historyCurrentPageData: historySlice})

        // Hide Next Button if Current Page = Last Page
        if (newPNNxt === this.state.historyTotalPageCount) {
            document.querySelector('#BtnECHistoryNext').style.display= 'none'; 
            document.querySelector('#BtnECHistoryPrevious').style.display= 'block';           
        }
        else {
            document.querySelector('#BtnECHistoryNext').style.display= 'block';
            document.querySelector('#BtnECHistoryPrevious').style.display= 'block';
        }
    }

    pageECHistoryPrev(){
        var newPNPrv = this.state.historyCurrentPageNumber - 1;        
        this.setState({historyCurrentPageNumber: newPNPrv})

        var pN = newPNPrv;
        var start = (pN*25) - 25;
        var end = (pN*25); 

        const historySlice = this.state.history.slice(start, end);
        this.setState({historyCurrentPageData: historySlice})

        // Hide Next Button if Current Page = First Page
        if (newPNPrv === 1) {
            document.querySelector('#BtnECHistoryPrevious').style.display= 'none';
            document.querySelector('#BtnECHistoryNext').style.display= 'block';         
        }
        else {           
            document.querySelector('#BtnECHistoryPrevious').style.display= 'block';
            document.querySelector('#BtnECHistoryNext').style.display= 'block';
        }
    }

    parseECHistory(responseJson) { 

        //console.log("REVERSE: " + responseJson);
        const reverseData = responseJson.reverse();        

        this.setState({historyEntryAmount: reverseData.length})
        
        for(var i = 0; i< reverseData.length; i++) {

        // Format Value
           var valueString = reverseData[i].value.toString()

           if (!valueString.includes('.')) {
                reverseData[i].value = valueString + '.0'
           }

        //    if (reverseData[i].event_name === 'Neuer Sollwert') {
        //     reverseData[i].sollwert_value.value = reverseData[i].value;            
        //    }

        // Count Pages
           var pageCount = reverseData.length / 25;
           pageCount = Math.ceil(pageCount);
           this.setState({historyTotalPageCount: pageCount})

        // Format Timestamp
           var timeStampString = reverseData[i].created_at; 
           var hisTime;

           console.log(timeStampString)

           let timeStamp = new Date(timeStampString);
           timeStamp.setHours(timeStamp.getHours() - 2);
           
           let cDay = timeStamp.getDate()
           let cMonth = timeStamp.getMonth() + 1
           let cYear = timeStamp.getFullYear()
           let cHours = timeStamp.getHours()
           let cMinutes = timeStamp.getMinutes()
           let cSeconds = timeStamp.getSeconds();      
   
           var dayCheck = cDay.toString();
           var monthCheck = cMonth.toString();
           var hoursCheck = cHours.toString();
           var minCheck = cMinutes.toString();
           var secCheck = cSeconds.toString();
          
           if (dayCheck.length === 1)
           {
               dayCheck = '0' + dayCheck;
           }       
   
           if (monthCheck.length === 1)
           {
               monthCheck = '0' + monthCheck;
           }  
   
           if (hoursCheck.length === 1)
           {
               hoursCheck = '0' + hoursCheck;
           }  
   
           if (minCheck.length === 1)
           {
               minCheck = '0' + minCheck;
           }

           if (secCheck.length === 1)
           {
                secCheck = '0' + secCheck;
           }

           hisTime = dayCheck + "." + monthCheck + "." + cYear + " " +  hoursCheck + ":" +  minCheck + ":" + secCheck;
           console.log(hisTime);
           reverseData[i].created_at =  hisTime; 
        }

        this.setState({history: reverseData})
        this.setTablePaging();
    }

    render() {   
        return (
        <div className="module-wrapper">
            <div className="distancer">
                <div className="outer-title">
                    <img className="" src={eclogo} alt="Logo" style={{height: "50px", margin: "0px 0px 5px 0px"}}/> Kuppel Parlament Wien: Historie
                </div>
            </div>
            <Row>
                <Col>
                    <Card className="deviceCard" style={{maxWidth: 880}}>
                        <Card.Header>                           
                            <span style={{lineHeight: "36px"}}>Seite {this.state.historyCurrentPageNumber} von {this.state.historyTotalPageCount}</span>
                            <Button id="BtnECHistoryNext" variant="contained" color="primary" className="fltr configButton" style={{marginLeft: 5}} onClick={() => {this.pageECHistoryNext()}}><Icon name="arrow-right"/></Button>  
                            <Button id="BtnECHistoryPrevious" variant="contained" color="primary" className="fltr configButtonFlipped" onClick={() => {this.pageECHistoryPrev()}}><Icon name="arrow-right"/></Button>  
                        </Card.Header>
                        <Card.Body>
                            <Table striped bordered hover size="sm" aria-sort="descending">
                                <thead>
                                    <tr>
                                        <th style={{width: 180, textAlign: 'right'}}>Ereignis</th>
                                        <th style={{width: 180, textAlign: 'right'}}>Benutzer</th>
                                        <th style={{width: 140, textAlign: 'right'}}>Sollwert</th>   
                                        <th style={{width: 140, textAlign: 'right'}}>Erreichter Wert</th>                                        
                                        <th style={{width: 160, textAlign: 'right'}}>Uhrzeit</th>                                                 
                                    </tr>
                                </thead>
                                <tbody>
                                    {this.state.historyCurrentPageData.map(dataSet =>  (
                                        <ECGlassHistory Event={dataSet.event_name} User={dataSet.created_by} Value={dataSet.value} Time={dataSet.created_at}/>
                                    ))}
                                </tbody>
                            </Table>                            
                        </Card.Body>
                        <Card.Footer>
                            {this.state.historyEntryAmount} Einträge Insgesamt
                        </Card.Footer>
                    </Card>  
                </Col>
            </Row>  
         </div>
        );
    }
}
            