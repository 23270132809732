import React, {Component} from 'react';
import {Route, Link, Redirect} from 'react-router-dom';
import zvclogo_text from '../assets/zvclogo_text.png';
import zvclogo_full from '../assets/zvclogo_full.png';
import zvclogo_compact from '../assets/zvconnect_logo.png';


import Icon from "./Icons";
import VpnKey from '@material-ui/icons/VpnKey';
import Close from '@material-ui/icons/Close';

import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

import jwt_decode from "jwt-decode";

class Header extends Component {

    constructor(props) {
        super(props)
        this.state = { 
            globalSettingsClass: '',
            passwordChangeStatusTextClass: 'passwordChangeStatusText emptyContent',
            oldPassword: '',
            newPassword: '',
            newPasswordRepeat: '',
            statusText: ''
        }
        this.settingsClicked = this.settingsClicked.bind(this);
        this.saveNewPasswordClicked = this.saveNewPasswordClicked.bind(this);      
    }

    logoutHandler = (e) => {
        sessionStorage.removeItem('token');
        localStorage.removeItem('token');
        window.location.reload();
    }

    handleSettingsNewPasswordOld = event => {
        this.setState({ oldPassword: event.target.value });
    }

    handleSettingsNewPassword = event => {
        this.setState({ newPassword: event.target.value });
    }

    handleSettingsNewPasswordRepeat = event => {
        this.setState({ newPasswordRepeat: event.target.value });
    }

    settingsClicked() {
        if (this.state.globalSettingsClass === '') {
            this.setState({
                globalSettingsClass: 'showGlobalSettingsDiv'
            })
        }
        else {
        this.setState({
            globalSettingsClass: '',
        })
        }
    }

    parseResponseFromSendNewPassword(responseJson) {
        var message = responseJson.message;
        var status = responseJson.status;
        console.log(message);

        if (status === "success") {
            document.getElementById('settingsNewPasswordOld').value = '';
            document.getElementById('settingsNewPassword').value = '';
            document.getElementById('settingsNewPasswordRepeat').value = '';

            this.setState({statusText: 'Passwort erfolgreich geändert.'})
            this.setState({passwordChangeStatusTextClass: 'passwordChangeStatusText' + ' textGreen'})  
        }
    }

    sendNewPassword(responseJson) {
        if (responseJson !== undefined && responseJson.token !== undefined) {
            var freshTokenString = responseJson.token;
            console.log(freshTokenString)

            var configJson = {
                old_password: this.state.oldPassword,
                new_password: this.state.newPassword,
            }

            var sendNewPasswordConfig = {
                method: 'PUT',
                headers: {'Content-Type': 'application/json', 'Authorization': 'JWT ' + freshTokenString},
                body: JSON.stringify(configJson)
            }
    
            // POST TO API
            fetch('https://api.zvconnect.de/change-password/', sendNewPasswordConfig)
            .then(response => response.json())
            .then(responseJson => {this.parseResponseFromSendNewPassword(responseJson)})
        }
        else {            
            this.setState({statusText: 'Das aktuelle Passwort ist falsch.'})
            this.setState({passwordChangeStatusTextClass: 'passwordChangeStatusText' + ' textRed'})            
        }
    }

    saveNewPasswordClicked() {
        if (this.state.newPassword === this.state.newPasswordRepeat && this.state.oldPassword !== '') {
            // https://api.zvconnect.de/auth/token/            

            var oldToken = JSON.parse(sessionStorage.getItem('token'));   //need token.token    

            if (oldToken !== null) {
                var decodedJwt = jwt_decode(oldToken.token);
                var userName = decodedJwt.username;

                var credentialsJson = {
                    username: userName,
                    password: this.state.oldPassword
                }
               
                var getFreshTokenConfig = {
                    method: 'POST',
                    headers: {'Content-Type': 'application/json'},
                    body: JSON.stringify(credentialsJson)
                }
        
                // POST TO API
                fetch('https://api.zvconnect.de/auth/token/ ', getFreshTokenConfig)
                .then(response => response.json())
                .then(responseJson => {this.sendNewPassword(responseJson);
                })
            }
        }   
        
        else {            
            this.setState({statusText: 'Bitte überprüfen Sie Ihre Angaben.'})
            this.setState({passwordChangeStatusTextClass: 'passwordChangeStatusText' + ' textRed'})            
        }
    }

    // Logout
    deleteSessionToken = (e) =>  {
        sessionStorage.removeItem('token');
        localStorage.removeItem('token');
        window.location.reload();              
    }

    render() {
        return (
            <header className="navbar fixed-top">                 
                    {/* TOP NAVBAR START */}
                    <nav className="navbar-container container-lg">                        
                        <a className="navbar-brand">
                            <img className="navbar-logo" src={zvclogo_full} alt="ZVconnect Logo"/>
                            <img className="navbar-logo-mobile" src={zvclogo_compact} alt="ZVconnect Logo"/>  
                        </a>
                        {/* RIGHT BUTTONS */}
                        <div className="navbar-icons-wrapper">                            
                            <div onClick={e=>this.logoutHandler(e)} className="logout-button" title='Ausloggen'><Icon name="logout"/></div>
                            <div onClick={this.settingsClicked} id="settingsBtn" className="settings-button" title='Passworteinstellungen'>{<VpnKey/>}</div>
                            <div className="alert-button" title='Alerts'><Icon name="alert"/></div>               
                        </div>                                      
                    </nav>
                    {/* TOP NAVBAR END */}    
                    <div id='globalSettingsDiv' className={this.state.globalSettingsClass}>
                        <div className='globalSettingsInnerDiv'>
                            <div className='outer-title'>Passworteinstellung<span onClick={this.settingsClicked} style={{float: "right", cursor: "pointer", color: "rgb(204, 92, 88)"}}>{<Close/>}</span></div>
                            <div className='gsRow'>
                                <div>Aktuelles Passwort:</div>
                                <input id='settingsNewPasswordOld' type="password" onChange={this.handleSettingsNewPasswordOld}></input>
                            </div>
                            <div className='gsRow'>
                                <div>Neues Passwort:</div>
                                <input id='settingsNewPassword' type="password" onChange={this.handleSettingsNewPassword}></input>
                            </div>
                            <div className='gsRow'>
                                <div>Neues Passwort wiederholen:</div>
                                <input id='settingsNewPasswordRepeat' type="password" onChange={this.handleSettingsNewPasswordRepeat}></input>
                            </div>
                            <div id="passwordChangeStatusText" className={this.state.passwordChangeStatusTextClass}>{this.state.statusText}</div>
                            <div id='confirmButtonRow' style={{textAlign: "center"}}>
                                <Button onClick={this.saveNewPasswordClicked} variant="contained" color="primary" className="btnSuccess">SPEICHERN</Button>
                                <Button onClick={this.settingsClicked} variant="contained" color="primary" className="btnCancel">SCHLIESSEN</Button>
                            </div>
                        </div>                        
                    </div>   
            </header>
        );
    }
}

export default Header;