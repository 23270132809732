import React, {Component, useState, Fragment, useEffect} from 'react';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';

const SmokeHistoryTableRow = (props) => {

    var pTimestamp;

    if (props.created_at !== null) {
        // Format Timestamp
        var timeStampString = props.time;        

        let timeStamp = new Date(timeStampString);
        timeStamp.setHours(timeStamp.getHours() - 2);
        
        let cDay = timeStamp.getDate()
        let cMonth = timeStamp.getMonth() + 1
        let cYear = timeStamp.getFullYear()
        let cHours = timeStamp.getHours()
        let cMinutes = timeStamp.getMinutes()
        let cSeconds = timeStamp.getSeconds();      

        var dayCheck = cDay.toString();
        var monthCheck = cMonth.toString();
        var hoursCheck = cHours.toString();
        var minCheck = cMinutes.toString();
        var secCheck = cSeconds.toString();
       
        if (dayCheck.length === 1)
        {
            dayCheck = '0' + dayCheck;
        }       

        if (monthCheck.length === 1)
        {
            monthCheck = '0' + monthCheck;
        }  

        if (hoursCheck.length === 1)
        {
            hoursCheck = '0' + hoursCheck;
        }  

        if (minCheck.length === 1)
        {
            minCheck = '0' + minCheck;
        }

        if (secCheck.length === 1)
        {
             secCheck = '0' + secCheck;
        }
        pTimestamp = dayCheck + "." + monthCheck + "." + cYear + " " +  hoursCheck + ":" +  minCheck + ":" + secCheck;        
    }

    return ( 
        <TableRow>
            <TableCell >{props.message}</TableCell >
            <TableCell style={{width: 155}}>{pTimestamp}</TableCell >
        </TableRow>
    );
};

export default SmokeHistoryTableRow;