import React, {Component, useState, Fragment, useEffect} from 'react';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';

const ECGlassRow = (props) => {
    
    var tempMinClass = '';
    var tempMaxClass = '';
    
    var tMax = parseInt(props.TempMax);
    var tMin = parseInt(props.TempMin);  

    if (tMax >= 60) {
        tempMaxClass = 'textRed textBold'         
    }
    else {        
        tempMaxClass = ''
    }

    if (tMin <= 5) {
        tempMinClass = 'textRed textBold' 
    }
    else {        
        tempMinClass = ''
    }

    return (
        <TableRow>
            <TableCell >{props.Name}</TableCell >
            <TableCell >{props.AC}</TableCell >
            <TableCell >{props.Error}</TableCell >
            <TableCell>{props.Idle}</TableCell>
            <TableCell >{props.Init}</TableCell >
            <TableCell >{props.Operating}</TableCell>
            <TableCell >{props.Waiting}</TableCell>
            <TableCell >{props.Percent} %</TableCell>
            <TableCell><span className={tempMinClass}>{props.TempMin} °C</span></TableCell>
            <TableCell><span className={tempMaxClass}>{props.TempMax} °C</span></TableCell>            
            <TableCell>{props.Time}</TableCell>
        </TableRow>
    );
};

export default ECGlassRow;